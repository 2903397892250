import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allMeasureUnitsOptions, allMonthsOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions"
import { Template } from "../../common/types/Template"
import { cloneDeep } from 'lodash'
import { Report127ActFilterSettings } from "./settings/Report127ActFilterSettings"
import { Report127ActFilterOptions } from "./options/Report127ActFilterOptions"
import { Option } from "../../common/types/Option"
import { StatusType } from "../../common/types/roots/fields"

export const actStatusOptions: Option<StatusType>[] = [
    { value: '39', label: "39 - Утверждено в ИС КК" },
    { value: '26', label: "26 - Согласовано УО" }
]

const fs: Report127ActFilterSettings = {
    reportName: '1-27-ACT',

    reportType: 'REGULATED',
    classificationType: 'MIXED',
    dataSource: 'LOADER',
    periodicity: 'MONTH',

    region: '',
    budgetTypes: [],
    statuses: [],
    measureUnit: 'TENGE',
    year: (new Date().getFullYear()),
    month: 1,
    roundUpTo: null
}

export const report127ActBaseTemplate: Template = {
    id: null,
    name: 'Акт сверки',
    description: null,
    category: '70',
    regions: [],
    userId: '',
    filterSettings: fs
}

export const report127ActFilterOptions: Report127ActFilterOptions = {
    // Base:
    key: '1-27-ACT',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    // Custom:
    regions: [], // Загружается с бэка
    statuses: cloneDeep(actStatusOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    months: cloneDeep(allMonthsOptions),
}
