import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { report420ExpensesClassificators, report420FilterOptions } from "../report-4-20";
import { Report420TableDataRequest } from "../data-request/Report420TableDataRequest";
import { Report420JournalRequest } from "../journal/Report420JournalRequest";
import { Report420FilterSettingsForm } from "../settings-form/Report420FilterSettingsForm";
import { Report420FilterSettings } from "../settings/Report420FilterSettings";
import { Report420Row } from "../table-data/Report420Row";
import { rounding } from "../../../common/utils/formattingUtils";

export class Report420Facade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(report420FilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== '4-20') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== '4-20') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        const budgetTypeOptions = cloneDeep(
            options.budgetTypes.filter(it => settings.budgetTypes.includes(it.value))
        )

        let measureUnitOption  = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnitOption')
        }
        measureUnitOption = { ...measureUnitOption }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: Report420FilterSettingsForm = {
            // Base:
            key: '4-20',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            region: regionOption,
            date: new Date(settings.date),
            budgetTypes: budgetTypeOptions,
            expenses: cloneDeep(settings.expenses),
            measureUnit: measureUnitOption,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo,
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== '4-20') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        
        const strDate = moment(form.date).format('YYYY-MM-DD')

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: Report420FilterSettings = {
            // Base:
            reportName: '4-20',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            region: form.region.value,
            date: strDate,
            budgetTypes: form.budgetTypes.map(it => it.value),
            expenses: cloneDeep(form.expenses),
            indicators: [],
            measureUnit: form.measureUnit.value,
            roundUpTo: roundUpTo
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(report420FilterOptions)

        const emptyForm: Report420FilterSettingsForm = {
            key: '4-20',
            // Base fields
            reportName: options.reportNames.find(it => it.value === '4-20')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'EXPENSES')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            // Custom fields            
            region: null,
            date: null,
            budgetTypes: [],
            expenses: cloneDeep(report420ExpensesClassificators),
            measureUnit: null,
            roundUp: false,
            roundUpTo: null
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== '4-20') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        const abps = (form.expenses.find(it => it.key === 'abp')?.selected ?? []).map(it => String(it))
        
        const date = form.date
        if (!date) {
            throw new Error('Необходимо ввести "Дата"')
        }

        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        
        const regionOption = form.region
        if (!regionOption) {
            throw new Error('Необходимо ввести "Регион"')
        }

        let isSpfIncluded = false
        if (form.expenses.find(it => it.key === 'spf')?.active) {
            isSpfIncluded = true
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strDate = moment(date).format('YYYY-MM-DD')

        const params: Report420TableDataRequest = {
            admin: abps,
            date: strDate,
            region: regionOption.value,
            spf: isSpfIncluded,
            budgetType: form.budgetTypes.map(it => `0${it.value}`),
            measureUnit: form.measureUnit.value
        }
        if (roundUpTo != null) {
            params.round = roundUpTo
        }

        const queryString = new URLSearchParams(
            ((params as unknown) as Record<string, string>)
        ).toString()

        const response = await fetch(`/api/budget-execution-report-constructor/data-4-20-spf?${queryString}`)
        if (!response.ok) {
            const body = await response.json()
            if (body.key && body.key === 'modules.budgetExecutionRC.error.guListIsEmpty') {
                throw new Error('По текущим параметрам отстутсвуют ГУ')                
            }
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseData = await response.json()
        const tableData: Report420Row[] = [responseData.total].concat(responseData.list)

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: Report420JournalRequest = {
            reportName: "4-20", // Приходится задавать для поддержки generics
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            date: strDate,
            spf: isSpfIncluded,
            table_data: responseData
        }

        const result: TableDataAndJournalParams = {
            tableData: tableData,
            journalParams: journalParams
        }

        return result
    }
}

