


































import { Vue, Component } from 'vue-property-decorator'
import { makeToast } from '@/modules/budget/monitoring/reports-constructor/common/utils/otherUtils'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { JournalResponse } from '../../common/types/JournalResponse'
import { JournalRecord } from '../../common/types/JournalRecord'
import { ReportName } from '../../common/types/roots/fields'

const tempTableFields = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'Наименование отчета' },
    { key: 'reportType', label: 'Вид' },
    { key: 'year', label: 'Год' },
    { key: 'region', label: 'Регион' },
    { key: 'cls', label: 'Кл' },
    { key: 'pcl', label: 'Подкл' },
    { key: 'abp', label: 'АБП' },
    { key: 'gu', label: 'ГУ' },
    { key: 'prg', label: 'ПРГ' },
    { key: 'mutuallyRedeeming', label: 'ВЗК' },
    { key: 'measureUnit', label: 'Ед. изм.' },
    { key: 'dataSource', label: 'Ист. данных' },
    { key: 'username', label: 'Пользователь' },
    { key: 'creationTimestamp', label: 'Дата и время создания' },
    { key: 'status', label: 'Статус' },
    { key: 'action', label: '' },
]

@Component
export default class Journal extends Vue {

    public records: JournalRecord[] = []

    public progress = 100

    public readonly tableFields = tempTableFields

    public updateTabRef(tabRef: string) {
        this.$emit('update-tab-ref', tabRef);
    }

    public handleSetReportParams(params: any) {
        this.$emit("set-report-params", params)
    }

    get userId(): string {
        return this.$store.state.user.sub;
    }

    private async created() {
        await this.loadJournalRecords()
    }

    public getNameMap(mapItem: any, key: string): string {
        if (typeof mapItem === 'object' && (mapItem ?? false)) {
            let label = ''
            mapItem.forEach((item: any, index: number) => {
                label += item
                if ((index + 1) !== mapItem.length) {
                    label += ', '
                }
            })
            return label
        }
        
        // @ts-ignore
        const label = nameMap[mapItem]
        if (typeof label === 'string') {
            return label
        }

        if (key === 'creationTimestamp') {
            return this.formatDateString(mapItem)
        }

        return mapItem
    }

    private async loadJournalRecords() {
        try {
            this.progress = 50
            const response = await fetch(`/api-py/monitoring/reports-constructor/journal/${this.userId}`)
            if (!response.ok) {
                throw new Error('Ошибка загрузики журнала')
            } 
            const responseRecords: JournalResponse[] = await response.json()
            this.progress = 70
            const tempRecords: JournalRecord[] = responseRecords.map(record => {
                let region: string = ''
                if ('region' in record.filter_settings) {
                    region = record.filter_settings.region
                } else if ('regions' in record.filter_settings) {
                    region = record.filter_settings.regions.reduce((acc, current) => {
                        return `${acc}, ${current}`
                    }, '')
                    .substring(1)
                    .trim()
                }

                const temp: JournalRecord = {
                    id: record.id,
                    name: record.name,
                    reportName: record.filter_settings.reportName,
                    reportType: record.filter_settings.reportType,
                    year: 'year' in record.filter_settings ? record.filter_settings.year : null,
                    region: region,
                    cls: 'incomes' in record.filter_settings ? (record.filter_settings.incomes?.find(it => it.key === 'cls')?.selected.reduce((acc, current) => `${acc}, ${current}`, '').substring(1).trim() ?? null) : null,
                    pcl: 'incomes' in record.filter_settings ? (record.filter_settings.incomes?.find(it => it.key === 'pcl')?.selected.reduce((acc, current) => `${acc}, ${current}`, '').substring(1).trim() ?? null) : null,
                    abp: 'expenses' in record.filter_settings ? (record.filter_settings.expenses?.find(it => it.key === 'abp')?.selected.reduce((acc, current) => `${acc}, ${current}`, '').substring(1).trim() ?? null) : null,
                    gu: 'expenses' in record.filter_settings ? (record.filter_settings.expenses?.find(it => it.key === 'gu')?.selected.reduce((acc, current) => `${acc}, ${current}`, '').substring(1).trim() ?? null) : null,
                    prg: 'expenses' in record.filter_settings ? (record.filter_settings.expenses?.find(it => it.key === 'prg')?.selected.reduce((acc, current) => `${acc}, ${current}`, '').substring(1).trim() ?? null) : null,
                    mutuallyRedeeming: 'mutuallyRedeeming' in record.filter_settings ? record.filter_settings.mutuallyRedeeming : null,
                    measureUnit: 'measureUnit' in record.filter_settings ? record.filter_settings.measureUnit : null,
                    dataSource: 'dataSource' in record.filter_settings ? record.filter_settings.dataSource : null,
                    username: record.user_id,
                    creationTimestamp: record.creation_timestamp,
                    status: 'ОБРАБОТАНО'
                }
                return temp
            })
            this.records = tempRecords
        } catch (error) {
            makeToast(this, 'Не удалось загрузить данные журнала', 'Ошибка', 'danger')
        } finally {
            this.progress = 100
        }
    }

    public async downloadReport(record: JournalRecord) {
        makeToast(this, 'Скоро начнется скачивание. Пожалуйста ожидайте.', 'Внимание!', 'info')
        this.progress = 10
        try {
            this.progress = 50
            const response = await fetch(`/api-py/monitoring/reports-constructor/journal/file/${record.id}`)
            if (!response.ok) {
                throw new Error('')
            }
            
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            const filename = record.name
            let extension = '.xls'
            const xlsxReports: ReportName[] = [
                'IRBO',
                '1-27-ACT',
                'DKI-P',
                'EIB',
                'INCOME',
                'CASH-EXEC',
                'RS',
            ]
            if (xlsxReports.includes(record.reportName)) {
                extension = '.xlsx'
            }
            link.href = url
            link.setAttribute('download', filename + extension)
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            makeToast(this, 'Не удалось загрузить отчет', 'Ошибка', 'danger')
        } finally {
            this.progress = 100
        }
    }

    // TODO: move to dateUtils
    private formatDateString(dateString: string): string {
        const date = new Date(dateString);

        // Extract date components
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Extract time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        // Format the date and time components into the desired format
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
}
