import { cloneDeep } from "lodash";
import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allMeasureUnitsOptions, allMutuallyRedeemingLevelOptions, allMutuallyRedeemingOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions, allSegmentationOptions } from "../../common/baseOptions";
import { Classificator } from "../../common/types/Classificator";
import { Template } from "../../common/types/Template";
import { ReportCashExecFilterSettings } from "./settings/ReportCashExecFilterSettings";
import { ReportCashExecFilterOptions } from "./options/ReportCashExecFilterOptions";
import { Indicator } from "../../common/types/Indicator";
import { Option } from "../../common/types/Option";
import { ByForm, StatusType } from "../../common/types/roots/fields";

export const reportCashExecIncomeClassificators: Classificator[] = [
    { key: 'kat', active: true, selected: [] },
    { key: 'cls', active: true, selected: [] },
    { key: 'pcl', active: true, selected: [] },
    { key: 'spf', active: true, selected: [] }
]
export const reportCashExecExpenseClassificators: Classificator[] = [
    { key: 'gr',  active: true, selected: [] },
    { key: 'pgr', active: true, selected: [] },
    { key: 'abp', active: true, selected: [] },
    { key: 'prg', active: true, selected: [] },
    { key: 'ppr', active: true, selected: [] },
    { key: 'spf', active: true, selected: [] },
]
export const reportCashExecIncomeIndicators: Indicator[] = [
    { key: 'plan', active: true },
    { key: 'plan_utch_year', active: true },
    { key: 'plan_utch_month', active: true },
    { key: 'plan_corr_year', active: true },
    { key: 'plan_corr_month', active: true },
    { key: 'month_sum', active: true },
    { key: 'sumrg', active: true },
    { key: 'exec_cash_curr_month', active: true },
    { key: 'delta_sumrg', active: true },
    { key: 'remain_year', active: true },
    { key: 'remain_period', active: true },
    { key: 'exec_pct_year', active: true },
    { key: 'exec_pct_period', active: true },
    { key: 'sumrg_past_year', active: true },
    { key: 'growth_rate', active: true },
    { key: 'dev_from_past', active: true }
]
export const reportCashExecExpenseIndicators: Indicator[] = [
    { key: 'plan_plat', active: true },
    { key: 'utch_budget_rep_year', active: true },
    { key: 'utch_budget_rep_month', active: true },
    { key: 'scor_plan_rep_year', active: true },
    { key: 'scor_plan_rep_month', active: true },
    { key: 'month_sum_plat', active: true },
    { key: 'sumrg', active: true },
    { key: 'curr_sumrg', active: true },
    { key: 'delta_sumrg', active: true },
    { key: 'reaminder_plan_rep_year', active: true },
    { key: 'remainder_plan_rep_period', active: true },
    { key: 'execution_rate_year', active: true },
    { key: 'execution_rate_period', active: true },
    { key: 'period_sum_obaz', active: true },
    { key: 'month_sum_obaz', active: true },
    { key: 'registrob', active: true },
    { key: 'remainder_obaz_year', active: true },
    { key: 'remainder_payment_cur_per', active: true },
    { key: 'execution_obaz_year', active: true },
    { key: 'execution_rate_obaz_cur_year', active: true },
    { key: 'sumrg_past', active: true },
    { key: 'increase_rate', active: true },
    { key: 'deviation_cur_year', active: true }
]
export const reportCashExecStatusOptions: Option<StatusType>[] = [
    { value: '39', label: '39 - Утверждено в ИС КК' },
    { value: '26', label: '26 - Согласовано УО' },
]
export const cashExecByFormOptions: Option<ByForm>[] = [
    { value: '2-19', label: '2-19' },
    { value: '4-20', label: '4-20' },
    { value: '5-42', label: '5-42' },
]

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportCashExecFilterSettings = {
    // Base:
    reportName: 'CASH-EXEC',
    reportType: 'REGULATED',
    classificationType: 'INCOMES',
    dataSource: 'IISK',
    periodicity: 'DAY',
    // Custom:
    date: '',
    budgetTypes: [],
    region: '',
    statuses: [],
    isTree: true,
    byForm: '2-19',
    measureUnit: 'THOUSAND',
    roundUpTo: null,
    mutuallyRedeeming: 'ONLY',
    mutuallyRedeemingLevels: [],
    segmentation: 'KBK',
    incomeTotal: false,
    incomes: [],
    expenseTotal: false,
    expenses: [],
    indicators: cloneDeep(reportCashExecIncomeIndicators)
}

export const reportCashExecBaseTemplate: Template = {
    id: null,
    name: 'Отчет о кассовом исполнении',
    description: null,
    category: '50',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const reportCashExecFilterOptions: ReportCashExecFilterOptions = {
    // Base:
    key: 'CASH-EXEC',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions.filter(it => it.value !== 'MIXED')),
    dataSources: cloneDeep(allDataSourcesOptions.filter(it => it.value !== 'MIXED')),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    regions: [], // Загружается с бэка
    mutuallyRedeeming: cloneDeep(allMutuallyRedeemingOptions),
    mutuallyRedeemingLevels: cloneDeep(allMutuallyRedeemingLevelOptions),
    statuses: cloneDeep(reportCashExecStatusOptions),
    byForms: cloneDeep(cashExecByFormOptions),
    segmentations: cloneDeep(allSegmentationOptions),
    incomes: cloneDeep(reportCashExecIncomeClassificators),
    expenses: cloneDeep(reportCashExecExpenseClassificators),
    indicators: cloneDeep(reportCashExecIncomeIndicators)
}
