




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import VueElementLoading from "vue-element-loading"
import { cloneDeep } from "lodash"
import VueDraggable from 'vuedraggable'
import Datepicker from "vue2-datepicker"
import moment from 'moment'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { getLastDateOfQuarter, getLastDateOfMonth } from '@/modules/budget/monitoring/reports-constructor/common/utils/dateUtils'
import { Classificator } from "../../common/types/Classificator"
import { TemplateCreateUpdateRequest } from "../../common/types/TemplateCreateUpdateRequest"
import { Template, TemplateForm } from "../../common/types/Template"
import { Option } from "../../common/types/Option"
import { FilterSettingsForm } from "../../common/types/roots/FilterSettingsForm"
import { FilterOptions } from "../../common/types/roots/FilterOptions"
import { Indicator } from "../../common/types/Indicator"
import { BudgetStructure, BudgetType, ByForm, CertificateLevel, CertificateType, ClassificationType, ExpenseType, Segmentation, StatusType } from "../../common/types/roots/fields"
import { allBudgetTypesOptions, allIncomeClassificators, allReportNamesOptions } from "../../common/baseOptions"
import { FilterSettings } from "../../common/types/roots/FilterSettings"
import { makeToast } from "../../common/utils/otherUtils"
import { TableData } from "../../common/types/roots/TableData"
import { LoadToJournalRequest } from "../../common/types/roots/LoadToJournalRequest"
import { reportCashExecExpenseIndicators, reportCashExecIncomeIndicators } from "../../reports/report-cash-exec/report-cash-exec"

import { Facade } from "../../common/Facade"
import { Report127Facade } from "../../reports/report-1-27/facade/Report127Facade"
import { Application2Facade } from "../../reports/application-2/facade/Application2Facade"
import { Report420Facade } from "../../reports/4-20/facade/Report420Facade"
import { ReportIRBOFacade } from "../../reports/report-irbo/facade/ReportIRBOFacade"
import { ReportDviFacade } from "../../reports/dvi/facade/ReportDviFacade"
import { Report127ActFacade } from "../../reports/report-1-27-act/facade/Report127ActFacade"
import { ReportDkiRFacade } from "../../reports/dki-r/facade/ReportDkiRFacade"
import { ReportDkiPFacade } from "../../reports/dki-p/facade/ReportDkiPFacade"
import { ReportEibFacade } from "../../reports/eib/facade/ReportEibFacade"
import { ReportIncomeFacade } from "../../reports/report-income/facade/ReportIncomeFacade"
import { ReportCashExecFacade } from "../../reports/report-cash-exec/facade/ReportCashExecFacade"
import { ReportRsFacade } from "../../reports/rs/facade/ReportRsFacade"

import Application2 from "@/modules/budget/monitoring/reports-constructor/reports/application-2/table-component/application-2.vue"
import Report127 from "@/modules/budget/monitoring/reports-constructor/reports/report-1-27/table-component/report-1-27.vue"
import Report420 from "@/modules/budget/monitoring/reports-constructor/reports/4-20/table-component/report-4-20.vue"
import ReportIRBO from "@/modules/budget/monitoring/reports-constructor/reports/report-irbo/table-component/report-irbo.vue"
import ReportDvi from "@/modules/budget/monitoring/reports-constructor/reports/dvi/table-component/report-dvi.vue"
import ReportRDki from "../../reports/dki-r/table-component/report-dki-r.vue"
import Report127Act from "../../reports/report-1-27-act/table-component/report-1-27-act.vue"
import ReportDkiP from "../../reports/dki-p/table-component/report-dki-p.vue"
import ReportEib from "../../reports/eib/table-component/report-eib.vue"
import ReportIncome from "@/modules/budget/monitoring/reports-constructor/reports/report-income/table-component/report-income.vue"
import ReportCashExec from "@/modules/budget/monitoring/reports-constructor/reports/report-cash-exec/table-component/report-cash-exec.vue"
import ReportRs from "../../reports/rs/table-component/report-rs.vue"

const personalCategoryOption = { label: 'Личные шаблоны', value: null }
const tempTemplateForm: TemplateForm = {
    id: null,
    name: '',
    description: null,
    category: { ...personalCategoryOption },
    regions: null
}
const emptyIncomeOptions: Record<string, number[]> = {
    kat: [],
    cls: [],
    pcl: [],
    spf: [],
}
const emptyExpenseOptions: Record<string, number[]> = {
    gr: [],
    pgr: [],
    abp: [],
    gu: [],
    prg: [],
    ppr: [],
    spf: [],
}

@Component({
    components: {
        'loading': VueElementLoading,
        'draggable': VueDraggable,
        'date-picker': Datepicker,
        'application-2': Application2,
        'report-1-27': Report127,
        'report-4-20': Report420,
        'report-irbo': ReportIRBO,
        'report-dvi': ReportDvi,
        'report-dki-r': ReportRDki,
        'report-dki-p': ReportDkiP,
        'report-1-27-act': Report127Act,
        'report-eib': ReportEib,
        'report-income': ReportIncome,
        'report-cash-exec': ReportCashExec,
        'report-rs': ReportRs,
    }
})
export default class FiltersForm extends Vue {
    // #region Props
    @Prop({
        type: Object,
        required: true
    })
    public readonly template!: Template

    @Prop({
        type: Function,
        required: true
    })
    public onSelectReport!: any
    // #endregion

    // #region Data
    public templateName = this.template.name
    private readonly moduleCode = '005.003.007'
    // Если тест/релиз то "00"
    private oblastKato: string = ''
    private facade: Facade | null = null
    public loading = false
    public regionLoading = false
    public isFilterPanelExpanded = true
    public saveAsNewModal = false
    public tableData: TableData | null = null
    public progress = 100
    private datePeriod: string | null = null

    public templateForm: TemplateForm = cloneDeep(tempTemplateForm)
    public templateFormOptions = {
        reportCategoryOptions: [] as Option<string | null>[],
        regionOptions: [] as Option<string>[]
    }

    public filterSettingsForm: FilterSettingsForm | null = null
    public filterOptions: FilterOptions | null = null

    public isAllSelectedTemplateRegion: boolean = false

    // Дублирующие переменные incomes/outcomes
    // нужны для того чтобы при драггинге (dragging)
    // компоненты отрисовки таблиц не ререндерился с новым порядком
    // и сохранял тот порядок который был при нажатий "Сформировать" 
    public incomesToPass: Classificator[] = []
    public expensesToPass: Classificator[] = []

    public categoryNameMap: Record<string, string> = {
        'kat': 'КАТ:',
        'cls': 'КЛ:',
        'pcl': 'ПКЛ:',
        'spf': 'СПФ:',
        'gr': 'ФГ:',
        'pgr': 'ФПГ:',
        'abp': 'АБП:',
        'gu': 'ГУ:',
        'prg': 'БП:',
        'ppr': 'БПП:'
    }

    public incomeOptions: Record<string, number[]> = cloneDeep(emptyIncomeOptions)
    public expenseOptions: Record<string, number[]> = cloneDeep(emptyExpenseOptions)

    // Чтобы сохранить параметры в момент нажатия 'сформировать',
    // нужно потому что показатели (indicators) задаются после формирования
    private journalParams: LoadToJournalRequest | null = null

    // Нужно чтобы затригерить re-render компонентов отрисовок таблиц
    public tableKey = 0

    private oblastCode: string = ''
    // #endregion

    // #region Computed
    get isNewForm(): boolean {
        return !this.template.id
    }

    get userId(): string {
        return this.$store.state.user.sub;
    }

    get username(): string {
        return this.$store.state.user.preferred_username
    }

    get hasEditAccess(): boolean {
        const accessLevel = this.$store.state.user.userModules.find((it: any) => it.modules === this.moduleCode)?.access_level
        return accessLevel >= 2
    }

    get isAdmin(): boolean {
        const accessLevel = this.$store.state.user.userModules.find((it: any) => it.modules === this.moduleCode)?.access_level
        return accessLevel == 3
    }

    get isStructuredBudgetPossible(): boolean {
        if (this.filterSettingsForm == null 
        || !('incomes' in this.filterSettingsForm) 
        || !('expenses' in this.filterSettingsForm)) {
            return false
        }
        let isPossible: boolean = true

        // 1. Проверка по выбранности (должны быть оба)
        const hasChosenIncome = this.filterSettingsForm.incomes.filter(it => it.active).length > 0
        const hasChosenExpense = this.filterSettingsForm.expenses.filter(it => it.active).length > 0
        if (!hasChosenIncome || !hasChosenExpense) {
            isPossible = false
        }

        // Side-effect костыль (чтобы не создавать watch)
        if (!isPossible) {
            if (this.filterSettingsForm?.key === '1-27' && this.filterOptions?.key === '1-27') {
                this.filterSettingsForm.budgetStructure = this.filterOptions.budgetStructures.find(it => it.value === 'WITHOUT') ?? null         
            }
        }

        return isPossible
    }

    get budgetStructureOptions(): Option<BudgetStructure>[] {
        if (this.filterSettingsForm?.key === '1-27' && this.filterOptions?.key === '1-27') {
            const options = this.isStructuredBudgetPossible ? this.filterOptions.budgetStructures : this.filterOptions.budgetStructures.filter(it => it.value === 'WITHOUT')
            return options
        }

        if (this.filterOptions && 'budgetStructures' in this.filterOptions) {
            return this.filterOptions.budgetStructures
        }
        return []
    }

    get classificationTypeOptions(): Option<ClassificationType>[] {
        if (!this.filterOptions) {
            return []
        }
        if (this.filterOptions.key === 'RS' && this.filterSettingsForm?.key === 'RS') {
            if (this.filterSettingsForm.certificateLevel?.value === 'ABP') {
                const expenseOptions = this.filterOptions.classificationTypes.filter(it => it.value === 'EXPENSES')
                
                // Side-effect чтобы присвоить значение "Классификация"
                if (this.filterSettingsForm.classificationType.value !== 'EXPENSES') {
                    this.filterSettingsForm.classificationType = cloneDeep(expenseOptions[0])
                }
                
                return expenseOptions
            }
        }
        return this.filterOptions.classificationTypes
    }

    get byFormFilterOptions(): Option<string>[] {
        if (this.filterSettingsForm?.key === 'CASH-EXEC' && this.filterOptions?.key === 'CASH-EXEC') {
            let byFormOptions: Option<ByForm>[] = []
            let defaultOption: Option<ByForm> | null = null
            if (this.filterSettingsForm.classificationType.value === 'INCOMES') {
                byFormOptions = this.filterOptions.byForms.filter(it => it.value === '2-19')
                defaultOption = byFormOptions.find(it => it.value === '2-19') ?? null
            } else if (this.filterSettingsForm.classificationType.value === 'EXPENSES') { 
                byFormOptions = this.filterOptions.byForms.filter(it => ['4-20', '5-42'].includes(it.value))
                defaultOption = byFormOptions.find(it => it.value === '4-20') ?? null
            }

            // Side-effect чтобы присвоить значение "По форме"
            if (defaultOption) {
                this.filterSettingsForm.byForm = defaultOption
            }

            return byFormOptions
        }

        if (this.filterOptions && 'byForms' in this.filterOptions) {
            return this.filterOptions.byForms
        }
        return []
    }

    get statusOptions(): Option<StatusType>[] {
        if (!this.filterOptions || !('statuses' in this.filterOptions)) {
            return []
        }

        if (this.filterOptions.key === 'RS' && this.filterSettingsForm?.key === 'RS') {
            if (this.filterSettingsForm.certificateLevel?.value === 'UO') {
                return this.filterOptions.statuses.filter(it => 
                    ['39', '26', '27', '1', '37', '38'].includes(it.value)
                )
            } else if (this.filterSettingsForm.certificateLevel?.value === 'ABP') {
                return this.filterOptions.statuses.filter(it => 
                    ['39', '26', '27', '1', '37', '38', '32', '31', '28'].includes(it.value)
                )
            }
            return []
        }

        return this.filterOptions.statuses
    }

    get certificateTypeOptions(): Option<CertificateType>[] {
        if (!this.filterOptions || !('certificateTypes' in this.filterOptions)) {
            return []
        }

        if (this.filterSettingsForm?.key === 'RS' && this.filterOptions?.key === 'RS') {
            if (this.filterSettingsForm.classificationType.value === 'INCOMES') {
                return this.filterOptions.certificateTypes.filter(it => it.value !== 'INTERNAL')
            }
            
            return this.filterOptions.certificateTypes
        }

        return this.filterOptions.certificateTypes
    }

    get isSeparateByRegionCheckboxDisabled(): boolean {
        if (this.filterSettingsForm?.key === '1-27') {
            const form = this.filterSettingsForm

            const anyActiveIncomeClassificator = form.incomes.filter(it => it.active).length > 0
            const anyActiveExpenseClassificator = form.expenses.filter(it => it.active).length > 0

            const anyActiveClassification = anyActiveIncomeClassificator || anyActiveExpenseClassificator
            const anyActiveTotal = form.incomeTotal || form.expenseTotal

            if (anyActiveClassification && anyActiveTotal) {
                // Side-effect to uncheck 'В разрезе бюджета'
                this.filterSettingsForm.separateByRegion = false
                return true
            }
        }

        return false
    }

    get isDecodingActualRegionalBudgetCheckboxDisabled(): boolean {
        if (this.filterSettingsForm?.key === 'IRBO') {
            const lastFour = this.filterSettingsForm.region?.value.slice(2);
            const lastTwo = this.filterSettingsForm.region?.value.slice(4);
            return (            
                lastFour !== "0000"  &&
                (
                    lastFour === "0101" ||
                    lastTwo === "00"
                )
            )
        }
        if (this.filterSettingsForm?.key === 'INCOME') {
            if (!this.filterSettingsForm.separateByRegion) {
                return true
            }
            return false
        }

        return false
    }

    get isIsTreeDisabled(): boolean {
        if (this.filterSettingsForm?.key === 'INCOME') {
            return !this.isDecodingActualRegionalBudgetCheckboxDisabled
        }
        return false
    }

    get isDataSourceDisabled(): boolean {
        if (this.filterSettingsForm?.key === 'CASH-EXEC' || this.filterSettingsForm?.key === 'INCOME') {
            return false
        }
        return true
    }

    get isByFormDisabled(): boolean {
        return false
    }

    get isClassificationTypeDisabled(): boolean {
        if (['CASH-EXEC', 'RS'].includes(this.filterSettingsForm?.key ?? '')) {
            return false
        }
        return true
    }

    get isPaymentTypeDisabled(): boolean {
        if (this.filterSettingsForm?.key === 'RS') {
            return this.filterSettingsForm.classificationType.value !== 'EXPENSES'
        }
        return false
    }

    get isCertificateLevelDisabled(): boolean {
        if (this.filterSettingsForm?.key === 'RS') {
            return this.filterSettingsForm.classificationType.value === 'INCOMES'
        }
        return false
    }

    get isIncomeClassificationsHidden(): boolean {
        if (this.filterSettingsForm?.key === 'CASH-EXEC') {
            return this.filterSettingsForm.segmentation?.value === 'REGION'
        }
        return false
    }

    get isExpenseClassificationsHidden(): boolean {
        if (this.filterSettingsForm?.key === 'CASH-EXEC') {
            return this.filterSettingsForm.segmentation?.value === 'REGION'
        }
        return false
    }

    get isReportTypeHidden(): boolean {
        return this.filterSettingsForm?.key === 'CASH-EXEC'
    }

    get isPeriodicityHidden(): boolean {
        return this.filterSettingsForm?.key === 'CASH-EXEC'
    }
    // #endregion

    // #region Lifecycles
    private async created() {
        try {
            this.loading = true

            this.setFacade()
            await this.setOblastKato()
            await this.setOblastCode()

            // Options
            await this.setTemplateRegionOptions()
            await this.setReportCategoryOptions()
            await this.setClassificationOptions()
            this.setFilterOptions()
            await this.setRegionOptionsOnCreate()

            // Forms
            this.setFilterSettingsForm()
            this.setTemplateForm()

            this.loading = false
        } catch (error) {
            this.loading = true
            if (error instanceof Error) {
                makeToast(this, error.message, 'Ошибка', 'danger')
                console.error(error.message)
            } else {
                makeToast(this, 'Ошибка загрузки', 'Ошибка', 'danger')
                console.error(error);
            }
        }
    }
    // #endregion

    // #region Methods
    /** Метод-обертка для $t */
    public tr(key: any): string {
        if (typeof key !== 'string') {
            return ''
        }

        const translationKey = 'modules.budget_execution_monitoring.reports_constructor'
        const text: string = String(this.$t(`${translationKey}.${key}`))

        return text
    }

    private setFacade() {
        switch (this.template.filterSettings.reportName) {
            case 'PR2': this.facade = new Application2Facade(); break;
            case '1-27': this.facade = new Report127Facade(); break;
            case '4-20': this.facade = new Report420Facade(); break;
            case 'IRBO': this.facade = new ReportIRBOFacade(); break;
            case 'DVI': this.facade = new ReportDviFacade(); break;
            case '1-27-ACT': this.facade = new Report127ActFacade(); break;
            case 'DKI-R': this.facade = new ReportDkiRFacade(); break;
            case 'DKI-P': this.facade = new ReportDkiPFacade(); break;
            case 'EIB': this.facade = new ReportEibFacade(); break;
            case 'INCOME': this.facade = new ReportIncomeFacade(); break;
            case 'CASH-EXEC': this.facade = new ReportCashExecFacade(); break;
            case 'RS': this.facade = new ReportRsFacade(); break;
            default: throw new Error('Не поддерживаемый исходный шаблон');
        }
    }

    private async setOblastKato() {
        const response = await fetch('/api/dict/server-description')
        if (!response.ok) {
            throw new Error('Ошибка загрузки данных о среде')
        }
        const serverDescription = await response.text()
        if (serverDescription === 'release' || this.$store.state._instanceCode === '') {
            this.oblastKato = '00'
        } else {
            this.oblastKato = this.$store.state._instanceCode
        }
    }

    private async setOblastCode() {
        const isTestOrNsi = this.oblastKato === '00' || this.oblastKato === '99'
        if (!isTestOrNsi) {
            const response = await fetch(`/api-py/monitoring/reports-constructor/tax-region/${this.oblastKato}`)
            if (!response.ok) {
                throw new Error('')
            }
            const oblastCode = await response.json()
            this.oblastCode = oblastCode
        }
    }

    public onTemplateRegionInput(currentValues: Option<string>[] | Option<string>) {
        const allRegionCode = '000000'

        // 'Отдельные регионы' -> 'Все'
        if (Array.isArray(currentValues)) {
            const lastSelected = currentValues[currentValues.length - 1]
            if (lastSelected.value === allRegionCode) {
                this.isAllSelectedTemplateRegion = true
                this.templateForm.regions = { ...lastSelected }
            }
            return
        }
        
        // 'Все' -> 'Отдельные регионы'
        const isObjectButNotArray = typeof currentValues === "object" && currentValues !== null && !Array.isArray(currentValues)
        const fromAllToSeparateRegions = isObjectButNotArray && currentValues.value !== allRegionCode
        if (fromAllToSeparateRegions) {
            this.isAllSelectedTemplateRegion = false
            this.templateForm.regions = [currentValues]
        }
    }

    public onBackToTemplatesList() {
        this.$emit("set-journal-params", null)
        this.onSelectReport(null)
    }

    public onExpenseTypeInput(selectedOption: Option<ExpenseType>) {
        if (this.filterSettingsForm == null || !('expenses' in this.filterSettingsForm)) {
            return
        }

        if (selectedOption.value === 'FUNCTIONAL') {
            const emptyForm = this.facade!.getEmptyForm()
            if ('expenses' in emptyForm) {
                const functionalExpenseClassificators = emptyForm.expenses
                this.filterSettingsForm.expenses = functionalExpenseClassificators
            }
        } else if (selectedOption.value === 'ECONOMICAL') {
            const economicalClassificators = cloneDeep(allIncomeClassificators)
            this.filterSettingsForm.expenses = economicalClassificators
        } else {
            throw new Error('not supported expenseType')
        }
    }

    public setBudgetTypeFromRegion(regionOptionsParam: Option<string>[] | Option<string> | null) {
        let regionOptions: Option<string>[] | Option<string> = []

        if (regionOptionsParam) {
            regionOptions = regionOptionsParam 
        }
        
        if (
            !this.filterSettingsForm
            || !this.filterOptions
            || !('budgetTypes' in this.filterOptions)
            || !('budgetTypes' in this.filterSettingsForm)
        ) {
            return
        }
        
        let regionOptionsArray: Option<string>[] = []
        if (Array.isArray(regionOptions)) {
            regionOptionsArray = regionOptions
        } else {
            regionOptionsArray = [regionOptions]
        }

        this.filterSettingsForm.budgetTypes = []
        // Проставить соответсвующий budget_type
        const budgetTypes: BudgetType[] = []
        regionOptionsArray.forEach(regionOption => {
            const code: string = regionOption.value
            if (['302001', '301901', '300401'].includes(code) && this.template.filterSettings.reportName === 'PR2') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
            } else if (code.substring(2) === '0000') {
                if (budgetTypes.notIncludes(2)) {
                    budgetTypes.push(2)
                }
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            } else if (code.substring(4) === '00') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            } else if (code.substring(2) === '0101') {
                if (budgetTypes.notIncludes(2)) {
                    budgetTypes.push(2)
                }
            } else if (code.substring(4) === '01') {
                if (budgetTypes.notIncludes(3)) {
                    budgetTypes.push(3)
                }
            } else if (code.substring(4) !== '01') {
                if (budgetTypes.notIncludes(6)) {
                    budgetTypes.push(6)
                }
            }
        })

        const tempAllBudgetTypesOptions = cloneDeep(allBudgetTypesOptions) 
        const tempOptions: Option<BudgetType>[] = []
        budgetTypes.sort((a: number, b: number) => a - b).forEach((budget: number) => {
            const budgetTypeOption = tempAllBudgetTypesOptions.find(item => item.value === budget)!
            tempOptions.push({...budgetTypeOption})
        })

        this.filterOptions.budgetTypes = tempAllBudgetTypesOptions.filter(it => budgetTypes.includes(it.value))
        this.filterSettingsForm.budgetTypes = tempOptions
    }

    public onRegionInput(regionOptionsParam: Option<string>[] | Option<string> | null) {
        // Common handles:
        if (Array.isArray(regionOptionsParam)) {
            this.filterMultipleSelectedRegions(regionOptionsParam)
        }
        this.setBudgetTypeFromRegion(regionOptionsParam)

        // Report-specific handles:
        if (this.filterSettingsForm?.key === 'IRBO' && !Array.isArray(regionOptionsParam)) {
            const lastFour = regionOptionsParam?.value.slice(2)
            const lastTwo = regionOptionsParam?.value.slice(4)

            const shouldDecodingActualRegionalBudgetCheckboxOnAndDisbled = (
                lastFour !== "0000" &&
                (
                    lastFour === "0101" ||
                    lastTwo === "00"
                )
            )
            if (shouldDecodingActualRegionalBudgetCheckboxOnAndDisbled) {
                this.filterSettingsForm.decodingActualRegionalBudget = shouldDecodingActualRegionalBudgetCheckboxOnAndDisbled
            }
            
            this.setMutuallyRedeemingLevelsFromRegion(regionOptionsParam ?? null)
        }

        if (
            this.filterSettingsForm?.key === 'CASH-EXEC'
            && this.filterOptions?.key === 'CASH-EXEC'
            && !Array.isArray(regionOptionsParam)
        ) {
            const lastTwo = regionOptionsParam?.value.slice(4)
            if (lastTwo === '00') {
                this.filterSettingsForm.mutuallyRedeeming = cloneDeep(
                    this.filterOptions.mutuallyRedeeming.find(it => it.value === 'WITHOUT')!
                )
            } else {
                this.filterSettingsForm.mutuallyRedeeming = cloneDeep(
                    this.filterOptions.mutuallyRedeeming.find(it => it.value === 'WITH')!
                )
            }
        }
    }

    public onSegmentationInput(option: Option<Segmentation> | null) {
        // Report-specific handles:
        if (this.filterSettingsForm?.key === 'CASH-EXEC') {
            if (option?.value === 'REGION') {
                const form = cloneDeep(this.filterSettingsForm)
                form.incomes.forEach(it => { it.active = false })
                form.expenses.forEach(it => { it.active = false })
                this.filterSettingsForm = form
            }
        }
    }

    public onCertificateLevelInput(option: Option<CertificateLevel> | null) {
        if (this.filterSettingsForm?.key === 'RS') {
            this.filterSettingsForm.statuses = []
        }
    }

    public onSeparateByRegionChange(value: boolean) {
        if (this.filterSettingsForm?.key === 'INCOME') {
            if (!this.filterSettingsForm.separateByRegion) {
                this.filterSettingsForm.decodingActualRegionalBudget = false;
                this.filterSettingsForm.regionsTotal = false;
            } else {
                this.filterSettingsForm.isTree = false;
            }
            
        }
    }

    public onClassificationCheckboxChange(key: string, value: boolean, type: 'INCOME' | 'EXPENSE') {
        // Report-specific handles:
        if (this.filterSettingsForm?.key === 'CASH-EXEC' && type === 'EXPENSE') {
            if (key === 'prg' && value) {
                this.filterSettingsForm.expenses.forEach(it => {
                    if (it.key === 'abp') {
                        it.active = true
                    }
                })
            }
            else if (key === 'abp' && !value) {
                this.filterSettingsForm.expenses.forEach(it => {
                    if (it.key === 'prg') {
                        it.active = false
                    }
                })
            }
            else if (key === 'pgr' && value) {
                this.filterSettingsForm.expenses.forEach(it => {
                    if (it.key === 'gr') {
                        it.active = true
                    }
                })
            }
            else if (key === 'gr' && !value) {
                this.filterSettingsForm.expenses.forEach(it => {
                    if (it.key === 'pgr') {
                        it.active = false
                    }
                })
            }
        }
        if (this.filterSettingsForm?.key === 'CASH-EXEC' && type === 'INCOME') {
            const order = ['kat', 'cls', 'pcl', 'spf']
            const keyIndex = order.indexOf(key)
            this.filterSettingsForm.incomes.forEach(it => {
                const i = order.indexOf(it.key)
                if (i <= keyIndex) {
                    if (value) {
                        it.active = value
                    }
                } else {
                    if (!value) {
                        it.active = value
                    }
                }
            })
        }
    }

    public setMutuallyRedeemingLevelsFromRegion(regionOption: Option<string> | null) {
        if (
            !regionOption 
            || !this.filterSettingsForm
            || !this.filterOptions
            || !('mutuallyRedeemingLevels' in this.filterSettingsForm)
            || !('mutuallyRedeemingLevels' in this.filterOptions)
        ) {
            return
        }

        const region = regionOption.value

        const lastFour = region.slice(2)
        const lastTwo = region.slice(4)

        if (lastFour === '0000' || lastFour === "0101") {
            this.filterSettingsForm.mutuallyRedeemingLevels = this.filterOptions.mutuallyRedeemingLevels
                .filter(it => ['TYPE-3', 'TYPE-4'].includes(it.value))
        } else if (lastTwo === '00') {
            this.filterSettingsForm.mutuallyRedeemingLevels = this.filterOptions.mutuallyRedeemingLevels
                .filter(it => it.value === 'TYPE-4')
        }
    }


    public setMutuallyRedeemingLevelsFromRegions(regionOption: Option<string>[] | null) {
        if (
            !regionOption 
            || !this.filterSettingsForm
            || !this.filterOptions
            || !('mutuallyRedeemingLevels' in this.filterSettingsForm)
            || !('mutuallyRedeemingLevels' in this.filterOptions)
        ) {
            return
        }

        const regionsSecond = regionOption.filter(x => (x.value.slice(2, 4) !== '00' && x.value.slice(2, 4) !== '01') && x.value.slice(4) === '00');

        if (regionsSecond.length > 0) {
            this.filterSettingsForm.mutuallyRedeemingLevels = this.filterOptions.mutuallyRedeemingLevels
                .filter(it => it.value === 'TYPE-4')
        } else {
            this.filterSettingsForm.mutuallyRedeemingLevels = this.filterOptions.mutuallyRedeemingLevels
                            .filter(it => ['TYPE-3', 'TYPE-4'].includes(it.value))
        }
    }

    public filterMultipleSelectedRegions(regionOptions: Option<string>[]) {
        if (!this.filterSettingsForm || !('regions' in this.filterSettingsForm)) {
            return
        }

        this.setMutuallyRedeemingLevelsFromRegions(regionOptions ?? null)

        const entireOblasts = regionOptions.filter(it => {
            const lastFour = it.value.slice(2)
            return lastFour === '0000'
        })
        if (entireOblasts.length > 0) {
            this.filterSettingsForm.regions = cloneDeep(entireOblasts)
            return
        }

        const entireRaiyons = regionOptions.filter(it => {
            const lastTwo = it.value.slice(4)
            return lastTwo === '00'
        })
        if (entireRaiyons.length > 0) {
            const firstFours = entireRaiyons.map(it => it.value.substring(0, 4))
            const entireRaiyonCodes = entireRaiyons.map(it => it.value)
            this.filterSettingsForm.regions = cloneDeep(
                regionOptions.filter(it => !firstFours.includes(it.value.substring(0, 4)) || entireRaiyonCodes.includes(it.value))
            )
            return
        }
    }

    public async onYearChange() {
        if (!this.filterSettingsForm) {
            return
        }
        switch (this.filterSettingsForm.periodicity.value) {
            case 'QUARTER':
                await this.onYearOrQuarterChange()
                break
            case 'MONTH':
                await this.onYearOrMonthChange()
                break
            default:
        }
    }

    public async onYearOrQuarterChange() {
        if (this.filterSettingsForm == null || this.filterOptions == null) {
            return
        }
        if (!('quarter' in this.filterSettingsForm)) {
            return
        }
        if (!('regions' in this.filterOptions)) {
            return
        }
        if (!this.filterSettingsForm.quarter?.value) {
            return
        }

        this.datePeriod = null

        const year = this.filterSettingsForm.year
        const quarter = this.filterSettingsForm.quarter.value
        if (!year || !quarter) {
            return
        }
        this.datePeriod = getLastDateOfQuarter(year, quarter)
        await this.setRegionOptions()
        if ('region' in this.filterSettingsForm) {
            // @ts-ignore
            const region = this.filterSettingsForm.region as Option<string> | null
            this.setBudgetTypeFromRegion(region)
        } 
        if ('regions' in this.filterSettingsForm) {
            // @ts-ignore
            const regions: Option<string>[] = this.filterSettingsForm.regions as Option<string>[]
            this.setBudgetTypeFromRegion(regions)
        }
    }

    public async onYearOrMonthChange() {
        if (this.filterSettingsForm == null || this.filterOptions == null || !('regions' in this.filterOptions) || !('month' in this.filterSettingsForm)) {
            return
        }

        this.datePeriod = null
        const year = this.filterSettingsForm.year
        const month = (this.filterSettingsForm.month as (Option<number> | undefined))?.value
        if (!year || !month) {
            return
        }
        this.datePeriod = getLastDateOfMonth(year, month)
        await this.setRegionOptions()
        if ('region' in this.filterSettingsForm) {
            // @ts-ignore
            const region = this.filterSettingsForm.region as Option<string> | null
            this.setBudgetTypeFromRegion(region)
        } 
        if ('regions' in this.filterSettingsForm) {
            // @ts-ignore
            const regions: Option<string>[] = this.filterSettingsForm.regions as Option<string>[]
            this.setBudgetTypeFromRegion(regions)
        }
    }

    public async onDateChange() {
        if (
            this.filterSettingsForm == null 
            || this.filterOptions == null 
            || !('regions' in this.filterOptions) 
            || !('region' in this.filterSettingsForm || 'regions' in this.filterSettingsForm)
        ) {
            if (this.filterSettingsForm?.key !== 'INCOME') { // TODO: Why <this.filterSettingsForm?.key !== 'INCOME'>
                return
            }
        }

        let date: Date | null = null
        if ('date' in this.filterSettingsForm) {
            date = this.filterSettingsForm.date 
        } else if ('periodTo' in this.filterSettingsForm) {
            date = this.filterSettingsForm.periodTo
        }

        this.datePeriod = null
        if (!date && this.filterSettingsForm?.key !== 'INCOME') { // TODO: Why <this.filterSettingsForm?.key !== 'INCOME'>
            if ('region' in this.filterSettingsForm) {
                this.filterSettingsForm.region = null
            }
            if ('regions' in this.filterSettingsForm) {
                this.filterSettingsForm.regions = []
            }
            if (this.filterOptions && 'regions' in this.filterOptions) {
                this.filterOptions.regions = []
            }
            return
        }

        const strDate = moment(date).format('YYYY-MM-DD')
        this.datePeriod = strDate
        await this.setRegionOptions()
        if ('region' in this.filterSettingsForm) {
            // @ts-ignore
            const region = this.filterSettingsForm.region as Option<string> | null
            this.setBudgetTypeFromRegion(region)
        } 
        if ('regions' in this.filterSettingsForm) {
            // @ts-ignore
            const regions: Option<string>[] = this.filterSettingsForm.regions as Option<string>[]
            this.setBudgetTypeFromRegion(regions)
        }
    }

    public async onDateInput(date: Date) {
        await this.onDateChange()
    }

    public onClassificationTypeInput(type: Option<ClassificationType> | null) {
        if (this.template.filterSettings.reportName === 'CASH-EXEC') {
            if (!this.filterSettingsForm || !('indicators' in this.filterSettingsForm)) {
                return
            }
            if (type?.value === 'INCOMES') {
                this.filterSettingsForm.indicators = cloneDeep(reportCashExecIncomeIndicators)
            } else if (type?.value === 'EXPENSES') {
                this.filterSettingsForm.indicators = cloneDeep(reportCashExecExpenseIndicators)
            }
            return
        }

        if (this.filterSettingsForm?.key === 'RS') {
            this.filterSettingsForm.certificateTypes = cloneDeep(this.certificateTypeOptions)
            return
        }
    }

    // Поскольу настройка порядка/исключения индикатора находится
    // в дочернем компоненте отрисовки таблиц, а не в самом filters-form,
    // при каждом изменений индикаторов в дочернем компоненте, надо обновлять параметры
    // выгрузки в журнал через этот метод
    public updateIndicators(newIndicators: Indicator[]) {
        if (this.journalParams == null || !('indicators_order' in this.journalParams)) {
            return
        }
        if (!this.filterSettingsForm || !('indicators' in this.filterSettingsForm)) {
            return
        }

        this.filterSettingsForm.indicators = cloneDeep(newIndicators)
        const newOrder = newIndicators.filter(item => item.active).map(item => item.key)
        this.journalParams.indicators_order = newOrder
        this.$emit("set-journal-params", cloneDeep(this.journalParams))
    }

    private async setClassificationOptions() {
        const incomeResponse = await fetch('/api-py/monitoring/reports-constructor/income-options')
        if (!incomeResponse.ok) {
            makeToast(this, 'Не удалось получить данные для выбора доходов', 'Ошибка', 'danger')
            return
        }
        const incomeData = await incomeResponse.json()
        for (const key in incomeData) {
            this.incomeOptions[key] = incomeData[key]
        }

        const expenseResponse = await fetch(`/api-py/monitoring/reports-constructor/expense-options/${this.template.filterSettings.reportName}`)
        if (!expenseResponse.ok) {
            makeToast(this, 'Не удалось получить данные для выбора расходов', 'Ошибка', 'danger')
            return
        }
        const expenseData = await expenseResponse.json()

        for (const key in expenseData) {
            this.expenseOptions[key] = expenseData[key]
        }
    }

    // Промежуточный метод для setRegionOptions
    // поскольку при созданий компонента год/квартал/месяц/дата берутся с props template 
    private async setRegionOptionsOnCreate() {
        if (!('periodicity' in this.template.filterSettings)) {
            return
        }
        const baseForm = this.facade!.getEmptyForm()

        const resetRegionAndDatePeriod = () => {
            this.datePeriod = null
            if (this.filterSettingsForm != null) {
                if ('regions' in this.filterSettingsForm) {
                    this.filterSettingsForm.regions = []
                } else if ('region' in this.filterSettingsForm) {
                    this.filterSettingsForm.region = null
                }
            }
        }

        if ('month' in this.template.filterSettings && this.template.filterSettings.periodicity === 'MONTH') {
            let year: number | null = null
            let month: number | null = null
            if (this.isNewForm) {
                year = (baseForm as any).year
                month = (baseForm as any).month
            } else {
                year = this.template.filterSettings.year
                month = Number(this.template.filterSettings.month)
            }

            if (year && month) {
                this.datePeriod = getLastDateOfMonth(year, month)
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
        else if ('quarter' in this.template.filterSettings && this.template.filterSettings.periodicity === 'QUARTER') {
            let year: number | null = null
            let quarter: number | null = null
            if (this.isNewForm) {
                year = (baseForm as any).year
                quarter = (baseForm as any).quarter?.value
            } else {
                year = this.template.filterSettings.year
                quarter = Number(this.template.filterSettings.quarter)
            }

            if (year && quarter) {
                this.datePeriod = getLastDateOfQuarter(year, quarter)
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
        else if ('date' in this.template.filterSettings && this.template.filterSettings.periodicity === 'DAY') {
            let date: string | null = null

            if (this.isNewForm && (baseForm as any).date) {
                date = moment((baseForm as any).date).format('YYYY-MM-DD')
            } else {
                date = this.template.filterSettings.date
            }

            if (date) {
                this.datePeriod = date
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
        else if ('period' in this.template.filterSettings && this.template.filterSettings.periodicity === 'PERIOD') {
            if (!('periodTo' in baseForm)) {
                return
            }
            
            let date: string | null = null

            const periodTo = baseForm.periodTo
            if (this.isNewForm && periodTo) {
                date = moment(periodTo).format('YYYY-MM-DD')
            } else {
                date = this.template.filterSettings.period.to
            }

            if (date) {
                this.datePeriod = date
                await this.setRegionOptions()
            } else {
                resetRegionAndDatePeriod()
            }
        }
    }

    private async setTemplateRegionOptions() {
        const response = await fetch(`/api-py/monitoring/reports-constructor/template-region-options/${this.oblastKato}`)
        if (!response.ok) {
            throw new Error('Ошибка запроса получения регионов')
        }

        const regionData = await response.json()

        const options: Option<string>[] = regionData.map((it: any) => {
            const option: Option<string> = { value: it.code, label: `${it.code} - ${it.name_ru}` }
            return option
        })
        options.unshift({ value: '000000', label: '000000 - Все' })

        this.templateFormOptions.regionOptions = options
    }

    private editStatusList() {
        if (this.filterSettingsForm?.key === 'INCOME' && this.filterOptions?.key === 'INCOME') {
            if ('statuses' in this.filterSettingsForm) {
                if (this.filterSettingsForm.statuses.length > 0) {
                    this.filterSettingsForm.statuses = this.filterSettingsForm.statuses.filter((x: any) => x.value !== '0')
                } else {
                    const allStatus: any = {
                        value: '0',
                        label: 'Все'
                    }
                    this.filterSettingsForm.statuses = [allStatus];
                }
            }
        }
    }

    private async setReportCategoryOptions() {
        const response = await fetch('/api-py/monitoring/reports-constructor/report-categories')
        if (!response.ok) {
            throw new Error('Ошибка запроса получения категорий отчетов')
        }
        const categoriesData = await response.json()

        const options: Option<string | null>[] = categoriesData.map((it: any) => ({ 
            label: it.name_ru, value: it.code
        }))
        options.push({ ...personalCategoryOption })

        // Админу доступно все, другим доступно только в личных
        const accessibleOptions = options.filter(it => {
            if (this.isAdmin) {
                return true
            }
            const isOnlyPersonal = it.value === null
            return isOnlyPersonal
        })

        this.templateFormOptions.reportCategoryOptions = accessibleOptions
    }

    private async setRegionOptions() {
        if (
            !this.datePeriod
            || this.filterOptions == null 
            || !('regions' in this.filterOptions)
        ) {
            return
        }

        this.regionLoading = true
        try {
            const response = await fetch(`/api-py/monitoring/reports-constructor/regions/${this.userId}/${this.datePeriod}`)
            if (!response.ok) {
                throw new Error('Ошибка загрузки регионов')
            }
            const data = await response.json()

            const regions: Option<string>[] = data.map((it: any) => ({
                value: it.code,
                label: `${it.code} - ${it.name_ru}`
            })).filter((it: Option<string>) => {
                if (this.template.filterSettings?.reportName === 'IRBO'){
                    if (it.value.length !== 6) return false;
                    if (isNaN(Number(it.value))) return false;

                    const lastFour = it.value.slice(2);
                    const lastTwo = it.value.slice(4);

                    return (
                        lastFour === "0000" ||
                        lastFour === "0101" ||
                        lastTwo === "00"
                    );
                } else {
                    return true
                }
            })

            this.filterOptions.regions = regions

            if (this.filterSettingsForm) {
                if ('region' in this.filterSettingsForm) {
                    const regionCode = this.filterSettingsForm.region?.value
                    const option: Option<string> | null = this.filterOptions.regions.find(it => it.value === regionCode) ?? null
                    this.filterSettingsForm.region = option
                } 
                else if ('regions' in this.filterSettingsForm) {
                    const regionCodes = this.filterSettingsForm.regions.map(it => it.value)
                    const options = this.filterOptions.regions.filter(it => regionCodes.includes(it.value))
                    this.filterSettingsForm.regions = options
                }
            }
        } catch (error) {
            makeToast(this, 'Не удалось загрузить регионы', 'Ошибка', 'danger')
        } finally {
            this.regionLoading = false
        }
    }

    private setFilterOptions() {
        this.filterOptions = this.facade!.getFilterOptions()
    }

    private async setFilterSettingsForm() {
        if (this.filterOptions == null) {
            throw new Error('Не загружены данные для фильтров')
        }

        let form: FilterSettingsForm | null = null
        if (this.isNewForm) {
            form = this.facade!.getEmptyForm()
        } else {
            form = this.facade!.settingsToForm(
                cloneDeep(this.template.filterSettings),
                cloneDeep(this.filterOptions)
            )
        }

        form = this.setAsyncDefaults(form, this.filterOptions)

        this.filterSettingsForm = form
    }

    // Задает дефолтные значения для пустых полей, варианты которых подгружаются асинхронно 
    // Для остальных полей дефолт задается в метода фасада .getEmptyForm()
    private setAsyncDefaults(formParam: FilterSettingsForm, options: FilterOptions): FilterSettingsForm {
        const form = cloneDeep(formParam)
        // Insert async defaults here
        return form
    }

    private setTemplateForm() {
        const allRegionCode = '000000'
        if (this.template.id) {

            let tempRegions: Option<string>[] | Option<string> | null = null
            if (this.template.regions.includes(allRegionCode)) {
                const allRegionOption = this.templateFormOptions.regionOptions.find(it => it.value = allRegionCode)!
                tempRegions = allRegionOption
            }
            else {
                const selectedOptions = this.templateFormOptions.regionOptions.filter(it => this.template.regions.includes(it.value))
                tempRegions = selectedOptions
            }

            const form: TemplateForm = {
                id: this.template.id,
                name: this.template.name,
                description: this.template.description,
                category: this.templateFormOptions.reportCategoryOptions.find(it => it.value === this.template.category)!,
                regions: tempRegions,
            }

            this.templateForm = form
        }
    }

    async onConstructReport() {
        this.loading = true
        try {
            await this.constructReport()
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message)
                makeToast(this, error.message, 'Ошибка', 'danger')
            } else {
                console.error(error)
                makeToast(this, 'Не удалось сформировать отчет', 'Ошибка', 'danger')
            }
        } finally {
            this.loading = false
        }
    }

    async constructReport() {
        if (this.filterSettingsForm == null) {
            throw new Error('Не загружена форма для формирования отчета')
        }
        let templateName: string = this.template.name // Base name
        const hasOwnTemplateName = Boolean(this.templateForm.id)
        if (hasOwnTemplateName) {
            templateName = this.templateForm.name
        }
        if ('incomes' in this.filterSettingsForm) {
            this.incomesToPass = cloneDeep(this.filterSettingsForm.incomes)
        }
        if ('expenses' in this.filterSettingsForm) {
            this.expensesToPass = cloneDeep(this.filterSettingsForm.expenses)
        }

        const { tableData, journalParams } = await this.facade!.constructReport(
            cloneDeep(this.filterSettingsForm),
            templateName,
            this.userId
        )
        this.tableData = tableData
        this.journalParams = cloneDeep(journalParams)
        this.$emit("set-journal-params", cloneDeep(journalParams))

        // Мануально затригерить re-render таблицы
        this.tableKey += 1

        makeToast(this, 'Сформировано', 'Сообщение', 'success')
    }

    public async settingsToDefault() {
        this.tableData = null
        this.setFilterSettingsForm()
        await this.setRegionOptionsOnCreate()
    }

    public toggleSaveAsNewModal(value: boolean | null = null) {
        if (typeof value === 'boolean') {
            this.saveAsNewModal = value    
        } else {
            this.saveAsNewModal = !this.saveAsNewModal
        }
    }

    public async onSaveAsNew() {
        if (this.filterSettingsForm == null) {
            return
        }

        if (!this.templateForm.name) {
            makeToast(this, 'Необходимо ввести название', 'Сообщение', 'danger')
            return
        }

        const baseTemplateNames = allReportNamesOptions.map(it => it.value.toLowerCase())
        if (baseTemplateNames.includes(this.templateForm.name.toLowerCase())) {
            makeToast(this, 'Необходимо изменить название', 'Сообщение', 'danger')
            return
        }
        
        let settings: FilterSettings | null = null
        try {
            settings = this.facade!.formToSettings(cloneDeep(this.filterSettingsForm))            
        } catch (error) {
            if (error instanceof Error) {
                console.error(error.message)
                makeToast(this, error.message, 'Ошибка', 'danger')
            } else {
                console.error(error)
                makeToast(this, 'Не удалось сохранить шаблон (ошибка конвератций формы)', 'Ошибка', 'danger')
            }
            return
        }

        const requestBody = await this.convertTemplateFormToRequestBody(
            cloneDeep(this.templateForm),
            cloneDeep(settings),
            this.userId,
            this.oblastKato
        )
        const response = await fetch('/api-py/monitoring/reports-constructor/templates', {
                method: 'POST',
                body: JSON.stringify(requestBody)
            }
        )
        if (!response.ok) {
            makeToast(this, 'Не удалось сохранить шаблон', 'Ошибка', 'danger')
            return
        }
        const addedItem = await response.json()
        this.templateForm.id = addedItem.id
        makeToast(this, 'Сохранено', 'Сообщение', 'success')
        this.toggleSaveAsNewModal(false)
        this.templateName = this.templateForm.name
    }

    private async convertTemplateFormToRequestBody(
        form: TemplateForm,
        filterSettings: FilterSettings,
        userId: string,
        oblastKato: string
    ): Promise<TemplateCreateUpdateRequest> {
        let categoryCode: string | null = null
        if (form.category && form.category.value) {
            categoryCode = String(form.category.value)
        }

        let regions: string[] = []
        if (categoryCode != null) {
            if (form.regions === null) {
                throw new Error('Должен быть указан регион')
            } else if (Array.isArray(form.regions)) {
                regions = form.regions.map(it => String(it.value))
            } else {
                regions = [String(form.regions.value)]
            }
        } else {
            let tempRegion = '000000'

            const isTestOrNsi = oblastKato === '00' || oblastKato === '99'
            if (!isTestOrNsi) {
                tempRegion = this.oblastCode + '0101'
            }

            regions = [tempRegion]
        }

        const requestBody: TemplateCreateUpdateRequest = {
            id: form.id,
            name: form.name,
            description: form.description,
            category: categoryCode,
            regions: regions,
            filter_settings: filterSettings,
            user_id: userId,
            oblast_kato: oblastKato
        }

        return requestBody
    }

    public async onUpdate() {
        if (this.filterSettingsForm == null) {
            return
        }

        const settings: FilterSettings = this.facade!.formToSettings(cloneDeep(this.filterSettingsForm))

        const requestBody = await this.convertTemplateFormToRequestBody(
            cloneDeep(this.templateForm),
            cloneDeep(settings),
            this.userId,
            this.oblastKato
        )
        const response = await fetch('/api-py/monitoring/reports-constructor/templates', {
                method: 'PUT',
                body: JSON.stringify(requestBody)
            }
        )
        if (!response.ok) {
            makeToast(this, 'Не удалось редактировать шаблон', 'Ошибка', 'danger')
            return
        }

        makeToast(this, 'Изменения сохранены', 'Сообщение', 'success')
        this.toggleSaveAsNewModal(false)
    }

    public isClassificatorCheckboxDisabled(classificatorKey: string): boolean {
        return (this.template.filterSettings.reportName === '4-20' && classificatorKey === 'abp')
    }

    public isClassificatorCheckboxHidden(): boolean {
        return ['DKI-R'].includes(this.template.filterSettings.reportName)
    }

    public isClassificatorMultiselectDisabled(classificatorKey: string): boolean {
        if (this.template.filterSettings.reportName === '4-20' && classificatorKey === 'spf') {
            return true
        }
        return false
    }

    public isClassificatorDraggingDisabled(): boolean {
        if (['4-20', 'DKI-R'].includes(this.template.filterSettings.reportName)) {
            return true
        }
        return false
    }

    // #endregion
}
