import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { reportDviExpensesClassificators, reportDviFilterOptions } from "../report-dvi";
import { ReportDviFilterSettingsForm } from "../settings-form/ReportDviFilterSettingsForm";
import { ReportDviFilterSettings } from "../settings/ReportDviFilterSettings";
import { ReportDviTableDataRequest } from "../data-request/ReportDviTableDataRequest";
import { ReportDviJournalRequest } from "../journal/ReportDviJournalRequest";
import { ReportDviRow } from "../table-data/ReportDviRow";

export class ReportDviFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportDviFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== 'DVI') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'DVI') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let measureUnitOption = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnitOption')
        }
        measureUnitOption = { ...measureUnitOption }

        const budgetTypeOptions = cloneDeep(
            options.budgetTypes.filter(it => settings.budgetTypes.includes(it.value))
        )

        let paymentTypeOption = options.paymentTypes.find(it => it.value === settings.paymentType)
        if (!paymentTypeOption) {
            throw new Error('Не найден paymentTypeOption')
        }
        paymentTypeOption = { ...paymentTypeOption }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: ReportDviFilterSettingsForm = {
            // Base:
            key: 'DVI',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            date: new Date(settings.date),
            measureUnit: measureUnitOption,
            budgetTypes: budgetTypeOptions,
            region: regionOption,
            paymentType: paymentTypeOption,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo,
            expenses: cloneDeep(settings.expenses),
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'DVI') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }

        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        const settings: ReportDviFilterSettings = {
            // Base:
            reportName: 'DVI',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            date: strDate,
            budgetTypes: form.budgetTypes.map(it => it.value),
            region: form.region.value,
            paymentType: form.paymentType.value,
            measureUnit: form.measureUnit.value,
            roundUpTo: roundUpTo,
            expenses: cloneDeep(form.expenses)
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportDviFilterOptions)

        const yesterdayMoment = moment().subtract(1, 'days')
        const yesterdayDate = yesterdayMoment.toDate()

        const emptyForm: ReportDviFilterSettingsForm = {
            key: 'DVI',
            // Base fields
            reportName: options.reportNames.find(it => it.value === 'DVI')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'EXPENSES')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            // Custom fields            
            date: yesterdayDate,
            measureUnit: null,
            budgetTypes: [],
            region: null,
            paymentType: options.paymentTypes.find(it => it.value === 'OBLIGATIONS')!,
            roundUp: false,
            roundUpTo: null,
            expenses: cloneDeep(reportDviExpensesClassificators),
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== 'DVI') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        if (form.budgetTypes.length === 0) {
            throw new Error('Необходимо ввести "Бюджет"')
        }
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        const tempExpenses = form.expenses
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))

        const params: ReportDviTableDataRequest = {
            date: strDate,
            budget_types: form.budgetTypes.map(it => it.value),
            region: form.region.value,
            payment_type: form.paymentType.value,
            measure_unit: form.measureUnit.value,
            round_up: roundUpTo,
            expenses: tempExpenses
        }

        const response = await fetch('/api-py/monitoring/reports-constructor/dvi',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseTableData: any[] = await response.json()

        const tableData: ReportDviRow[] = responseTableData.map((it: any, i: number) => {
            const row: ReportDviRow = {
                index: i,
                gr: it.gr ?? null,
                pgr: it.pgr ?? null,
                abp: it.abp ?? null,
                prg: it.prg ?? null,
                ppr: it.ppr ?? null,
                spf: it.spf ?? null,
                name: it.name ?? null,
                plan: it.plan,
                month1: it['1'],
                month2: it['2'],
                month3: it['3'],
                month4: it['4'],
                month5: it['5'],
                month6: it['6'],
                month7: it['7'],
                month8: it['8'],
                month9: it['9'],
                month10: it['10'],
                month11: it['11'],
                month12: it['12'],
                correctedPlan: it.corrected_plan
            }
            return row
        })

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportDviJournalRequest = {
            reportName: "DVI", // Приходится задавать для поддержки generics
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            date: strDate,
            expenses_order: form.expenses.filter(it => it.active).map(it => it.key),
            payment_type: form.paymentType.value,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            round_up: roundUpTo,
            table_data: responseTableData
        }

        const result: TableDataAndJournalParams = {
            tableData: cloneDeep(tableData),
            journalParams: journalParams
        }

        return result
    }
}
