import { cloneDeep } from "lodash";
import { ReportIncomeFilterOptions } from "./options/ReportIncomeFilterOptions";
import { allClassificationTypesOptions, allDataSourcesOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions, allMutuallyRedeemingOptions, allStatusOptions, allBudgetTypesOptions, allMutuallyRedeemingLevelOptions } from "../../common/baseOptions";
import { Indicator } from "../../common/types/Indicator";
import { ReportIncomeFilterSettings } from "./settings/ReportIncomeFilterSettings";
import { Template } from "../../common/types/Template";
import { Option } from "../../common/types/Option";
import { MeasureUnit } from "../../common/types/roots/fields";
import { Classificator } from "../../common/types/Classificator";

export const reportIncomeIndicators: Indicator[] = [
    { key: 'action', active: false },
    { key: 'kat', active: false },
    { key: 'cls', active: false },
    { key: 'pcl', active: false },
    { key: 'spf', active: false },
    { key: 'region_name', active: true },
    { key: 'total_sum', active: true },
    { key: 'period_sum', active: true },
    { key: 'sumrg', active: true },
    { key: 'deviation_period', active: true },
    { key: 'percent_to_period', active: true },
    { key: 'deviation_annual', active: true },
    { key: 'percent_to_year', active: true },
    { key: 'sumrg_past', active: false },
    { key: 'deviation_past', active: false },
    { key: 'growth', active: false },
]

export const allIncomeClassificators: Classificator[] = [
    { key: 'kat', active: true, selected: [1, 2, 3] },
    { key: 'cls', active: true, selected: [] },
    { key: 'pcl', active: true, selected: [] },
    { key: 'spf', active: true, selected: [] }
]

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportIncomeFilterSettings = {
    // Base:
    reportName: 'INCOME',
    reportType: 'REGULATED',
    classificationType: 'INCOMES',
    dataSource: 'IISK',
    periodicity: 'DAY',
    mutuallyRedeeming: 'WITHOUT',
    measureUnit: 'TENGE',
    separateByRegion: false,
    roundUpTo: null,
    // Custom:
    regions: [],
    date: '',
    decodingActualRegionalbudget: false,
    isTree: false,
    indicators: cloneDeep(reportIncomeIndicators),
    incomes: cloneDeep(allIncomeClassificators),
    statuses: ['39'],
    regionsTotal: false,
    prevYear: false,
    budgetTypes: [],
    mutuallyRedeemingLevels: []
}

export const reportIncomeBaseTemplate: Template = {
    id: null,
    name: 'Информация об исполнении доходов бюджета',
    description: null,
    category: '50',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

const measureUnitsOptions: Option<MeasureUnit>[] = [
    { value: 'TENGE', label: 'тенге' },
    { value: 'THOUSAND', label: 'тыс. тенге' },
    { value: 'MILLION', label: 'млн. тенге' }
]

export const reportIncomeFilterOptions: ReportIncomeFilterOptions = {
    // Base:
    key: 'INCOME',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions.filter(it => it.value !== 'MIXED')),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    incomes: cloneDeep(allIncomeClassificators),
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    // Custom:
    regions: [], // Загружается с бэка
    indicators: cloneDeep(reportIncomeIndicators),
    mutuallyRedeeming: cloneDeep(allMutuallyRedeemingOptions),
    measureUnits: cloneDeep(measureUnitsOptions),
    statuses: cloneDeep(allStatusOptions),
    mutuallyRedeemingLevels: cloneDeep(allMutuallyRedeemingLevelOptions)
}
