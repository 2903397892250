



















































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Classificator } from "../../../common/types/Classificator"
import { Indicator } from "../../../common/types/Indicator"
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"
import { ReportDkiRRow } from "../table-data/ReportDkiRRow"

type Column = 'gr' | 'pgr' | 'abp' | 'prg' | 'ppr' | 'spf' | 'name' 
    | 'kasIsp' | 'months'

type RowKey = keyof ReportDkiRRow

export interface TableField {
    key: Column,
    label: string,
    active: boolean,

    income?: string,
    expense?: string
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportDkiR extends Vue {

    @Prop({
        type: Array,
        required: true,
    })
    public tableData!: ReportDkiRRow[]

    @Prop({
        type: Array,
        required: true
    })
    private readonly expenses!: Classificator[]

    public tableFields: TableField[] = []
    public currentIndicators: Indicator[] = []
    public currentPage = 1
    public currentItems: ReportDkiRRow[] = []

    private readonly allIndicators: Column[] = [
        /** Кассовое исполнение, всего */
        'kasIsp', // plan, cash, balance

        /** в том числе по месяцам */
        'months' // month1 - month12
    ]
    public readonly doubleColumns = ['paymentPlan', 'left', 'completionPercentage', 'obligationPlan']

    public columnsNameMap: Record<string, string> = {
        'gr': 'Функциональная группа',
        'pgr': 'Функциональная подгруппа',
        'abp': 'Администратор',
        'prg': 'Программа',
        'ppr': 'Подпрограмма',
        'spf': 'Специфика',
        'name': 'Наименование расходов',
        'kasIsp': 'Кассовое исполнение, всего',
        'months': 'в том числе по месяцам',
        '1': 'январь',
        '2': 'февраль',
        '3': 'март',
        '4': 'апрель',
        '5': 'май',
        '6': 'июнь',
        '7': 'июль',
        '8': 'август',
        '9': 'сентябрь',
        '10': 'октябрь',
        '11': 'ноябрь',
        '12': 'декабрь',
    }

    public months = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12' 
    ]

    private created() {
        this.currentIndicators = this.allIndicators.map(it => {
            const temp: Indicator = {
                key: it,
                active: true
            }
            return temp
        })
        this.constructTableFields()
        this.handlePageChange(1)
    }

    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.tableData.slice(startIndex, endIndex)
    }

    private constructTableFields() {
        const tempTableFields: TableField[] = []

        // Классификаций (Боковик)
        const bokovikColumns: Column[] = ['gr', 'pgr', 'abp', 'prg', 'ppr', 'spf', 'name']
        bokovikColumns.forEach(it => {
            tempTableFields.push({ key: it, label: this.columnsNameMap[it], active: true })
        })

        // Показатели
        this.allIndicators.forEach(indicator => {
            tempTableFields.push({ key: indicator, label: this.columnsNameMap[indicator], active: true })
        })

        this.tableFields = tempTableFields
    }

    public formatDigitsGrouping = formatDigitsGrouping
}
