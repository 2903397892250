export const formatDigitsGrouping = (number: number | string): string => {
    const fullNumberString: string = number + ''

    let decimalIndex = fullNumberString.indexOf(',')
    if (decimalIndex === -1) {
        decimalIndex = fullNumberString.indexOf('.') 
    }
    if (decimalIndex === -1) {
        decimalIndex = fullNumberString.length
    }

    const decimalPart = fullNumberString.substring(decimalIndex, fullNumberString.length)
    const wholePart = fullNumberString.substring(0, decimalIndex)

    const groupedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return groupedWholePart + decimalPart
}

export const rounding = (value: number, decimals: number = 0): number => {
    return Number(value.toFixed(decimals))
}

export const padLeadingZeros = (number: number | string, size: number): string => {
    let s = String(number)
    while (s.length < size) {
        s = '0' + s;
    }
    return s
}
