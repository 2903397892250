import { cloneDeep } from "lodash";
import moment from "moment";
import i18nService from "@/services/i18n";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { reportCashExecFilterOptions } from "../report-cash-exec";
import { ReportCashExecFilterSettingsForm } from "../settings-form/ReportCashExecFilterSettingsForm";
import { ReportCashExecFilterSettings } from "../settings/ReportCashExecFilterSettings";
import { ReportCashExecRow } from "../table-data/ReportCashExecRow";
import { ReportCashExecJournalRequest } from "../journal/ReportCashExecJournalRequest";
import { ReportCashExecTableDataRequest } from "../data-request/ReportCashExecTableDataRequest";
import { Segmentation } from "../../../common/types/roots/fields";

export class ReportCashExecFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportCashExecFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== 'CASH-EXEC') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'CASH-EXEC') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let measureUnitOption = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnitOption')
        }
        measureUnitOption = { ...measureUnitOption }

        let mutuallyRedeemingOption = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeemingOption) {
            throw new Error('Не найден mutuallyRedeemingOption')
        }
        mutuallyRedeemingOption = { ...mutuallyRedeemingOption }

        let segmentationOption = options.segmentations.find(it => it.value === settings.segmentation)
        if (!segmentationOption) {
            throw new Error('Не найден segmentationOption')
        }
        segmentationOption = { ...segmentationOption }

        const budgetTypeOptions = cloneDeep(
            options.budgetTypes.filter(it => settings.budgetTypes.includes(it.value))
        )

        const statusOptions = cloneDeep(
            options.statuses.filter(it => settings.statuses.includes(it.value))
        )

        const mutuallyRedeemingLevelOptions = cloneDeep(
            options.mutuallyRedeemingLevels.filter(it => settings.mutuallyRedeemingLevels.includes(it.value))
        )

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        let byFormOption = options.byForms.find(it => it.value === settings.byForm)
        if (!byFormOption) {
            throw new Error('Не найден byFormOption')
        }
        byFormOption = { ...byFormOption }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: ReportCashExecFilterSettingsForm = {
            // Base:
            key: 'CASH-EXEC',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            date: new Date(settings.date),
            measureUnit: measureUnitOption,
            budgetTypes: budgetTypeOptions,
            statuses: statusOptions,
            isTree: settings.isTree,
            mutuallyRedeeming: mutuallyRedeemingOption,
            mutuallyRedeemingLevels: mutuallyRedeemingLevelOptions,
            segmentation: segmentationOption,
            byForm: byFormOption,
            region: regionOption,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo,
            incomeTotal: settings.incomeTotal,
            incomes: cloneDeep(settings.incomes),
            expenseTotal: settings.expenseTotal,
            expenses: cloneDeep(settings.expenses),
            indicators: cloneDeep(settings.indicators)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'CASH-EXEC') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }

        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        if (!form.mutuallyRedeeming) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }

        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }

        if (!form.segmentation) {
            throw new Error('Необходимо ввести "Разрезность"')
        }

        if (!form.byForm) {
            throw new Error('Необходимо ввести "По форме"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        const settings: ReportCashExecFilterSettings = {
            // Base:
            reportName: 'CASH-EXEC',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            date: strDate,
            budgetTypes: form.budgetTypes.map(it => it.value),
            region: form.region.value,
            statuses: form.statuses.map(it => it.value),
            isTree: form.isTree,
            byForm: form.byForm.value,
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            mutuallyRedeemingLevels: form.mutuallyRedeemingLevels.map(it => it.value),
            segmentation: form.segmentation.value,
            measureUnit: form.measureUnit.value,
            roundUpTo: roundUpTo,
            incomeTotal: form.incomeTotal,
            incomes: cloneDeep(form.incomes),
            expenseTotal: form.expenseTotal,
            expenses: cloneDeep(form.expenses),
            indicators: cloneDeep(form.indicators)
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportCashExecFilterOptions)

        const yesterdayMoment = moment().subtract(1, 'days')
        const yesterdayDate = yesterdayMoment.toDate()

        const emptyForm: ReportCashExecFilterSettingsForm = {
            key: 'CASH-EXEC',
            // Base fields
            reportName: options.reportNames.find(it => it.value === 'CASH-EXEC')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'INCOMES')!,
            dataSource: options.dataSources.find(it => it.value === 'IISK')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            // Custom fields            
            date: yesterdayDate,
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            budgetTypes: [],
            region: null,
            mutuallyRedeeming: null,
            mutuallyRedeemingLevels: options.mutuallyRedeemingLevels,
            statuses: options.statuses.filter(it => it.value === '39'),
            isTree: true,
            byForm: options.byForms.find(it => it.value === '2-19')!,
            segmentation: null,
            roundUp: false,
            roundUpTo: null,
            incomeTotal: false,
            incomes: cloneDeep(options.incomes),
            expenseTotal: false,
            expenses: cloneDeep(options.expenses),
            indicators: cloneDeep(options.indicators)
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== 'CASH-EXEC') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        if (form.budgetTypes.length === 0) {
            throw new Error('Необходимо ввести "Бюджет"')
        }
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.mutuallyRedeeming) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        if (!form.segmentation) {
            throw new Error('Необходимо ввести "Разрезность"')
        }

        const cutnessMap: Record<Segmentation, (1 | 2 | 3 | 4)> = {
            'REGION': 1,
            'KBK': 2,
            'REGION-KBK': 3,
            'KBK-REGION': 4,
        }
        const cutness: (1 | 2 | 3 | 4) | undefined = cutnessMap[form.segmentation.value]
        if (cutness == null) {
            throw new Error('Неподдерживаемый тип разрезности')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        let classifications: string[] = []
        let activeIncomes = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        let activeExpenses = form.expenses
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        let incomeTotal = form.incomeTotal
        let expenseTotal = form.expenseTotal

        const classificationType = form.classificationType.value
        if (classificationType === 'INCOMES') {
            activeExpenses = []
            expenseTotal = false
            classifications = activeIncomes.map(it => it.key)
        } else if (classificationType === 'EXPENSES') {
            activeIncomes = []
            incomeTotal = false
            classifications = activeExpenses.map(it => it.key)
        } else {
            throw new Error('Неподдерживаемый тип классификации')
        }

        const vzkList = form.mutuallyRedeemingLevels.map(it => {
            if (it.value === 'TYPE-3') {
                return 3
            } else if (it.value === 'TYPE-4') {
                return 4
            } else {
                throw new Error('Неподдерживаемый уровень ВЗК')
            }
        })

        if (form.segmentation.value === 'REGION') {
            activeIncomes = []
            activeExpenses = []
        }

        let source: 'integration' | 'loader' = 'integration'
        if (form.dataSource.value === 'IISK') {
            source = 'integration'
        } else if (form.dataSource.value === 'LOADER') {
            source = 'loader'
        } else {
            throw new Error('Неподдерживаемый источник данных')
        }

        const params: ReportCashExecTableDataRequest = {
            user_id: userId,
            incomes: activeIncomes,
            expenses: activeExpenses,
            source: source,
            repdate: strDate,
            region: form.region.value,
            cutness: cutness,
            mutually_redeeming: form.mutuallyRedeeming.value,
            vzkList: vzkList,
            tree_view: form.isTree,
            form: form.byForm.value,
            budget_types: form.budgetTypes.map(it => it.value),
            status_list: form.statuses.map(it => it.value),
            income_total: incomeTotal,
            expense_total: expenseTotal,
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
            locale: i18nService.locale
        }

        let endpoint = '' 
        let indicatorsOrder: string[] = []
        switch (classificationType) {
            case 'INCOMES': {
                endpoint = '2-19'
                indicatorsOrder = incomeIndicatorsOrder
                break
            }
            case 'EXPENSES': {
                endpoint = '4-20';
                indicatorsOrder = expenseIndicatorsOrder
                break;
            }
            default: throw new Error('not supported classificationType')
        }

        const response = await fetch(`/api-py/monitoring/reports-constructor/cash-ececution/${endpoint}`,
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseTableData: any[] = await response.json()
        const firstClsn = classifications[0]
        const lastClsn = classifications[classifications.length - 1]
        const tableData: ReportCashExecRow[] = responseTableData.map((row: any, index) => {
            row.index = index

            if (row.region) {
                if (form.segmentation?.value === 'KBK-REGION') {
                    row[lastClsn] = row.region
                } else {
                    row[firstClsn] = row.region
                }
            }

            if (!form.isTree && row[lastClsn]) {
                if (typeof row.name === 'string' && row.name) {
                    row.name = `${row[lastClsn]} - ${row.name}`
                } else {
                    row.name = row[lastClsn]
                }
            }

            return row
        })

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportCashExecJournalRequest = {
            reportName: "CASH-EXEC",
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            columns_list: classifications,
            indicators_order: indicatorsOrder, // Может быть редактирована во время превью
            cutness: cutness,
            params: params,
            locale: i18nService.locale
        }

        const result: TableDataAndJournalParams = {
            tableData: cloneDeep(tableData),
            journalParams: journalParams
        }

        return result
    }
}

// TODO: replace with in preview
const incomeIndicatorsOrder: string[] = [
    "plan",
    "plan_utch_year",
    "plan_utch_month",
    "plan_corr_year",
    "plan_corr_month",
    "month_sum",
    "sumrg",
    "exec_cash_curr_month",
    "delta_sumrg",
    "remain_year",
    "remain_period",
    "exec_pct_year",
    "exec_pct_period",
    "sumrg_past_year",
    "growth_rate",
    "dev_from_past"
]

const expenseIndicatorsOrder: string[] = [
    'plan_plat',
    'utch_budget_rep_year',
    'utch_budget_rep_month',
    'scor_plan_rep_year',
    'scor_plan_rep_month',
    'month_sum_plat',
    'sumrg',
    'curr_sumrg',
    'delta_sumrg',
    'reaminder_plan_rep_year',
    'remainder_plan_rep_period',
    'execution_rate_year',
    'execution_rate_period',
    'period_sum_obaz',
    'month_sum_obaz',
    'registrob',
    'remainder_obaz_year',
    'remainder_payment_cur_per',
    'execution_obaz_year',
    'execution_rate_obaz_cur_year',
    'sumrg_past',
    'increase_rate',
    'deviation_cur_year',
]
