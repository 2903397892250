


































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReportRSRow } from '../table-data/ReportRsRow';
import { ClassificationType } from '../../../common/types/roots/fields';
import { Classificator } from '../../../common/types/Classificator';
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"

@Component({})
export default class ReportRs extends Vue {
    
    @Prop({
        type: Array,
        required: true,
    })
    public tableData!: ReportRSRow[]

    @Prop({
        type: String,
        required: true
    })
    private readonly classificationType!: Exclude<ClassificationType, 'MIXED'>

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private readonly propClassificators!: Classificator[]
    
    public classificators: string[] = this.propClassificators.filter(it => it.active).map(it => it.key)
    
    private created() {
        this.handlePageChange(1)
    }
    
    public totalRows = this.tableData.length
    public perPage = 200
    
    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * this.perPage
        const endIndex = startIndex + this.perPage
        this.currentItems = this.tableData.slice(startIndex, endIndex)
    }
    
    public currentPage = 1
    public currentItems: ReportRSRow[] = []

    public columnsNameMap: Record<string, string> = {
        'spr': 'Справка',
        'spr_type': 'Вид справки',
        'description': 'Основание',

        'kat': 'КАТ',
        'cls': 'КЛС',
        'pcl': 'ПКЛ',
        'spf': 'СПФ',

        'gr': 'ФГ',
        'pgr': 'ФПГ',
        'abp': 'АБП',
        'prg': 'БП',
        'ppr': 'БПП',

        'total_amount': 'Всего',
        'month1': 'январь',
        'month2': 'февраль',
        'month3': 'март',
        'month4': 'апрель',
        'month5': 'май',
        'month6': 'июнь',
        'month7': 'июль',
        'month8': 'август',
        'month9': 'сентябрь',
        'month10': 'октябрь',
        'month11': 'ноябрь',
        'month12': 'декабрь',
    }
    public formatDigitsGrouping = formatDigitsGrouping

}
