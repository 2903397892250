import { cloneDeep } from "lodash";
import { allDataSourcesOptions, allIncomeClassificators, allMeasureUnitsOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions, allStatusOptions } from "../../common/baseOptions";
import { Classificator } from "../../common/types/Classificator";
import { Template } from "../../common/types/Template";
import { ReportRsFilterSettings } from "./settings/ReportRsFilterSettings";
import { ReportRsFilterOptions } from "./options/ReportRsFilterOptions";
import { Option } from "../../common/types/Option";
import { CertificateLevel, CertificateType, ClassificationType, PaymentType, StatusType } from "../../common/types/roots/fields";

export const reportRsExpensesClassificators: Classificator[] = [
    { key: 'gr',  active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: true, selected: [] },
    { key: 'prg', active: true, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] },
]
export const reportRsIncomesClassificators: Classificator[] = [
    { key: 'kat', active: true, selected: [] },
    { key: 'cls', active: true, selected: [] },
    { key: 'pcl', active: true, selected: [] },
    { key: 'spf', active: true, selected: [] }
]
export const reportRsPaymentTypesOptions: Option<PaymentType>[] = [
    { value: 'OBLIGATIONS', label: 'Обязательства' },
    { value: 'PAYMENTS', label: 'Платежи' }
]
export const reportRsCertificateLevelOptions: Option<CertificateLevel>[] = [
    { value: 'UO', label: 'УО' },
    { value: 'ABP', label: 'АБП' }
]
export const reportRsCertificateTypeOptions: Option<CertificateType>[] = [
    { value: 'INTERNAL', label: 'Внутренняя' },
    { value: 'MOVEMENT', label: 'Передвижка' },
    { value: 'ADJUSTMENT', label: 'Корректировка' },
    { value: 'CLARIFICATION', label: 'Уточнение' },
]
export const reportRsStatusOptions: Option<StatusType>[] = [
    { value: '39', label: '39 - Утверждено в ИС КК' },
    { value: '26', label: '26 - Согласовано' },
    { value: '27', label: '27 - На доработке' },
    { value: '1', label: '1 - Заявка (проект)' },
    { value: '37', label: '37 - На утверждении ТПК' },
    { value: '38', label: '38 - Рассмотрено ТПК. На доработке' },
    { value: '32', label: '32 - Согласовано УО' },
    { value: '31', label: '31 - На согласовании УО' },
    { value: '28', label: '28 - Рассмотрено УО. На доработке АБП' },
]
export const reportRsClassificationTypeOptions: Option<ClassificationType>[] = [
    { value: 'INCOMES', label: 'Доходы' },
    { value: 'EXPENSES', label: 'Расходы' },
]


// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportRsFilterSettings = {
    // Base:
    reportName: 'RS',
    reportType: 'REGULATED',
    classificationType: 'MIXED',
    dataSource: 'LOADER',
    periodicity: 'PERIOD',
    // Custom:
    period: { from: '', to: '' },
    region: '',
    certificateLevel: 'ABP',
    paymentType: 'OBLIGATIONS',
    certificateTypes: [],
    statuses: [],
    incomes: [],
    expenses: [],
    measureUnit: 'MILLION',
    roundUpTo: null
}

export const reportRsBaseTemplate: Template = {
    id: null,
    name: 'Реестр справок о внесении изменений',
    description: null,
    category: '55',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const reportRsFilterOptions: ReportRsFilterOptions = {
    // Base:
    key: 'RS',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(reportRsClassificationTypeOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    regions: [], // Загружается с бэка
    statuses: cloneDeep(reportRsStatusOptions),
    paymentTypes: cloneDeep(reportRsPaymentTypesOptions),
    certificateLevels: cloneDeep(reportRsCertificateLevelOptions),
    certificateTypes: cloneDeep(reportRsCertificateTypeOptions),
    incomes: cloneDeep(reportRsIncomesClassificators),
    expenses: cloneDeep(reportRsExpensesClassificators)
}
