import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { reportDkiPFilterOptions } from "../report-dki-p";
import { ReportDkiPFilterSettingsForm } from "../settings-form/ReporDkiPFilterSettingsForm";
import { ReportDkiPFilterSettings } from "../settings/ReportDkiPFilterSettings";
import { ReportDkiPTableDataRequest } from "../data-request/ReportDkiPTableDataRequest";
import { ReportDkiPJournalRequest } from "../journal/ReportDkiPJournalRequest";
import { ReportDkiPTableData } from "../table-data/ReportDkiPTableData";
import { allIncomeClassificators } from "../../../common/baseOptions";

export class ReportDkiPFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportDkiPFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== 'DKI-P') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'DKI-P') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        let mutuallyRedeemingOption = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeemingOption) {
            throw new Error('Не найден mutuallyRedeemingOption')
        }
        mutuallyRedeemingOption = { ...mutuallyRedeemingOption }

        let measureUnitOption = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnitOption')
        }
        measureUnitOption = { ...measureUnitOption }

        const budgetTypeOptions = cloneDeep(
            options.budgetTypes.filter(it => settings.budgetTypes.includes(it.value))
        )

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: ReportDkiPFilterSettingsForm = {
            // Base:
            key: 'DKI-P',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            region: regionOption,
            date: new Date(settings.date),
            budgetTypes: budgetTypeOptions,
            mutuallyRedeeming: mutuallyRedeemingOption,
            measureUnit: measureUnitOption,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null,
            incomes: cloneDeep(settings.incomes),
            incomeTotal: settings.incomeTotal
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'DKI-P') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (!form.mutuallyRedeeming) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        
        const strDate = moment(form.date).format('YYYY-MM-DD')

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: ReportDkiPFilterSettings = {
            // Base:
            reportName: 'DKI-P',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            region: form.region.value,
            date: strDate,
            budgetTypes: form.budgetTypes.map(it => it.value),
            measureUnit: form.measureUnit.value,
            roundUpTo: roundUpTo,
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            incomes: cloneDeep(form.incomes),
            incomeTotal: form.incomeTotal
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportDkiPFilterOptions)

        const yesterdayMoment = moment().subtract(1, 'days')
        const yesterdayDate = yesterdayMoment.toDate()

        const emptyForm: ReportDkiPFilterSettingsForm = {
            key: 'DKI-P',
            // Base fields
            reportName: options.reportNames.find(it => it.value === 'DKI-P')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'INCOMES')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            // Custom fields            
            region: null,
            date: yesterdayDate,
            budgetTypes: [],
            mutuallyRedeeming: null,
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            roundUp: false,
            roundUpTo: null,
            incomes: cloneDeep(allIncomeClassificators).map(it => {it.active = true; return it}),
            incomeTotal: true
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== 'DKI-P') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }
        
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.mutuallyRedeeming) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        const incomeParams = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))

        const params: ReportDkiPTableDataRequest = {
            report_date: strDate,
            region: form.region.value,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
            mutually_redeeming: form.mutuallyRedeeming.value,
            incomes: incomeParams
        }

        const response = await fetch('/api-py/monitoring/reports-constructor/dki-p',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseData = await response.json()
        const tableData: ReportDkiPTableData = responseData

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportDkiPJournalRequest = {
            reportName: "DKI-P", // Приходится задавать для поддержки generics
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            incomes: incomeParams.map(it => it.key),
            report_date: strDate,
            region: form.region.value,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            income_total: form.incomeTotal,
            items: tableData.items,
            total: tableData.total
        }

        const result: TableDataAndJournalParams = {
            tableData: tableData,
            journalParams: journalParams
        }

        return result
    }
}
