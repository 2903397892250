import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { Option } from "../../../common/types/Option";
import { reportIncomeFilterOptions, reportIncomeIndicators, allIncomeClassificators } from "../report-income";
import { ReportIncomeTableDataRequest } from "../ReportIncomeTableDataRequest";
import { ReportIncomeJournalRequest } from "../journal/ReportIncomeJournalRequest";
import { ReportIncomeFilterSettingsForm } from "../settings-form/ReportIncomeFilterSettingsForm";
import { ReportIncomeFilterSettings } from "../settings/ReportIncomeFilterSettings";
import { BudgetType, MutuallyRedeemingLevel } from "../../../common/types/roots/fields";
import { ReportIncomeResponse } from "../ReportIncomeResponse";
import { ReportIncomeTableData } from "../table-data/table-data-types";

export class ReportIncomeFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportIncomeFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== 'INCOME') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'INCOME') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let mutuallyRedeemingOption = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeemingOption) {
            throw new Error('Не найден mutuallyRedeeming')
        }
        mutuallyRedeemingOption = { ...mutuallyRedeemingOption }

        const mutuallyRedeemingLevelOptions: Option<MutuallyRedeemingLevel>[] = []
        settings.mutuallyRedeemingLevels.forEach(level => {
            const option = options.mutuallyRedeemingLevels.find(it => it.value === level)!
            mutuallyRedeemingLevelOptions.push({...option})
        })

        let measureUnitOption = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnit')
        }
        measureUnitOption = { ...measureUnitOption } 

        const regions: Option<string>[] = []
        settings.regions.forEach(region => {
            const option = options.regions.find(it => it.value === region)!
            regions.push({...option})
        })

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        const statuses = cloneDeep(
            options.statuses.filter(it => settings.statuses.includes(it.value))
        )

        const budgetTypes: Option<BudgetType>[] = []
        settings.budgetTypes.forEach(budget => { // TODO: just filter
            const budgetTypeOption = options.budgetTypes.find(item => item.value === budget)!
            budgetTypes.push({...budgetTypeOption})
        })

        // 2. Формирование
        const form: ReportIncomeFilterSettingsForm = {
            // Base:
            key: 'INCOME',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            mutuallyRedeeming: mutuallyRedeemingOption,
            measureUnit: measureUnitOption,
            separateByRegion: settings.separateByRegion,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null,
            // Custom:
            regions: regions,
            date: new Date(settings.date),
            budgetTypes: budgetTypes,
            decodingActualRegionalBudget: settings.decodingActualRegionalbudget,
            isTree: settings.isTree,
            statuses: statuses,
            regionsTotal: settings.regionsTotal,
            prevYear: settings.prevYear,
            indicators: cloneDeep(settings.indicators),
            incomes: cloneDeep(settings.incomes),
            mutuallyRedeemingLevels: mutuallyRedeemingLevelOptions
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'INCOME') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }
        if (form.mutuallyRedeeming == null) {
            throw new Error('')
        }
        if (form.measureUnit == null) {
            throw new Error('')
        }
        if (form.regions.length === 0) {
            throw new Error('Необходимо выбрать "Регион"')
        }
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }

        if (form.budgetTypes.length === 0) {
            throw new Error('Необходимо выбрать "Бюджет"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }
        
        const strDate = moment(form.date).format('YYYY-MM-DD')

        const settings: ReportIncomeFilterSettings = {
            // Base:
            reportName: 'INCOME',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            measureUnit: form.measureUnit.value,
            separateByRegion: form.separateByRegion,
            roundUpTo: roundUpTo,
            budgetTypes: form.budgetTypes.map(it => it.value),
            // Custom:
            regions: form.regions.map(it => it.value),
            date: strDate,
            indicators: cloneDeep(form.indicators),
            decodingActualRegionalbudget: form.decodingActualRegionalBudget ?? false,
            isTree: form.isTree ?? false,
            regionsTotal: form.regionsTotal ?? false,
            statuses: form.statuses.map(status => status.value),
            prevYear: form.prevYear ?? false,
            incomes: cloneDeep(form.incomes),
            mutuallyRedeemingLevels: form.mutuallyRedeemingLevels?.map(it => it.value) ?? []
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportIncomeFilterOptions)

        const emptyForm: ReportIncomeFilterSettingsForm = {
            key: 'INCOME',
            // Base fields
            reportName: options.reportNames.find(it => it.value === 'INCOME')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'INCOMES')!,
            dataSource: options.dataSources.find(it => it.value === 'IISK')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            mutuallyRedeeming: options.mutuallyRedeeming.find(it => it.value === 'WITHOUT')!,
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            separateByRegion: false,
            roundUp: false,
            roundUpTo: null,
            budgetTypes: [],
            // Custom fields            
            regions: [],
            date: null,
            decodingActualRegionalBudget: false,
            isTree: false,
            regionsTotal: false,
            statuses: [options.statuses.find(it => it.value === '39')!],
            prevYear: false,
            indicators: cloneDeep(reportIncomeIndicators),
            incomes: cloneDeep(allIncomeClassificators),
            mutuallyRedeemingLevels: []
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== 'INCOME') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }
        
        const date = form.date
        if (!date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        
        if ('regions' in form && form.regions.length === 0) {
            throw new Error('Необходимо ввести "Регион"')
        }

        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        if ('regions' in form && form.budgetTypes.length === 0) {
            throw new Error('Необходимо выбрать "Бюджет"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        if (form.decodingActualRegionalBudget == null) {
            throw new Error('Необходимо ввести "Расшифровка собственно областного бюджета"')
        }

        if (form.isTree == null) {
            throw new Error('Необходимо ввести "Древовидная структура"')
        }

        if (form.regionsTotal == null) {
            throw new Error('Необходимо ввести "Итого по городам"')
        }

        const tempIncomes = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))

        const strDate = moment(date).format('YYYY-MM-DD')
        const params: ReportIncomeTableDataRequest = {
            incomes: tempIncomes,
            date: strDate,
            regions: form.regions.map(it => it.value),
            separateByRegion: form.separateByRegion,
            vzk: form.mutuallyRedeeming?.value,
            measureUnit: form.measureUnit.value,
            roundTo: roundUpTo,
            decodingActualRegionalBudget: form.decodingActualRegionalBudget,
            isTree: form.isTree,
            statuses: form.statuses.map(status => status.value),
            regionsTotal: form.regionsTotal,
            prevYear: form.prevYear,
            budget_types: form.budgetTypes.map(it => it.value),
            mutually_redeeming_levels: form.mutuallyRedeemingLevels.map(it => it.value),
            dataSource: form.dataSource.value

        }

        const response = await fetch('/api-py/monitoring/reports-constructor/income',
            {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseData: ReportIncomeResponse = await response.json()
        const tableData: ReportIncomeTableData = responseData

        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportIncomeJournalRequest = {
            reportName: "INCOME",

            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // indicators_order: form.indicators.filter(it => it.active).map(it => it.key),

            regions: form.regions.map(it => it.value),
            report_date: strDate,
            measure_unit: form.measureUnit.value,
            actually_regional: tableData.actually_regional ?? null,
            template1_items: tableData.template1_items ?? null,
            template1_items_total: tableData.template1_items_total ?? null,
            template1_regional_budget_decrypt_items: tableData.template1_regional_budget_decrypt_items ?? null,
            template2_items: tableData.template2_items ?? null,
            template1_items_districts_total: tableData.template1_items_districts_total ?? null,
            template1_items_cities_total: tableData.template1_items_cities_total ?? null,
            template2_items_total: tableData.template2_items_total ?? null,
            template2_items_tree: tableData.template2_items_tree ?? null,
            decodingActualRegionalBudget: form.decodingActualRegionalBudget,
            budget_types: form.budgetTypes.map(it => it.value)
        }

        const result: TableDataAndJournalParams = {
            tableData: cloneDeep(tableData),
            journalParams: journalParams
        }

        return result
    }
}
