





































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Classificator } from "../../../common/types/Classificator"
import { Indicator } from "../../../common/types/Indicator"
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"
import { Report420Row } from "../table-data/Report420Row"

type Column = 'abp' | 'prg' | 'ppr' | 'spf' | 'name' | 'paymentPlan' | 'iiskSumrg' | 'left' | 'completionPercentage' | 'obligationPlan' | 'iiskRegistrob' | 'st11'

export interface TableField {
    key: Column,
    label: string,
    active: boolean,

    income?: string,
    expense?: string
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class Report420 extends Vue {

    @Prop({
        type: Array,
        required: true,
    })
    public tableData!: Report420Row[]

    @Prop({
        type: Array,
        required: true
    })
    private readonly expenses!: Classificator[]

    public currentPage = 1
    public currentItems: Report420Row[] = []
    public tableFields: TableField[] = []
    public currentIndicators: Indicator[] = []

    private readonly allIndicators: Column[] = [
        /** Уточненный план по платежам */
        'paymentPlan', // year, month
        /** Кассовые расходы */
        'iiskSumrg',
        /** Остаток */
        'left', // st4, st5
        /** % исполнения к уточненному плану по платежам */
        'completionPercentage', // st6, st7
        /** Уточненный план по обязательствам */
        'obligationPlan', // obligationYear, obligationMonth
        /** Сумма принятых обязательств */    
        'iiskRegistrob',
        /** Сумма непринятых обязательств */
        'st11'
    ]
    public readonly doubleColumns = ['paymentPlan', 'left', 'completionPercentage', 'obligationPlan']

    public columnsNameMap: Record<Column, string> = {
        'abp': 'Администратор',
        'prg': 'Программа',
        'ppr': 'Подпрограмма',
        'spf': 'Специфика',
        'name': 'Наименование кодов бюджетной классификации',
        'paymentPlan': 'Уточненный план по платежам',
        'iiskSumrg': 'Кассовые расходы',
        'left': 'Остаток',
        'completionPercentage': '% исполнения к уточненному плану по платежам',
        'obligationPlan': 'Уточненный план по обязательствам',
        'iiskRegistrob': 'Сумма принятых обязательств',
        'st11': 'Сумма непринятых обязательств'
    }

    get activeDoubleColumnAmount() {
        return this.tableFields
            .filter(it => it.active)
            .map(it => it.key)
            .filter(it => this.doubleColumns.includes(it))
            .length
    }

    private created() {
        this.currentIndicators = this.allIndicators.map(it => {
            const temp: Indicator = {
                key: it,
                active: true
            }
            return temp
        })
        this.constructTableFields()
        this.handlePageChange(1)
    }

    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.tableData.slice(startIndex, endIndex)
    }

    private constructTableFields() {
        const tempTableFields: TableField[] = []

        // Классификаций (Боковик)
        let bokovikColumns: Column[] = ['abp', 'prg', 'ppr', 'spf', 'name']
        const spfClassificator = this.expenses.find(it => it.key === 'spf')
        if (!Boolean(spfClassificator?.active)) {
            bokovikColumns = ['abp', 'prg', 'ppr', 'name']
        }
        bokovikColumns.forEach(it => {
            tempTableFields.push({ key: it, label: this.columnsNameMap[it], active: true })
        })

        // Показатели
        this.allIndicators.forEach(indicator => {
            tempTableFields.push({ key: indicator, label: this.columnsNameMap[indicator], active: true })
        })

        this.tableFields = tempTableFields
    }

    public format(value: any): any {
        if (value == null) {
            return ''
        }
        if (Number(value).isNaN) {
            return value
        }
        return formatDigitsGrouping(value)
    }
}
