












































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReportCashExecRow } from "../table-data/ReportCashExecRow"
import { Classificator } from "../../../common/types/Classificator"
import { Indicator } from "../../../common/types/Indicator"
import { ClassificationType } from "../../../common/types/roots/fields"
import { formatDigitsGrouping, padLeadingZeros } from "../../../common/utils/formattingUtils"
import { cloneDeep } from "lodash"
import moment from "moment"

type Column = keyof ReportCashExecRow

interface TableField {
    key: Column
    active: boolean
}

interface ParentIndicator {
    key: string
    colspan: number
    rowspan: number
}

const incomeParentIndicators: ParentIndicator[] = [
    { key: 'plan', colspan: 1, rowspan: 2 },
    { key: 'utch_budget', colspan: 2, rowspan: 1 },
    { key: 'scor_plan_rep', colspan: 3, rowspan: 1 },
    { key: 'cash_execution', colspan: 3, rowspan: 1 },
    { key: 'remainder', colspan: 2, rowspan: 1 },
    { key: 'execution_rate', colspan: 2, rowspan: 1 },
    { key: 'on_repdate', colspan: 3, rowspan: 1 },
]

const expenseParentIndicators: ParentIndicator[] = [
    { key: 'plan_plat', colspan: 1, rowspan: 2 },
    { key: 'utch_budget', colspan: 2, rowspan: 1 },
    { key: 'scor_plan_rep', colspan: 3, rowspan: 1 },
    { key: 'cash_execution', colspan: 3, rowspan: 1 },
    { key: 'remainder_payment', colspan: 2, rowspan: 1 },
    { key: 'payment_execution_rate', colspan: 2, rowspan: 1 },
    { key: 'scor_plan_obligations', colspan: 2, rowspan: 1 },
    { key: 'registrob', colspan: 1, rowspan: 2 },
    { key: 'ramainder_obligations', colspan: 2, rowspan: 1 },
    { key: 'obligations_execution_rate', colspan: 2, rowspan: 1 },
    { key: 'on_repdate', colspan: 3, rowspan: 1 },
]

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportCashExec extends Vue {

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    public readonly tableData!: ReportCashExecRow[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private readonly propIndicators!: Indicator[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private readonly incomes!: Classificator[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private readonly expenses!: Classificator[]

    @Prop({
        type: String,
        required: true,
    })
    private readonly classificationType!: ClassificationType

    @Prop({
        type: Date,
        required: true,
        default: new Date(),
    })
    private readonly date!: Date

    @Prop({
        type: Boolean,
        required: true,
        default: false,
    })
    private readonly isTree!: boolean

    public tableFields: TableField[] = []
    public classifications: Classificator[] = []
    public indicators: Indicator[] = []

    public currentPage = 1
    public currentItems: ReportCashExecRow[] = []

    public parentIndicators: ParentIndicator[] = []

    private created() {
        this.indicators = cloneDeep(this.propIndicators)
        this.constructTableFields(true)
        this.handlePageChange(1)
    }

    private constructTableFields(isOnCreate: boolean) {
        // Классификаций
        if (this.isTree) {
            let classifications: Classificator[] = []
            if (this.classificationType === 'INCOMES') {
                classifications = cloneDeep(this.incomes)
            } else if (this.classificationType === 'EXPENSES') {
                classifications = cloneDeep(this.expenses)
            } else {
                throw new Error('not supported classificationType')
            }
            const activeClassifications = classifications.filter(it => it.active)
            this.classifications = activeClassifications
        }

        // Показатели
        const tableFields: TableField[] = []
        const indicators = cloneDeep(isOnCreate ? this.propIndicators : this.indicators)
        indicators.filter(it => it.active).forEach(indicator => {
            tableFields.push({ key: (indicator.key as any), active: true })
        })

        // Родительские колонки
        const activeIndicators = indicators.filter(it => it.active).map(it => it.key)
        if (this.classificationType === 'INCOMES') {
            this.parentIndicators = this.constructIncomeParentIndicators(activeIndicators)
        } else {
            this.parentIndicators = this.constructExpenseParentIndicators(activeIndicators)
        }

        this.tableFields = tableFields
    }

    private constructIncomeParentIndicators(activeIndicators: string[]): ParentIndicator[] {
        const parentIndicators: ParentIndicator[] = []

        // Утвержденный бюджет на отчетный финансовый год
        if (activeIndicators.find(key => key === 'plan')) {
            parentIndicators.push({ key: 'plan', colspan: 1, rowspan: 2 })
        }
        // Уточненный бюджет
        let utchBudgetColspan = 0
        if (activeIndicators.find(key => key === 'plan_utch_year')) {
            utchBudgetColspan++
        }
        if (activeIndicators.find(key => key === 'plan_utch_month')) {
            utchBudgetColspan++
        }
        if (utchBudgetColspan > 0) {
            parentIndicators.push({ key: 'utch_budget', colspan: utchBudgetColspan, rowspan: 1 })
        }
        // Скорректированный бюджет
        let scorPlanRepColspan = 0
        if (activeIndicators.find(key => key === 'plan_corr_year')) {
            scorPlanRepColspan++
        }
        if (activeIndicators.find(key => key === 'plan_corr_month')) {
            scorPlanRepColspan++
        }
        if (activeIndicators.find(key => key === 'month_sum')) {
            scorPlanRepColspan++
        }
        if (scorPlanRepColspan > 0) {
            parentIndicators.push({ key: 'scor_plan_rep', colspan: scorPlanRepColspan, rowspan: 1 })
        }
        // Кассовое исполнение
        let cashExecutionColspan = 0
        if (activeIndicators.find(key => key === 'sumrg')) {
            cashExecutionColspan++
        }
        if (activeIndicators.find(key => key === 'exec_cash_curr_month')) {
            cashExecutionColspan++
        }
        if (activeIndicators.find(key => key === 'delta_sumrg')) {
            cashExecutionColspan++
        }
        if (cashExecutionColspan > 0) {
            parentIndicators.push({ key: 'cash_execution', colspan: cashExecutionColspan, rowspan: 1 })
        }
        // Остаток
        let remainderColspan = 0
        if (activeIndicators.find(key => key === 'remain_year')) {
            remainderColspan++
        }
        if (activeIndicators.find(key => key === 'remain_period')) {
            remainderColspan++
        }
        if (remainderColspan > 0) {
            parentIndicators.push({ key: 'remainder', colspan: remainderColspan, rowspan: 1 })
        }
        // % исполнения
        let executionRateColspan = 0
        if (activeIndicators.find(key => key === 'exec_pct_year')) {
            executionRateColspan++
        }
        if (activeIndicators.find(key => key === 'exec_pct_period')) {
            executionRateColspan++
        }
        if (executionRateColspan > 0) {
            parentIndicators.push({ key: 'execution_rate', colspan: executionRateColspan, rowspan: 1 })
        }
        // На $date
        let onRepdateColspan = 0
        if (activeIndicators.find(key => key === 'sumrg_past_year')) {
            onRepdateColspan++
        }
        if (activeIndicators.find(key => key === 'growth_rate')) {
            onRepdateColspan++
        }
        if (activeIndicators.find(key => key === 'dev_from_past')) {
            onRepdateColspan++
        }
        if (onRepdateColspan > 0) {
            parentIndicators.push({ key: 'on_repdate', colspan: onRepdateColspan, rowspan: 1 })
        }

        return parentIndicators
    }

    private constructExpenseParentIndicators(activeIndicators: string[]): ParentIndicator[] {
        const parentIndicators: ParentIndicator[] = []

        // Утвержденный бюджет на отчетный финансовый год
        if (activeIndicators.find(key => key === 'plan_plat')) {
            parentIndicators.push({ key: 'plan_plat', colspan: 1, rowspan: 2 })
        }
        // Уточненный бюджет
        let utchBudgetColspan = 0
        if (activeIndicators.find(key => key === 'utch_budget_rep_year')) {
            utchBudgetColspan++
        }
        if (activeIndicators.find(key => key === 'utch_budget_rep_month')) {
            utchBudgetColspan++
        }
        if (utchBudgetColspan > 0) {
            parentIndicators.push({ key: 'utch_budget', colspan: utchBudgetColspan, rowspan: 1 })
        }
        // Скорректированный бюджет
        let scorPlanRepColspan = 0
        if (activeIndicators.find(key => key === 'scor_plan_rep_year')) {
            scorPlanRepColspan++
        }
        if (activeIndicators.find(key => key === 'scor_plan_rep_month')) {
            scorPlanRepColspan++
        }
        if (activeIndicators.find(key => key === 'month_sum_plat')) {
            scorPlanRepColspan++
        }
        if (scorPlanRepColspan > 0) {
            parentIndicators.push({ key: 'scor_plan_rep', colspan: scorPlanRepColspan, rowspan: 1 })
        }
        // Кассовое исполнение
        let cashExecutionColspan = 0
        if (activeIndicators.find(key => key === 'sumrg')) {
            cashExecutionColspan++
        }
        if (activeIndicators.find(key => key === 'curr_sumrg')) {
            cashExecutionColspan++
        }
        if (activeIndicators.find(key => key === 'delta_sumrg')) {
            cashExecutionColspan++
        }
        if (cashExecutionColspan > 0) {
            parentIndicators.push({ key: 'cash_execution', colspan: cashExecutionColspan, rowspan: 1 })
        }
        // Остаток по платежам
        let remainderPaymentColspan = 0
        if (activeIndicators.find(key => key === 'reaminder_plan_rep_year')) {
            remainderPaymentColspan++
        }
        if (activeIndicators.find(key => key === 'remainder_plan_rep_period')) {
            remainderPaymentColspan++
        }
        if (remainderPaymentColspan > 0) {
            parentIndicators.push({ key: 'remainder_payment', colspan: remainderPaymentColspan, rowspan: 1 })
        }
        // % исполнения по платежам
        let paymentExecutionRateColspan = 0
        if (activeIndicators.find(key => key === 'execution_rate_year')) {
            paymentExecutionRateColspan++
        }
        if (activeIndicators.find(key => key === 'execution_rate_period')) {
            paymentExecutionRateColspan++
        }
        if (paymentExecutionRateColspan > 0) {
            parentIndicators.push({ key: 'payment_execution_rate', colspan: paymentExecutionRateColspan, rowspan: 1 })
        }
        // Скорректированный план по обязательствам
        let scorPlanObligationsColspan = 0
        if (activeIndicators.find(key => key === 'period_sum_obaz')) {
            scorPlanObligationsColspan++
        }
        if (activeIndicators.find(key => key === 'month_sum_obaz')) {
            scorPlanObligationsColspan++
        }
        if (scorPlanObligationsColspan > 0) {
            parentIndicators.push({ key: 'scor_plan_obligations', colspan: scorPlanObligationsColspan, rowspan: 1 })
        }
        // Принятые обязательства
        let registrobColspan = 0
        if (activeIndicators.find(key => key === 'registrob')) {
            registrobColspan++
        }
        if (registrobColspan > 0) {
            parentIndicators.push({ key: 'registrob', colspan: registrobColspan, rowspan: 2 })
        }
        // Остаток по обязательствам
        let ramainderObligationsColspan = 0
        if (activeIndicators.find(key => key === 'remainder_obaz_year')) {
            ramainderObligationsColspan++
        }
        if (activeIndicators.find(key => key === 'remainder_payment_cur_per')) {
            ramainderObligationsColspan++
        }
        if (ramainderObligationsColspan > 0) {
            parentIndicators.push({ key: 'ramainder_obligations', colspan: ramainderObligationsColspan, rowspan: 1 })
        }
        // % исполнения по обязательствам
        let obligationsExecutionRateColspan = 0
        if (activeIndicators.find(key => key === 'execution_obaz_year')) {
            obligationsExecutionRateColspan++
        }
        if (activeIndicators.find(key => key === 'execution_rate_obaz_cur_year')) {
            obligationsExecutionRateColspan++
        }
        if (obligationsExecutionRateColspan > 0) {
            parentIndicators.push({ key: 'obligations_execution_rate', colspan: obligationsExecutionRateColspan, rowspan: 1 })
        }
        // На $date
        let onRepdateColspan = 0
        if (activeIndicators.find(key => key === 'sumrg_past')) {
            onRepdateColspan++
        }
        if (activeIndicators.find(key => key === 'increase_rate')) {
            onRepdateColspan++
        }
        if (activeIndicators.find(key => key === 'deviation_cur_year')) {
            onRepdateColspan++
        }
        if (onRepdateColspan > 0) {
            parentIndicators.push({ key: 'on_repdate', colspan: onRepdateColspan, rowspan: 1 })
        }

        return parentIndicators
    }

    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.tableData.slice(startIndex, endIndex)
    }

    public tr(key: any): string | null {
        if (typeof key !== 'string') {
            return null
        }

        let text = ''
        let tableTranslationKey = ''
        if (this.classificationType === 'INCOMES') {
            tableTranslationKey = 'modules.budget_execution_monitoring.reports_constructor.cash_execution_report.2_19.table'
        } else if (this.classificationType === 'EXPENSES') {
            tableTranslationKey = 'modules.budget_execution_monitoring.reports_constructor.cash_execution_report.4_20.table'
        }
        text = String(this.$t(`${tableTranslationKey}.${key}`))

        if (!text || text === `${tableTranslationKey}.${key}`) {

            const translationKey = 'modules.budget_execution_monitoring.reports_constructor'
            text = String(this.$t(`${translationKey}.${key}`))

            if (!text || text === `${translationKey}.${key}`) {
                text = key
            }
        }

        if (text.includes('$date')) {
            const date = moment(this.date)
            const days = date.year() % 4 === 0 ? 366 : 365
            const previousYearDate = date.subtract(days, 'days')
            const formattedPreviousYearDate = previousYearDate.format('DD.MM.YYYY')
            text = text.replaceAll("$date", formattedPreviousYearDate)
        }

        return text
    }

    public synchronizeIndicatorsOrder() {
        // const fullIndicatorKeys = this.indicatorKeys.filter(it => ['plgp', 'plgo'].notIncludes(it)).concat(['plgs'])
        // const indicatorsOrder = this.tableFields
        //     .filter(it => fullIndicatorKeys.includes(it.key))
        //     .map(it => it.key)

        //     fullIndicatorKeys.forEach(indicatorKey => {
        //     if (indicatorsOrder.notIncludes(indicatorKey)) {
        //         indicatorsOrder.push(indicatorKey)
        //     }
        // })

        // const tempIndicators: Indicator[] = []
        // indicatorsOrder.forEach(indicatorKey => {
        //     const IndicatorObject = this.indicators.find(it => it.key === indicatorKey)!
        //     tempIndicators.push(IndicatorObject)
        // })

        // this.indicators = tempIndicators
        // this.$emit('update-indicators', tempIndicators)
    }

    public synchronizeVisiblityInTable() {
        this.$emit('update-indicators', cloneDeep(this.indicators))
        this.constructTableFields(false)
    }

    public format(value: any): any {
        if (value == null) {
            return ''
        }
        if (Number(value).isNaN) {
            return value
        }
        return formatDigitsGrouping(value)
    }
    public padLeft = padLeadingZeros
}
