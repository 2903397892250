import { cloneDeep } from "lodash";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { Option } from "../../../common/types/Option";
import { BudgetType } from "../../../common/types/roots/fields";
import { report127Expenses, report127FilterOptions, report127Indicators } from "../report-127";
import { Report127FilterSettingsForm } from "../settings-form/Report127FilterSettingsForm";
import { Report127FilterSettings } from "../settings/Report127FilterSettings";
import { Report127TableDataRequest } from "../other-types/Report127TableDataRequest";
import { getLastDateOfMonth } from "../../../common/utils/dateUtils";
import { allIncomeClassificators } from "../../../common/baseOptions";
import { Report127JournalRequest } from "../journal/Report127JournalRequest";
import { Report127Row, Report127TableRow, SectionSeparator } from "../table-data/table-data-types";

export class Report127Facade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(report127FilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== '1-27') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== '1-27') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        const regions: Option<string>[] = []
        settings.regions.forEach(region => {
            const option = options.regions.find(it => it.value === region)!
            regions.push({...option})
        })

        let mutuallyRedeeming = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeeming) {
            throw new Error('Не найден mutuallyRedeeming')
        }
        mutuallyRedeeming = { ...mutuallyRedeeming }

        const budgetTypes: Option<BudgetType>[] = []
        settings.budgetTypes.forEach(budget => { // TODO: just filter
            const budgetTypeOption = options.budgetTypes.find(item => item.value === budget)!
            budgetTypes.push({...budgetTypeOption})
        })

        let measureUnit = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnit) {
            throw new Error('Не найден measureUnit')
        }
        measureUnit = { ...measureUnit }

        let budgetStructure = options.budgetStructures.find(item => item.value == settings.budgetStructure) ?? null
        if (budgetStructure) {
            budgetStructure = {...budgetStructure}
        }

        let developType = options.developTypes.find(it => it.value === settings.developType)
        if (!developType) {
            throw new Error('Не найден developType')
        }
        developType = { ...developType }

        let month = options.months.find(it => it.value == settings.month) ?? null
        if (month) {
            month = { ...month }
        }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        let expenseType = options.expenseTypes.find(it => it.value == settings.expenseType) ?? null
        if (!expenseType) {
            throw new Error('Не найден expenseType')
        }
        expenseType = { ...expenseType }

        // 2. Формирование
        const form: Report127FilterSettingsForm = {
            key: '1-27',

            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,

            regions: regions,
            mutuallyRedeeming: mutuallyRedeeming,
            budgetTypes: budgetTypes,
            measureUnit: measureUnit,
            budgetStructure: budgetStructure,
            developType: developType,
            year: settings.year,
            month: month,
            separateByRegion: settings.separateByRegion,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null,
            incomeTotal: settings.incomeTotal,
            expenseTotal: settings.expenseTotal,

            incomes: cloneDeep(settings.incomes),
            expenseType: expenseType,
            expenses: cloneDeep(settings.expenses),
            indicators: cloneDeep(settings.indicators)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== '1-27') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (form.budgetStructure == null) {
            throw new Error('Необходимо ввести "Стуктура бюджета"')
        }
        if (form.developType == null) {
            throw new Error('Необходимо ввести "Вид программы"')
        }
        if (form.year == null) {
            throw new Error('Необходимо ввести "Год"')
        }
        if (form.month == null) {
            throw new Error('Необходимо ввести "Месяц"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: Report127FilterSettings = {
            reportName: '1-27',

            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,

            regions: form.regions.map(it => it.value),
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            budgetTypes: form.budgetTypes.map(it => it.value),
            measureUnit: form.measureUnit.value,
            budgetStructure: form.budgetStructure.value,
            developType: form.developType.value ?? null,
            year: form.year,
            month: form.month.value,
            separateByRegion: form.separateByRegion,
            roundUpTo: roundUpTo,
            incomeTotal: form.incomeTotal,
            expenseTotal: form.expenseTotal,
            incomes: cloneDeep(form.incomes),
            expenseType: form.expenseType.value,
            expenses: cloneDeep(form.expenses),
            indicators: cloneDeep(form.indicators)
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(report127FilterOptions)

        const emptyForm: Report127FilterSettingsForm = {
            key: '1-27',

            reportName: options.reportNames.find(it => it.value === '1-27')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'MIXED')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'MONTH')!,

            regions: [],
            mutuallyRedeeming: options.mutuallyRedeeming.find(it => it.value === 'WITH')!,
            budgetTypes: [],
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            budgetStructure: null,
            developType: options.developTypes.find(it => it.value === null)!,
            year: (new Date().getFullYear()),
            month: null,
            separateByRegion: false,
            roundUp: false,
            roundUpTo: null,
            incomeTotal: false,
            expenseTotal: false,
            
            incomes: cloneDeep(allIncomeClassificators),
            expenses: cloneDeep(report127Expenses),
            expenseType: options.expenseTypes.find(it => it.value === 'FUNCTIONAL')!,
            indicators: cloneDeep(report127Indicators)
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций
        if (form.key !== '1-27') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.year || !form.month) {
            throw new Error('Необходимо ввести "Год" и "Месяц"')
        }

        if ('regions' in form && form.regions.length === 0) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        const activeIncomes = form.incomes.filter(it => it.active)
        if (form.mutuallyRedeeming.value !== 'WITH' && activeIncomes.length > 0) {
            const doesRegionAlignWithMutuallyRedeeming = (region: string): boolean => {
                const isOblast = ['0101', '0000'].includes(region.substring(2))
                const isRayon = ['01', '00'].includes(region.substring(4))
                if (!isOblast && !isRayon) {
                    return false
                }
                return true
            }

            form.regions.map(it => it.value).forEach(region => {
                if (!doesRegionAlignWithMutuallyRedeeming(region)) {
                    throw new Error('Для "без/только ВЗК" необходимо выбрать областной/районный регион')
                }
            })
        }

        if (form.developType == null) {
            throw new Error('Необходимо ввести "Вид программы"')
        }
        const developType = form.developType.value


        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        // 2. Формирование данных для таблицы
        const tempIncomes = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        const tempExpenses = form.expenses
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        const params: Report127TableDataRequest = {
            incomes: tempIncomes,
            expenses: tempExpenses,
            expense_type: form.expenseType.value,
            year: form.year,
            month: form.month.value,
            regions: form.regions.map(it => it.value),
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
            mutually_redeeming: form.mutuallyRedeeming.value,
            budget_structure: form.budgetStructure?.value ?? null,
            develop_type: developType,
            income_total: form.incomeTotal,
            expense_total: form.expenseTotal,
            separate_by_region: form.separateByRegion
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/1-27',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseTableData = await response.json()

        let index = 0
        // functions are defined in this scope to use 'index' variable for unique keys
        const mapRow = (item: any): Report127TableRow => {
            const mapped: Report127TableRow = {
                index: index++,

                gr: item.gr ?? null,
                pgr: item.pgr ?? null,
                abp: item.abp ?? null,
                prg: item.prg ?? null,
                ppr: item.ppr ?? null,
                epcl: item.epcl ?? null,
                espf: item.espf ?? null,
            
                kat: item.kat ?? null,
                cls: item.cls ?? null,
                pcl: item.pcl ?? null,
                spf: item.spf ?? null,
            
                nameRu: item.name ?? null,

                utv: item.utv ?? null,
                utch: item.utch ?? null,
                plg: item.plg ?? null,
                plgp: item.plgp ?? null,
                plgo: item.plgo ?? null,
                obz: item.obz ?? null,
                nobz: item.nobz ?? null,
                sumrg: item.sumrg ?? null,
                percentage1: item.percentage1 ?? null,
                percentage2: item.percentage2 ?? null,

                bold: item.bold
            }
            return mapped
        }

        const tempTableData: Report127Row[] = []

        responseTableData.forEach((it: any) => {
            if (it.separator) {
                let name = it.name
                if (name === "V. ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА") {
                    name = "V. ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА \n VI. НЕНЕФТЯНОЙ ДЕФИЦИТ (ПРОФИЦИТ) БЮДЖЕТА \n VII ФИНАНСИРОВАНИЕ ДЕФИЦИТА (ИСПОЛЬЗОВАНИЕ ПРОФИЦИТА) БЮДЖЕТА \n Справочно: Остатки бюджетных средств"
                }

                const separatorType: 'SECTION' | 'REGION' = it.separator

                const separator: SectionSeparator = { name: name, type: separatorType } 
                tempTableData.push(separator)
            }
            const onlySeparator = it.utv === undefined 
            if (!onlySeparator) {
                const mapped = mapRow(it)
                tempTableData.push(mapped)
            }
        })

        // 3. Формирование параметров для выгрузки журнал
        const date = getLastDateOfMonth(form.year, form.month.value)
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: Report127JournalRequest = {
            reportName: "1-27",
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            incomes_order: form.incomes.filter(it => it.active).map(it => it.key),
            expenses_order: form.expenses.filter(it => it.active).map(it => it.key),
            expense_type: form.expenseType.value,
            income_total: form.incomeTotal,
            expense_total: form.expenseTotal,
            indicators_order: form.indicators.filter(it => it.active).map(it => it.key),
            table_data: responseTableData,
            date: date,
            region_labels: form.regions.map(it => it.label),
            mutually_redeeming: form.mutuallyRedeeming.value,
            separate_by_region: form.separateByRegion,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit_label: form.measureUnit.label,
            round_up: form.roundUpTo ?? null,
        }

        const result: TableDataAndJournalParams = {
            tableData: tempTableData,
            journalParams: journalParams
        }

        return result
    }
}
