import { cloneDeep } from "lodash";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { application2FilterOptions, application2Indicators } from "../application-2";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { Option } from "../../../common/types/Option";
import { BudgetType } from "../../../common/types/roots/fields";
import { Application2FilterSettingsForm } from "../settings-form/Application2FilterSettingsForm";
import { Application2FilterSettings } from "../settings/Application2FilterSettings";
import { Application2Response } from "../other-types/Application2Response";
import { allIncomeClassificators } from "../../../common/baseOptions";
import { Application2TableDataRequest } from "../other-types/Application2TableDataRequest";
import { Application2TableData, Application2TableRow } from "../table-data/table-data-types";
import { Appilcation2JournalRequest } from "../journal/Application2JournalRequest";

export class Application2Facade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(application2FilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        if (settings.reportName !== 'PR2') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'PR2') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let region = options.regions.find(it => it.value === settings.region) ?? null
        if (region) {
            region = { ...region }
        }

        let mutuallyRedeeming = options.mutuallyRedeeming.find(it => it.value === settings.mutuallyRedeeming)
        if (!mutuallyRedeeming) {
            throw new Error('Не найден mutuallyRedeeming')
        }
        mutuallyRedeeming = { ...mutuallyRedeeming }

        const budgetTypes: Option<BudgetType>[] = []
        settings.budgetTypes.forEach(budget => {
            const budgetTypeOption = options.budgetTypes.find(item => item.value === budget)!
            budgetTypes.push({...budgetTypeOption})
        })

        let measureUnit = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnit) {
            throw new Error('Не найден measureUnit')
        }
        measureUnit = { ...measureUnit }

        let quarter = options.quarters.find(it => it.value === settings.quarter)
        if (!quarter) {
            throw new Error('Не найден quarter')
        }
        quarter = { ...quarter }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: Application2FilterSettingsForm = {
            key: 'PR2',

            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,

            region: region,
            mutuallyRedeeming: mutuallyRedeeming,
            budgetTypes: budgetTypes,
            measureUnit: measureUnit,
            year: settings.year,
            quarter: quarter,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null,

            incomes: cloneDeep(settings.incomes),
            indicators: cloneDeep(settings.indicators)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'PR2') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }
        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (form.year == null) {
            throw new Error('Необходимо ввести "Год"')
        }
        if (form.quarter == null) {
            throw new Error('Необходимо ввести "Квартал"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: Application2FilterSettings = {
            reportName: 'PR2',

            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,

            region: form.region.value,
            mutuallyRedeeming: form.mutuallyRedeeming.value,
            budgetTypes: form.budgetTypes.map(it => it.value),
            measureUnit: form.measureUnit.value,
            year: form.year,
            quarter: form.quarter.value,
            roundUpTo: roundUpTo,
            incomes: cloneDeep(form.incomes),
            indicators: cloneDeep(form.indicators),
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(application2FilterOptions)

        const emptyForm: Application2FilterSettingsForm = {
            key: 'PR2',

            reportName: options.reportNames.find(it => it.value === 'PR2')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'INCOMES')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'QUARTER')!,

            region: null,
            mutuallyRedeeming: null,
            budgetTypes: [],
            measureUnit: null,
            year: (new Date().getFullYear()),
            quarter: null,
            roundUp: false,
            roundUpTo: null,
            incomes: cloneDeep(allIncomeClassificators),
            indicators: cloneDeep(application2Indicators),
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций
        if (form.key !== 'PR2') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.year || !form.quarter) {
            throw new Error('Необходимо ввести "год" и "квартал"')
        }

        const tempIncomes = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        
        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (form.mutuallyRedeeming == null) {
            throw new Error('Необходимо ввести "Взаимопогашаемые коды"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo
        }

        // 2. Формирование данных для таблицы
        const params: Application2TableDataRequest = {
            incomes: tempIncomes,
            year: form.year,
            quarter: form.quarter.value,
            region: form.region.value,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
            mutually_redeeming: form.mutuallyRedeeming.value
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/application-2',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseTableData = await response.json()

        const tablesData: Application2Response = responseTableData
        const unfulfilledMappedData: Application2TableRow[] = tablesData.unfulfilled.map((item: any, index: number) => {
            const temp: Application2TableRow = {
                index: index + 1,
                kat: item.kat ?? null,
                cls: item.cls ?? null,
                pcl: item.pcl ?? null,
                spf: item.spf ?? null,
                fullcode: item.fullcode ?? null,
                nameRu: item.name_ru,
                plan: item.plan,
                fact: item.fact,
                delta1: item.delta1,
                delta2: item.delta2,
                reasons: null
            }
            return temp
        })
        const overfulfilledMappedData: Application2TableRow[] = tablesData.overfulfilled.map((item: any, index: number) => {
            const temp: Application2TableRow = {
                index: index + 1,
                kat: item.kat ?? null,
                cls: item.cls ?? null,
                pcl: item.pcl ?? null,
                spf: item.spf ?? null,
                fullcode: item.fullcode ?? null,
                nameRu: item.name_ru,
                plan: item.plan,
                fact: item.fact,
                delta1: item.delta1,
                delta2: item.delta2,
                reasons: null
            }
            return temp
        })

        const tempTableData: Application2TableData = {
            overfulfilledTableData: overfulfilledMappedData,
            unfulfilledTableData: unfulfilledMappedData,
            overfulfilledTotal: tablesData.overfulfilled_total,
            unfulfilledTotal: tablesData.unfulfilled_total
        }

        // 3. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: Appilcation2JournalRequest = {
            reportName: "PR2",

            name: templateName,
            filter_settings: settings,
            user_id: userId,
            
            incomes_order: form.incomes.filter(it => it.active).map(it => it.key),
            indicators_order: form.indicators.filter(it => it.active).map(it => it.key),
            unfulfilled_table: unfulfilledMappedData,
            overfulfilled_table: overfulfilledMappedData,
            unfulfilled_total: tablesData.unfulfilled_total,
            overfulfilled_total: tablesData.overfulfilled_total
        }

        const result: TableDataAndJournalParams = {
            tableData: tempTableData,
            journalParams: journalParams
        }

        return result
    }
}
