import { cloneDeep } from "lodash";
import { Report420FilterOptions } from "./options/Report420FilterOptions";
import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allMeasureUnitsOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions";
import { Classificator } from "../../common/types/Classificator";
import { Indicator } from "../../common/types/Indicator";
import { Report420FilterSettings } from "./settings/Report420FilterSettings";
import { Template } from "../../common/types/Template";



export const report420ExpensesClassificators: Classificator[] = [
    { key: 'abp', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] }
]

export const report420Indicators: Indicator[] = []

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: Report420FilterSettings = {
    // Base:
    reportName: '4-20',
    reportType: 'REGULATED',
    classificationType: 'EXPENSES',
    dataSource: 'LOADER',
    periodicity: 'DAY',
    // Custom:
    region: '',
    date: '',
    budgetTypes: [],
    measureUnit: 'THOUSAND',
    roundUpTo: null,
    expenses: cloneDeep(report420ExpensesClassificators),
    indicators: cloneDeep(report420Indicators)
}

export const report420BaseTemplate: Template = {
    id: null,
    name: 'Отчет о принятых обязательствах и кассовом исполнении (по форме 4-20)',
    description: null,
    category: '50',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const report420FilterOptions: Report420FilterOptions = {
    // Base:
    key: '4-20',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    regions: [], // Загружается с бэка
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    expenses: cloneDeep(report420ExpensesClassificators),
    indicators: cloneDeep(report420Indicators),
    measureUnits: cloneDeep(allMeasureUnitsOptions)
}
