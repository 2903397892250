










































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"
import { Report127ActRow } from "../table-data/table-data-types"

type RowKey = keyof Report127ActRow

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class Report127Act extends Vue {

    @Prop({
        type: Array,
        required: true,
    })
    public tableData!: Report127ActRow[]

    @Prop({
        type: Number,
        required: true,
    })
    public year!: number

    @Prop({
        type: Number,
        required: true,
    })
    public month!: number

    @Prop({
        type: Boolean,
        required: true,
    })
    public roundUp!: boolean

    @Prop({
        type: Number
    })
    public roundUpTo!: number | null

    public tableFields: RowKey[] = ['number', 'name', 'department', 'local', 'difference']

    public otherTranslations: Record<string, { nameRu: string, nameKz: string, nameEn: string }> = {}

    public async loadTranslation() {
        const year = 2024
        const month = 1
        const response = await fetch(`/api-py/monitoring/reports-constructor/dictionary/act-1-27/${year}/${month}`)
        if (!response.ok) {
            throw new Error('Не удалось получить переводы')
        }
        
        const translationData = await response.json()

        const translationMap: Record<string, { nameRu: string, nameKz: string, nameEn: string }> = {}

        for (const key in translationData) { if (translationData.hasOwnProperty(key)) {
            translationMap[key] = {
                nameRu: translationData[key].name_ru ?? '',
                nameKz: translationData[key].name_kk ?? '',
                nameEn: translationData[key].name_en ?? '',
            }
        }}

        this.otherTranslations = translationMap
    }

    public tr(key: any): string | null {
        if (typeof key !== 'string') {
            return null
        }

        const translationKey = 'modules.budget_execution_monitoring.reports_constructor.act_1_27'
        let text: string = String(this.$t(`${translationKey}.${key}`))

        if (!text || text === `${translationKey}.${key}`) {
            const dict = this.otherTranslations[key]
            if (dict) {
                switch (this.$i18n.locale) {
                    case 'ru': text = dict.nameRu; break;
                    case 'kk': text = dict.nameKz; break;
                    case 'en': text = dict.nameEn; break;
                    default: break;
                } 
            }
        }

        return text
    }

    private async created() {
        await this.loadTranslation()
    }

    public formatNumber(value: any): any {
        if (typeof value !== 'number') {
            return value
        }

        if (this.roundUp && (this.roundUpTo??-1) >= 0) {
            const formatted = this.$n(value, {
                "style": "decimal",
                "useGrouping": true,
                "minimumFractionDigits": this.roundUpTo!, 
                "maximumFractionDigits": this.roundUpTo!
            })
            return formatted
        }

        const formatted = this.$n(value, {
            "style": "decimal",
            "useGrouping": true
        })

        return formatted
    }

    public formatDigitsGrouping = formatDigitsGrouping
}
