import { cloneDeep } from "lodash";
import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allMeasureUnitsOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions";
import { Classificator } from "../../common/types/Classificator";
import { Template } from "../../common/types/Template";
import { ReportDkiRFilterSettings } from "./settings/ReportDkiRFilterSettings";
import { ReportDkiRFilterOptions } from "./options/ReportDkiRFilterOptions";

export const reportDkiRExpensesClassificators: Classificator[] = [
    { key: 'gr', active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: false, selected: [] },
    { key: 'prg', active: false, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] }
]

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportDkiRFilterSettings = {
    // Base:
    reportName: 'DKI-R',
    reportType: 'REGULATED',
    classificationType: 'EXPENSES',
    dataSource: 'LOADER',
    periodicity: 'DAY',
    // Custom:
    region: '',
    date: '',
    measureUnit: 'THOUSAND',
    roundUpTo: null,
    budgetTypes: [],
    expenses: cloneDeep(reportDkiRExpensesClassificators)
}

export const reportDkiRBaseTemplate: Template = {
    id: null,
    name: 'Динамика кассового исполнения плана финансирования',
    description: null,
    category: '60',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const reportDkiRFilterOptions: ReportDkiRFilterOptions = {
    // Base:
    key: 'DKI-R',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    regions: [], // Загружается с бэка
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    expenses: cloneDeep(reportDkiRExpensesClassificators)
}
