import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { reportRsFilterOptions } from "../report-rs";
import { ReportRsFilterSettingsForm } from "../settings-form/ReportRsFilterSettingsForm";
import { ReportRsFilterSettings } from "../settings/ReportRsFilterSettings";
import { ReportRsTableDataRequest, CertificateTypeRequest } from "../data-request/ReportRsTableDataRequest";
import { ReportRSRow } from "../table-data/ReportRsRow";
import { ReportRsJournalRequest } from "../journal/ReportRsJournalRequest";
import { CertificateType } from "../../../common/types/roots/fields";
import { Option } from "../../../common/types/Option";

export class ReportRsFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportRsFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== 'RS') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'RS') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }
        
        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let measureUnitOption = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnitOption')
        }
        measureUnitOption = { ...measureUnitOption }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        let certificateLevelOption = options.certificateLevels.find(it => it.value === settings.certificateLevel)
        if (!certificateLevelOption) {
            throw new Error('Не найден certificateLevelOption')
        }
        certificateLevelOption = { ...certificateLevelOption }

        const paymentTypeOption = cloneDeep(
            options.paymentTypes.find(it => it.value === settings.paymentType) ?? null
        )

        const certificateTypeOptions = cloneDeep(
            options.certificateTypes.filter(it => settings.certificateTypes.includes(it.value))
        )

        const statusOptions = cloneDeep(
            options.statuses.filter(it => settings.statuses.includes(it.value))
        )

        // 2. Формирование
        const form: ReportRsFilterSettingsForm = {
            // Base:
            key: 'RS',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            periodFrom: new Date(settings.period.from),
            periodTo: new Date(settings.period.to),
            measureUnit: measureUnitOption,
            region: regionOption,
            certificateLevel: certificateLevelOption,
            paymentType: paymentTypeOption,
            certificateTypes: certificateTypeOptions,
            statuses: statusOptions,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo,
            incomes: cloneDeep(settings.incomes),
            expenses: cloneDeep(settings.expenses)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'RS') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.periodFrom || !form.periodTo) {
            throw new Error('Необходимо ввести "Период"')
        }

        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }

        if (!form.certificateLevel) {
            throw new Error('Необходимо ввести "Уровень справок"')
        }

        if (form.certificateTypes.length <= 0) {
            throw new Error('Необходимо ввести "Вид справки"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strPeriodFrom = moment(form.periodFrom).format('YYYY-MM-DD')
        const strPeriodTo = moment(form.periodTo).format('YYYY-MM-DD')

        const settings: ReportRsFilterSettings = {
            // Base:
            reportName: 'RS',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            period: { from: strPeriodFrom, to: strPeriodTo },
            certificateLevel: form.certificateLevel.value,
            certificateTypes: form.certificateTypes.map(it => it.value),
            paymentType: form.paymentType?.value ?? null,
            statuses: form.statuses.map(it => it.value),
            region: form.region.value,
            incomes: cloneDeep(form.incomes),
            expenses: cloneDeep(form.expenses),
            measureUnit: form.measureUnit.value,
            roundUpTo: roundUpTo,
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportRsFilterOptions)

        const todayDate = moment().toDate()
        const firstJan = moment().startOf('year').toDate()

        const classificationType = options.classificationTypes.find(it => it.value === 'INCOMES')!

        let certificateTypes: Option<CertificateType>[] = []
        if (classificationType.value === 'INCOMES') {
            certificateTypes = options.certificateTypes.filter(it => 
                ['MOVEMENT', 'ADJUSTMENT', 'CLARIFICATION'].includes(it.value)
            )
        } else if (classificationType.value === 'EXPENSES') {
            certificateTypes = options.certificateTypes.filter(it => 
                ['INTERNAL', 'MOVEMENT', 'ADJUSTMENT', 'CLARIFICATION'].includes(it.value)
            )
        }

        const emptyForm: ReportRsFilterSettingsForm = {
            key: 'RS',
            // Base fields
            reportName: options.reportNames.find(it => it.value === 'RS')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: classificationType,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'PERIOD')!,
            // Custom fields    
            periodFrom: firstJan,
            periodTo: todayDate,         
            region: null,
            certificateLevel: options.certificateLevels.find(it => it.value === 'UO')!,
            paymentType: options.paymentTypes.find(it => it.value === 'PAYMENTS')!,
            certificateTypes: certificateTypes,
            statuses: options.statuses.filter(it => it.value === '39')!,
            incomes: cloneDeep(options.incomes),
            expenses: cloneDeep(options.expenses),
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            roundUp: false,
            roundUpTo: null,
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== 'RS') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.periodFrom || !form.periodTo) {
            throw new Error('Необходимо ввести "Период"')
        }
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.certificateLevel) {
            throw new Error('Необходимо ввести "Уровень справок"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strPeriodFrom = moment(form.periodFrom).format('YYYY-MM-DD')
        const strPeriodTo = moment(form.periodTo).format('YYYY-MM-DD')

        const classficationType = form.classificationType.value
        if (classficationType === 'MIXED') {
            throw new Error('Неподдерживаемый тип классификации')
        }

        let activeIncomes = form.incomes
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        let activeExpenses = form.expenses
            .filter(item => item.active)
            .map(it => ({ key: it.key, selected: it.selected }))
        
        let classificators: string[] = []
        if (classficationType === 'INCOMES') {
            activeExpenses = []
            classificators = activeIncomes.map(it => it.key)
        } else if (classficationType === 'EXPENSES') {
            activeIncomes = []
            classificators = activeExpenses.map(it => it.key)
        }
        
        let pfType: 1 | 2 | null = null
        switch (form.paymentType?.value) {
            case 'OBLIGATIONS': pfType = 1; break;
            case 'PAYMENTS': pfType = 2; break;
            default: break;
        }

        const certificateTypeIncomeMap: Record<string, CertificateTypeRequest> = {
            'ADJUSTMENT': 'budget_income',
            'MOVEMENT': 'budget_income_movement',
            'CLARIFICATION': 'budget_income_clarification',
        }
        const certificateTypeExpenseMap: Record<string, CertificateTypeRequest> = {
            'INTERNAL': 'individual',
            'MOVEMENT': 'movement',
            'CLARIFICATION': 'budget_clarification',
            'ADJUSTMENT': 'budget_adjustment',
        }

        const certificateTypes: CertificateTypeRequest[] = []
        form.certificateTypes.forEach(it => {
            if (classficationType === 'INCOMES') {
                certificateTypes.push(certificateTypeIncomeMap[it.value])
            } else if (classficationType === 'EXPENSES') {
                certificateTypes.push(certificateTypeExpenseMap[it.value])
            }
        })
        if (certificateTypes.length <= 0) {
            throw new Error('Необходимо ввести "Вид справок"')
        }
        
        const statuses = form.statuses.map(it => Number(it.value))
        if (statuses.length <= 0) {
            throw new Error('Необходимо ввести "Статусы"')
        }

        const params: ReportRsTableDataRequest = {
            bdate: strPeriodFrom,
            edate: strPeriodTo,
            region: form.region.value,
            certificate_level: form.certificateLevel.value,
            classification_type: classficationType,
            pf_type: pfType,
            certificate_types: certificateTypes,
            statuses: statuses,
            incomes: activeIncomes,
            expenses: activeExpenses,
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo,
        }

        const response = await fetch('/api-py/monitoring/reports-constructor/rs',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }

        const responseTableData: { rows: ReportRSRow[] } = await response.json()
        const tableData: ReportRSRow[] = responseTableData.rows

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportRsJournalRequest = {
            reportName: "RS",
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            classification_type: classficationType,
            classificators: classificators,
            rows: tableData, 
        }

        const result: TableDataAndJournalParams = {
            tableData: cloneDeep(tableData),
            journalParams: journalParams
        }

        return result
    }
}
