

















































































import Vue from "vue";
import { cloneDeep } from "lodash"
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { makeToast } from '@/modules/budget/monitoring/reports-constructor/common/utils/otherUtils'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { report127BaseTemplate } from "../../reports/report-1-27/report-127";
import { Template } from "../../common/types/Template";
import { TemplateResponse } from "../../common/types/TemplateResponse";
import { application2BaseTemplate } from "../../reports/application-2/application-2";
import { ReportCategory } from "../../common/types/ReportCategory";
import { report420BaseTemplate } from "../../reports/4-20/report-4-20";
import { reportIncomeBaseTemplate } from "../../reports/report-income/report-income";
import { reportIRBOBaseTemplate } from "../../reports/report-irbo/report-irbo";
import { reportDviBaseTemplate } from "../../reports/dvi/report-dvi";
import { report127ActBaseTemplate } from "../../reports/report-1-27-act/report-127-act";
import { reportDkiRBaseTemplate } from "../../reports/dki-r/report-dki-r";
import { reportDkiPBaseTemplate } from "../../reports/dki-p/report-dki-p";
import { reportEibBaseTemplate } from "../../reports/eib/report-eib";
import { reportCashExecBaseTemplate } from "../../reports/report-cash-exec/report-cash-exec";
import { reportRsBaseTemplate } from "../../reports/rs/report-rs";

@Component({
    name: "report-list"
})
export default class ReportList extends Vue {

    // #region Props
    @Prop({
        type: Function,
        required: true,
    })
    public onSelectReport!: Function;
    // #endregion

    get userId() {
        return this.$store.getters.user_uuid
    }

    // #region Data
    public templates = [] as Template[]
    public reportCategories = [] as ReportCategory[]
    public totalExpand: boolean = false
    private readonly moduleCode = '005.003.007'
    private enviroment: string = ''
    private oblastKato: String = ''
    // #endregion

    // #region Computed
    get isAdmin(): boolean {
        const accessLevel = this.$store.state.user.userModules.find((it: any) => it.modules === this.moduleCode)?.access_level
        return accessLevel == 3
    }
    // #endregion

    public async created() {
        try {
            await this.setEnviroment()
            await this.setOblastKato()
            await this.loadCategories()
            await this.loadTemplates()
        } catch (error) {
            console.log(error)            
        }
    }

    private async setEnviroment() {
        try {
            const katoCode = this.$store.state._instanceCode
            const response = await fetch(`/api/dict/get-budget-obl?obl=${katoCode}`)
            if (!response.ok) {
                throw new Error()
            }
            const serverInfo = await response.json()
            this.enviroment = serverInfo.server
        } catch (error) {
            makeToast(this, 'Ошибка запроса get-budget-obl', 'Ошибка', 'danger')
        }
    }

    private async setOblastKato() {
        const response = await fetch('/api/dict/server-description')
        if (!response.ok) {
            throw new Error('Ошибка загрузки данных о среде')
        }
        const serverDescription = await response.text()

        if (serverDescription === 'release' || this.$store.state._instanceCode === '') {
            this.oblastKato = ''
        } else {
            this.oblastKato = this.$store.state._instanceCode
        }
    }

    public toggleAllCategoryVisibility(visibility: boolean) {
        this.totalExpand = visibility
        this.reportCategories.forEach(it => it.isExpanded = visibility)
    }

    private async loadCategories() {
        const response = await fetch('/api-py/monitoring/reports-constructor/report-categories')
        if (!response.ok) {
            throw new Error('Ошибка запроса получения категорий отчетов')
        }
        const categoriesData = await response.json()

        const reportCategories: ReportCategory[] = categoriesData.map((it: any) => {
            const temp: ReportCategory = {
                id: it.id,
                code: it.code,
                nameRu: it.name_ru,
                nameKz: it.name_kz,
                isExpanded: false
            }
            return temp
        })
        const personalCategory: ReportCategory = {
            id: 0,
            code: null,
            nameRu: 'Личные шаблоны',
            nameKz: 'Личные шаблоны',
            isExpanded: false
        }
        reportCategories.push(personalCategory)

        this.reportCategories = reportCategories
    }

    private async loadTemplates() {
        try {
            const tempTemplates: Template[] = [
                // Исходные шаблоны
                cloneDeep(report127BaseTemplate),
                cloneDeep(application2BaseTemplate),
                cloneDeep(report420BaseTemplate),
                cloneDeep(reportIncomeBaseTemplate),
                cloneDeep(reportIRBOBaseTemplate),
                cloneDeep(reportDviBaseTemplate),
                cloneDeep(report127ActBaseTemplate),
                cloneDeep(reportDkiRBaseTemplate),
                cloneDeep(reportDkiPBaseTemplate),
                cloneDeep(reportEibBaseTemplate),
                cloneDeep(reportCashExecBaseTemplate),
                cloneDeep(reportRsBaseTemplate),
            ]

            // Пользовательские шаблоны
            let katoCode = this.oblastKato
            if (!katoCode) {
                // '00' - Загружать все области, если НСИ или тестовая среда
                katoCode = '00'
            }
            const response = await fetch(`/api-py/monitoring/reports-constructor/templates/${this.userId}/${katoCode}`)
            if (!response.ok) {
                throw new Error('Ошибка запроса получения шаблонов')
            }
            const sharedAndPersonalTemplatesResponse = (await response.json()) as TemplateResponse[]

            sharedAndPersonalTemplatesResponse.forEach(it => {
                const mapped: Template = {
                    id: it.id,
                    name: it.name,
                    description: it.description,
                    category: it.category,
                    regions: it.regions,
                    filterSettings: it.filter_settings,
                    userId: it.user_id
                }
                tempTemplates.push(mapped)
            })

            this.templates = tempTemplates
        } catch (error) {
            makeToast(this, 'Не удалось загрузить личные шаблоны', 'Ошибка', 'danger')
        }
    }

    public async deleteTemplate(id: number, userId: string) {
        if (this.userId !== userId) {
            makeToast(this, 'Отсутсвует доступ для удаления', 'Сообщение', 'danger')
            return
        }

        let katoCode = this.oblastKato
        if (!katoCode) {
            katoCode = '00'
        }

        const response = await fetch(`/api-py/monitoring/reports-constructor/templates/${katoCode}/${id}`, {
            method: 'DELETE'
        })
        if (!response.ok) {
            makeToast(this, 'Не удалось удалить шаблон', 'Ошибка', 'danger')
            return
        }
        makeToast(this, 'Шаблон удален', 'Сообщение', 'success')

        await this.loadTemplates()
    }

    public readonly nameMap = nameMap
}
