import { cloneDeep } from "lodash";
import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allIncomeClassificators, allMeasureUnitsOptions, allMutuallyRedeemingOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions";
import { Template } from "../../common/types/Template";
import { ReportDkiPFilterSettings } from "./settings/ReportDkiPFilterSettings";
import { ReportDkiPFilterOptions } from "./options/ReportDkiPFilterOptions";

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportDkiPFilterSettings = {
    // Base:
    reportName: 'DKI-P',
    reportType: 'REGULATED',
    classificationType: 'INCOMES',
    dataSource: 'LOADER',
    periodicity: 'DAY',
    // Custom:
    region: '',
    date: '',
    budgetTypes: [],
    measureUnit: 'MILLION',
    roundUpTo: null,
    mutuallyRedeeming: 'ONLY',
    incomes: [],
    incomeTotal: true,
}

export const reportDkiPBaseTemplate: Template = {
    id: null,
    name: 'Динамика кассового исполнения по областному бюджету (поступления)',
    description: null,
    category: '60',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const reportDkiPFilterOptions: ReportDkiPFilterOptions = {
    // Base:
    key: 'DKI-P',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    regions: [], // Загружается с бэка
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    incomes: cloneDeep(allIncomeClassificators),
    mutuallyRedeeming: cloneDeep(allMutuallyRedeemingOptions),
    measureUnits: cloneDeep(allMeasureUnitsOptions)
}
