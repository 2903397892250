




































import { Vue, Component } from 'vue-property-decorator'
import ReportList from "@/modules/budget/monitoring/reports-constructor/tabs/constructor/report-list.vue";
import FiltersForm from "@/modules/budget/monitoring/reports-constructor/tabs/constructor/filters-form2.vue";
import { Template } from '../../common/types/Template';
import { makeToast } from '../../common/utils/otherUtils';
import { cloneDeep } from 'lodash'
import { LoadToJournalRequest } from '../../common/types/roots/LoadToJournalRequest';
import { ReportName } from '../../common/types/roots/fields';

@Component({
    components: {
        'report-list': ReportList,
        'filters-form': FiltersForm
    }
})
export default class ReportsConstructor extends Vue {

    public journalParams: LoadToJournalRequest | null = null;

    public selectedTemplate = null as Template | null

    public onSelectTemplate(template: Template | null) {
        this.selectedTemplate = template
    }

    setJournalParams(params: LoadToJournalRequest | null) {
        this.journalParams = params
    }

    public async sendToJournal() {
        if (this.journalParams == null) {
            makeToast(this, 'Для выгрузки необходимо сформировать отчет', 'Сообщение', '')
            return
        }

        makeToast(this, 'Началась выгрузка. Для скачивания отчета перейдите во вкладку "Журнал".', 'Внимание!', 'info')
        try {
            if (['4-20', 'DKI-R'].includes(this.journalParams.reportName)) { // Kotlin
                const requestBody = cloneDeep(this.journalParams)
                const response = await fetch(`/api/budget-execution-report-constructor/journal/${requestBody.reportName}`, {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: { 'Content-Type': 'application/json' }
                })
                if (!response.ok) {
                    throw new Error('')
                }
            } else { // Python
                const requestBody = cloneDeep(this.journalParams)
                const endpoint = this.getEndpoint(requestBody.reportName)
                const response = await fetch(`/api-py/monitoring/reports-constructor/journal/${endpoint}`, {
                    method: 'POST',
                    body: JSON.stringify(requestBody)
                })
                if (!response.ok) {
                    throw new Error('')
                }
            }

            makeToast(this, 'Сохранено в журнал', 'Сообщение', 'success')
        } catch (error) {
            makeToast(this, 'Не удалось сохранить в журнал', 'Ошибка', 'danger')
        }

        this.$emit('update-journal')
    }

    private getEndpoint(reportName: ReportName): string {
        if (reportName === '1-27-ACT') {
            return 'act-1-27'
        }
        if (reportName === 'CASH-EXEC') {
            if (this.journalParams?.filter_settings.classificationType === 'INCOMES') {
                return 'cash-ececution/2-19'
            } else {
                return 'cash-ececution/4-20'
            }
        }
        return reportName
    }
}
