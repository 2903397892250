import { Classificator } from "./types/Classificator"
import { BudgetStructure, BudgetType, ClassificationType, DataSource, DevelopType, ExpenseType, MeasureUnit, Month, MutuallyRedeeming, MutuallyRedeemingLevel, Periodicity, Quarter, ReportName, ReportType, Segmentation, StatusType } from "./types/roots/fields"
import { Option } from "./types/Option"

export const allReportNamesOptions: Option<ReportName>[] = [
    { value: '1-27', label: 'Отчет 1-27' },
    { value: '4-20', label: 'Отчет о принятых обязательствах и кассовом исполнении (по форме 4-20)' },
    { value: 'PR2', label: 'Анализ причин по поступлениям' },
    { value: 'IRBO', label: 'Информация об исполнении расходов бюджета' },
    { value: 'DVI', label: 'Динамика внесенных изменений в планы финансирования годовые изменения' },
    { value: '1-27-ACT', label: 'Акт сверки' },
    { value: 'DKI-R', label: 'Динамика кассового исполнения плана финансирования' },
    { value: 'DKI-P', label: 'Динамика кассового исполнения по областному бюджету (поступления)' },
    { value: 'INCOME', label: 'Информация об исполнении доходов' },
    { value: 'EIB', label: 'Ежедневное исполнение бюджета (по всем уровням бюджета)' },
    { value: 'CASH-EXEC', label: 'Отчет о кассовом исполнении' },
    { value: 'RS', label: 'Реестр справок о внесении изменений' },
]
export const allReportTypesOptions: Option<ReportType>[] = [
    { value: 'REGULATED', label: 'Регламентированный' },
    { value: 'UNREGULATED', label: 'Нерегламентированный' }
]
export const allClassificationTypesOptions: Option<ClassificationType>[] = [
    { value: 'INCOMES', label: 'Доходы' },
    { value: 'EXPENSES', label: 'Расходы' },
    { value: 'MIXED', label: 'Смешанная' },
]
export const allDataSourcesOptions: Option<DataSource>[] = [
    { value: 'IISK', label: 'ИИСК' },
    { value: 'LOADER', label: 'Загрузчик' },
    { value: 'MIXED', label: 'Смешанный' }
]
export const allMutuallyRedeemingOptions: Option<MutuallyRedeeming>[] = [
    { value: 'WITH', label: 'c ВЗК' },
    { value: 'ONLY', label: 'только ВЗК' },
    { value: 'WITHOUT', label: 'без ВЗК' }
]
export const allMutuallyRedeemingLevelOptions: Option<MutuallyRedeemingLevel>[] = [
    { value: 'TYPE-3', label: 'Тип 3 (Облать-Район)' },
    { value: 'TYPE-4', label: 'Тип 4 (Район-МСУ)' }
]
export const allPeriodicitiesOptions: Option<Periodicity>[] = [
    { value: 'YEAR', label: 'Годовой' },
    { value: 'QUARTER', label: 'Квартальный' },
    { value: 'MONTH', label: 'Месячный' },
    { value: 'DAY', label: 'Дневной' },
    { value: 'PERIOD', label: 'Период' },
]
export const allBudgetTypesOptions: Option<BudgetType>[] = [
    { value: 2, label: 'Областной' },
    { value: 3, label: 'Районный (городской)' },
    { value: 6, label: 'МСУ' }
]
export const allMeasureUnitsOptions: Option<MeasureUnit>[] = [
    { value: 'TENGE', label: 'тенге' },
    { value: 'THOUSAND', label: 'тыс. тенге' },
    { value: 'MILLION', label: 'млн. тенге' }
]
export const allBudgetStructuresOptions: Option<BudgetStructure>[] = [
    { value: 'WITH', label: 'По структуре' },
    { value: 'WITHOUT', label: 'Без структуры' }
]
export const allDevelopTypesOptions: Option<DevelopType>[] = [
    { label: 'Все', value: null }, 
    { label: 'Текущие', value: 0 }, 
    { label: 'Развития', value: 1 }
]
export const allQuartersOptions: Option<Quarter>[] = [
    { value: 1, label: "I Квартал" },
    { value: 2, label: "II Квартал" },
    { value: 3, label: "III Квартал" },
    { value: 4, label: "IV Квартал" }
]
export const allMonthsOptions: Option<Month>[] = [
    { value: 1, label: "Январь" },
    { value: 2, label: "Февраль" },
    { value: 3, label: "Март" },
    { value: 4, label: "Апрель" },
    { value: 5, label: "Май" },
    { value: 6, label: "Июнь" },
    { value: 7, label: "Июль" },
    { value: 8, label: "Август" },
    { value: 9, label: "Сентябрь" },
    { value: 10, label: "Октябрь" },
    { value: 11, label: "Ноябрь" },
    { value: 12, label: "Декабрь" }
]
export const allIncomeClassificators: Classificator[] = [
    { key: 'kat', active: false, selected: [] },
    { key: 'cls', active: false, selected: [] },
    { key: 'pcl', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] }
]
export const allExpenseTypesOptions: Option<ExpenseType>[] = [
    { value: 'FUNCTIONAL', label: "Функциональная" },
    { value: 'ECONOMICAL', label: "Экономическая" }
]
export const allExpensesClassificators: Classificator[] = [
    { key: 'gr',  active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: false, selected: [] },
    { key: 'gu', active: false, selected: [] },
    { key: 'prg', active: false, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] },
]
export const allStatusOptions: Option<StatusType>[] = [
    { value: '39', label: "39 - Утверждено в ИС КК" },
    { value: '26', label: "26 - Согласовано УО" },
    { value: '0', label: "Все" }
]
export const allSegmentationOptions: Option<Segmentation>[] = [
    { value: 'REGION', label: "Регион" },
    { value: 'KBK', label: "КБК" },
    { value: 'REGION-KBK', label: "Регион-КБК" },
    { value: 'KBK-REGION', label: "КБК-Регион" },
]
