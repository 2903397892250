


























































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import moment from 'moment'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { nameMap } from '@/modules/budget/monitoring/reports-constructor/common/nameMap'
import { cloneDeep } from "lodash"
import { Classificator } from "../../../common/types/Classificator"
import { ReportEibRow } from "../table-data/ReportEibRow"
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"

export interface TableField {
    key: string,
    label: string,
    active: boolean,

    income?: string,
    expense?: string,
    classification?: string
}

type RowKey = keyof ReportEibRow

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportEib extends Vue {

    // region Props
    @Prop({
        type: Array,
        required: true,
    })
    public readonly tableData!: ReportEibRow[]

    @Prop({
        type: Date,
        required: true,
        default: new Date(),
    })
    private readonly date!: Date

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private readonly incomes!: Classificator[]

    @Prop({
        type: Array,
        required: true,
        default: [],
    })
    private readonly expenses!: Classificator[]
    // endregion

    // region Data
    public indicators: string[] = ['name', 'plan', 'execution', 'remaining', 'completion', 'dayCompletions', 'monthCompletion']
    public indicatorsNameMap: Record<string, string> = { // TODO: replace with $t
        'name': 'Наименование',
        'plan': 'Скорректированный план',
        'execution': 'Кассовое исполнение', 
        'remaining': 'Остаток', 
        'completion': '% исполнения', 
        'dayCompletions': 'Исполнение по дням',
        'monthCompletion': 'На отчетный месяц'
    }
    public subColumnKeys: string[] = []
    public dayNumber = 0
    public classificationColumns: TableField[] = []
    public currentPage = 1
    public currentItems: ReportEibRow[] = []
    public rowKeys: RowKey[] = [
        'name',
        'yearPlan',
        'upToMonthPlan',
        'monthPlan',
        'upToMonthExecution',
        'dayExecution',
        'yearRemaining',
        'periodRemining',
        'yearCompletionPercentage',
        'periodCompletionPercentage',
        'dayCompletions',
        'monthCompletion'
    ]
    private monthNameMap: Record<number, string> = {
        1: 'янв',
        2: 'фев',
        3: 'мар',
        4: 'апр',
        5: 'мая',
        6: 'июн',
        7: 'июл',
        8: 'авг',
        9: 'сен',
        10: 'окт',
        11: 'ноя',
        12: 'дек'
    }
    // endregion

    // region Computed
    get categoriesSpan(): number {
        const activeIncomes = this.incomes.filter(item => item.active)
        const activeExpenses = this.expenses.filter(item => item.active)
        return activeExpenses.length > activeIncomes.length ? activeExpenses.length : activeIncomes.length
    }
    // endregion

    // region LifeCycle
    private created() {
        this.constructTableFields()
        this.setSubcolumns()
        this.handlePageChange(1)
    }
    // endregion

    // region Methods
    public handlePageChange(page: number) {
        const indexedPage = page - 1
        this.currentPage = page
        const startIndex = indexedPage * 100
        const endIndex = startIndex + 100
        this.currentItems = this.tableData.slice(startIndex, endIndex)
    }

    private constructTableFields() {
        const activeIncomes = cloneDeep(this.incomes.filter(item => item.active))
        const activeExpenses = cloneDeep(this.expenses.filter(item => item.active))

        activeExpenses.forEach(it => {
            if (it.key === 'spf') {
                it.key = 'espf'
            }
        })
        
        const categoriesSpan = this.categoriesSpan === 0 ? 1 : this.categoriesSpan
        
        const classificationColumns: TableField[] = []
        for (let i = 0; i < categoriesSpan; i++) {
            const incomeKey = activeIncomes[i]?.key
            const expenseKey = activeExpenses[i]?.key
            // @ts-ignore
            const incomeLabel = nameMap[incomeKey]
            // @ts-ignore
            const expenseLabel = nameMap[expenseKey]

            const column: TableField = {
                key: `${incomeKey}/${expenseKey}`,
                label: `${incomeLabel??' - '}/${expenseLabel??' - '}`,
                income: incomeKey,
                expense: expenseKey,
                classification: incomeKey ?? expenseKey,
                active: true
            }
            classificationColumns.push(column)
        }

        this.classificationColumns = classificationColumns
    }

    private setSubcolumns() {
        const subcols = ['на год', 'на отчетный месяц', 'за отчетный месяц', 'на отчетный месяц', 'на день', 'на год', 'на отчетный период', 'на год', 'на отчетный период']
        const mDate = moment(this.date).subtract(1, 'days')
        const currentDay = mDate.date()
        const monthShortName = this.monthNameMap[mDate.month() + 1]
        for (let i = 0; i < currentDay; i++) {
            const dayNumber = (i + 1).toString().padStart(2, '0')
            subcols.push(`${dayNumber}.${monthShortName}`)
        }

        this.dayNumber = currentDay
        this.subColumnKeys = subcols
    }

    public isTotalRow(row: ReportEibRow): boolean {
        return (
            row.kat == null
            && row.cls == null
            && row.pcl == null
            && row.spf == null

            && row.gr == null
            && row.pgr == null
            && row.abp == null
            && row.prg == null
            && row.ppr == null
            && row.espf == null
        )
    }

    public formatNumber = formatDigitsGrouping
    // endregion
}
