import { render, staticRenderFns } from "./report-1-27-act.vue?vue&type=template&id=2372ae16&scoped=true&"
import script from "./report-1-27-act.vue?vue&type=script&lang=ts&"
export * from "./report-1-27-act.vue?vue&type=script&lang=ts&"
import style0 from "./report-1-27-act.vue?vue&type=style&index=0&id=2372ae16&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2372ae16",
  null
  
)

export default component.exports