import { cloneDeep } from "lodash";
import moment from "moment";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { reportDkiRExpensesClassificators, reportDkiRFilterOptions } from "../report-dki-r";
import { ReportDkiRFilterSettingsForm } from "../settings-form/ReporDkiRFilterSettingsForm";
import { ReportDkiRFilterSettings } from "../settings/ReportDkiRFilterSettings";
import { ReportDkiRTableDataRequest } from "../data-request/ReportDkiRTableDataRequest";
import { ReportDkiRJournalRequest } from "../journal/ReportDkiRJournalRequest";
import { ReportDkiRRow } from "../table-data/ReportDkiRRow";
import { rounding } from "../../../common/utils/formattingUtils";

export class ReportDkiRFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(reportDkiRFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== 'DKI-R') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== 'DKI-R') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        const budgetTypeOptions = cloneDeep(
            options.budgetTypes.filter(it => settings.budgetTypes.includes(it.value))
        )

        let measureUnitOption  = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnitOption) {
            throw new Error('Не найден measureUnitOption')
        }
        measureUnitOption = { ...measureUnitOption }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: ReportDkiRFilterSettingsForm = {
            // Base:
            key: 'DKI-R',
            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,
            // Custom:
            region: regionOption,
            date: new Date(settings.date),
            budgetTypes: budgetTypeOptions,
            measureUnit: measureUnitOption,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo,
            expenses: cloneDeep(settings.expenses)
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== 'DKI-R') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (!form.date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        
        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const strDate = moment(form.date).format('YYYY-MM-DD')

        const settings: ReportDkiRFilterSettings = {
            // Base:
            reportName: 'DKI-R',
            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,
            // Custom:
            region: form.region.value,
            date: strDate,
            budgetTypes: form.budgetTypes.map(it => it.value),
            measureUnit: form.measureUnit.value,
            roundUpTo: roundUpTo,
            expenses: cloneDeep(form.expenses)
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(reportDkiRFilterOptions)

        const emptyForm: ReportDkiRFilterSettingsForm = {
            key: 'DKI-R',
            // Base fields
            reportName: options.reportNames.find(it => it.value === 'DKI-R')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'EXPENSES')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'DAY')!,
            // Custom fields            
            region: null,
            date: null,
            budgetTypes: [],
            measureUnit: null,
            roundUp: false,
            roundUpTo: null,
            expenses: cloneDeep(reportDkiRExpensesClassificators),
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций и Формирование данных для таблицы
        if (form.key !== 'DKI-R') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }
        
        const date = form.date
        if (!date) {
            throw new Error('Необходимо ввести "Дата"')
        }
        
        const regionOption = form.region
        if (!regionOption) {
            throw new Error('Необходимо ввести "Регион"')
        }
        const region = regionOption.value

        const strDate = moment(date).format('YYYY-MM-DD')

        const grs = (form.expenses.find(it => it.key === 'gr')?.selected ?? []).map(it => String(it))
        const pgrs = (form.expenses.find(it => it.key === 'pgr')?.selected ?? []).map(it => String(it))
        const abps = (form.expenses.find(it => it.key === 'abp')?.selected ?? []).map(it => String(it))
        const prgs = (form.expenses.find(it => it.key === 'prg')?.selected ?? []).map(it => String(it))
        const pprs = (form.expenses.find(it => it.key === 'ppr')?.selected ?? []).map(it => String(it))
        const spfs = (form.expenses.find(it => it.key === 'spf')?.selected ?? []).map(it => String(it))

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        if (!form.measureUnit) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        const params: ReportDkiRTableDataRequest = {
            date: strDate,
            region: region,
            measureUnit: form.measureUnit.value,
            budgetType: form.budgetTypes.map(it => `0${it.value}`),
        
            gr: grs,
            pgr: pgrs,
            admin: abps,
            prg: prgs,
            ppr: pprs,
            spf: spfs
        }
        if (roundUpTo != null) {
            params.round = roundUpTo
        }

        const queryString = new URLSearchParams(
            ((params as unknown) as Record<string, string>)
        ).toString()

        const response = await fetch(`/api/budget-execution-report-constructor/data-dki?${queryString}`)
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseData = await response.json()
        const tableData: ReportDkiRRow[] = responseData.list

        // 2. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: ReportDkiRJournalRequest = {
            reportName: "DKI-R", // Приходится задавать для поддержки generics
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            date: strDate,
            region: region,
            table_data: responseData
        }

        // Итоговая строка
        const totalRow = calculateTotalRow(tableData, roundUpTo)
        const tableDataWithTotalRow = [totalRow].concat(cloneDeep(tableData))

        const result: TableDataAndJournalParams = {
            tableData: tableDataWithTotalRow,
            journalParams: journalParams
        }

        return result
    }
}

const calculateTotalRow = (allRows: ReportDkiRRow[], roundUpTo: number | null): ReportDkiRRow => {
    const totalRow: ReportDkiRRow = {
        gr: null,
        pgr: null,
        abp: null,
        prg: null,
        ppr: null,
        spf: null,
    
        name: 'ИТОГО',
    
        plan: 0,
        cash: 0,
        balance: 0,
        
        plan0: 0,
        cash0: 0,
        balance0: 0,
        plan1: 0,
        cash1: 0,
        balance1: 0,
        plan2: 0,
        cash2: 0,
        balance2: 0,
        plan3: 0,
        cash3: 0,
        balance3: 0,
        plan4: 0,
        cash4: 0,
        balance4: 0,
        plan5: 0,
        cash5: 0,
        balance5: 0,
        plan6: 0,
        cash6: 0,
        balance6: 0,
        plan7: 0,
        cash7: 0,
        balance7: 0,
        plan8: 0,
        cash8: 0,
        balance8: 0,
        plan9: 0,
        cash9: 0,
        balance9: 0,
        plan10: 0,
        cash10: 0,
        balance10: 0,
        plan11: 0,
        cash11: 0,
        balance11: 0
    }

    const uniqueGrRows: ReportDkiRRow[] = []

    allRows.forEach(row => {
        const found = uniqueGrRows.find(grRow => grRow.gr === row.gr)
        if (!found) {
            uniqueGrRows.push(row)
        }
    })

    uniqueGrRows
        .forEach(row => {
            totalRow.plan += row.plan
            totalRow.cash += row.cash
            totalRow.balance += row.balance
            for (let i = 0; i < 12; i++) {
                // @ts-ignore
                totalRow['plan' + i] += row['plan' + i]
                // @ts-ignore
                totalRow['cash' + i] += row['cash' + i]
                // @ts-ignore
                totalRow['balance' + i] += row['balance' + i]
            }
        })

    if (roundUpTo != null) {
        totalRow.plan = rounding(totalRow.plan, roundUpTo)
        totalRow.cash = rounding(totalRow.cash, roundUpTo)
        totalRow.balance = rounding(totalRow.balance, roundUpTo)
        for (let i = 0; i < 12; i++) {
            // @ts-ignore
            totalRow['plan' + i] = rounding(totalRow['plan' + i], roundUpTo)
            // @ts-ignore
            totalRow['cash' + i] = rounding(totalRow['cash' + i], roundUpTo)
            // @ts-ignore
            totalRow['balance' + i] = rounding(totalRow['balance' + i], roundUpTo)
        }
    }

    return totalRow
}
