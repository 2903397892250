






























































































































































































































































































































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { ReportIncomeTableData } from "../table-data/table-data-types"
import { Indicator } from "../../../common/types/Indicator"
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"
import { ReportIncomeFilterSettingsForm } from "../settings-form/ReportIncomeFilterSettingsForm";

type Column = 'action' | 'kat' | 'cls' | 'pcl' | 'spf' | 'region_name' | 'total_sum' | 'period_sum' | 'sumrg' | 'deviation_period' | 'percent_to_period' | 'deviation_annual' | 'percent_to_year' | 'sumrg_past' | 'deviation_past' | 'growth'

export interface TableField {
    key: Column,
    label: string,
    active: boolean
}

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportIncome extends Vue {

    @Prop({
        type: Object,
        required: true,
    })
    public tableData!: ReportIncomeTableData

    @Prop({
        type: Array,
        required: true
    })
    private readonly propIndicators!: Indicator[]

    @Prop({
        type: Date,
        required: true
    })
    private readonly reportDate!: Date
    private readonly nextYear: number = this.reportDate.getFullYear()

    @Prop({
        type: Boolean,
        required: false,
    })
    public prevYear!: boolean

    @Prop({
        type: Array,
        required: true,
    })
    public regions!: object

    @Prop({
        type: Object,
        required: true,
    })
    public filterSettings!: ReportIncomeFilterSettingsForm

    // public variant: 'UNFULFILLED' | 'OVERFULFILLED' = 'UNFULFILLED'
    public tableFields: TableField[] = []
    public indicators: Indicator[] = []

    public columnsNameMap: Record<Column, string> = {
        'action': '',
        'kat': '',
        'cls': '',
        'pcl': '',
        'spf': '',
        'region_name': 'Наименование',
        'total_sum': `План на ${this.nextYear} год`,
        'period_sum': 'План на отчетный период',
        'sumrg': 'Фактическое исполнение',
        'deviation_period': 'Отклонение к периоду',
        'percent_to_period': '% к периоду',
        'deviation_annual': 'Отклонение к годовому плану',
        'percent_to_year': '% к году',
        'sumrg_past': 'Фактическое поступление на аналогичный период прошлого года',
        'deviation_past': 'Темп роста, %',
        'growth': 'Отклонение',
    }
    public totalExpand: boolean = true

    private open = false;

    private treeData: any = [];

    private keyCounter = 0;

    private settings: ReportIncomeFilterSettingsForm = this.filterSettings;

    private itemsData: ReportIncomeTableData  = this.tableData;

    private regionsList: any = [];

    private created() {
        this.keyCounter += 1;
        this.indicators = cloneDeep(this.propIndicators);
        this.settings = cloneDeep(this.filterSettings);
        this.itemsData = cloneDeep(this.tableData);
        this.regionsList = cloneDeep(this.regions);
        this.constructTableFields();
        this.treeStructure();
        // this.$watch(
        //     () => this.filterSettings,
        //     () => {
        //         this.constructTableFields();
        //         this.treeStructure();
        //         this.keyCounter += 1;
        //     },
        //     { deep: true }
        // );
        
    }

    private treeStructure() {
        this.treeData = [];
        if (this.settings.isTree) {
            const tree = []
            for (const data of this.itemsData.template2_items_tree as any) {
                const { cls_list, ...k } = data;
                k.visible = true;
                k.open = this.open;
                tree.push(k)
                for (const cls of data.cls_list) {
                    const { pcl_list, ...c } = cls;
                    c.open = this.open;
                    tree.push(c)
                    for (const pcl of cls.pcl_list) {
                        const { spf_list, ...p } = pcl;
                        p.open = this.open;
                        tree.push(p);
                        for (const spf of pcl.spf_list) {
                            tree.push(spf)
                        }
                    }
                }
            }
            this.treeData = tree;
        }
        if (this.settings.separateByRegion) {
            const tree = [];
            for (const data of this.itemsData.template1_items as any) {
                if (data.children) {
                    const { chidlren, ...reg } = data;
                    reg.visible = true;
                    if (data.children.length > 0) {
                        reg.open = this.open;
                    }
                    tree.push(reg);
                    for (const child_reg of data.children) {
                        tree.push(child_reg)
                    }
                } else {
                    data.visible = true;
                    tree.push(data)
                }
            }
            this.treeData = tree;
        }
    }

    private constructTableFields() {
        const tempTableFields: TableField[]  = []

        // Показатели
        this.propIndicators.forEach(indicator => {
            const key: Column = indicator.key as unknown as Column
            if (this.settings.isTree) {
                if (indicator.key == 'action' || indicator.key == 'kat' || indicator.key == 'cls' || indicator.key == 'pcl' || indicator.key == 'spf') {
                    indicator.active = true;
                }
            } else {
                if (indicator.key == 'action' || indicator.key == 'kat' || indicator.key == 'cls' || indicator.key == 'pcl' || indicator.key == 'spf') {
                    indicator.active = false;
                }
            }
            if (this.prevYear) {
                if (indicator.key == 'sumrg_past' || indicator.key == 'deviation_past' || indicator.key == 'growth') {
                    indicator.active = true;
                }
            } else {
                if (indicator.key == 'sumrg_past' || indicator.key == 'deviation_past' || indicator.key == 'growth') {
                    indicator.active = false;
                }
            }
            tempTableFields.push({ key: key, label: this.columnsNameMap[key], active: indicator.active })
        })

        this.tableFields = tempTableFields
    }

    public formatDigitsGrouping = formatDigitsGrouping

    private openAll() {
        this.open = !this.open;
        for (const r of this.treeData) {
            if (this.settings.isTree) {
                if (r.kat) {
                    r.open = this.open;
                } else {
                    r.visible = this.open;
                }
            }
            if (this.settings.separateByRegion) {
                if (r.open !== undefined) {
                    r.open = this.open;
                } else {
                    r.visible = this.open;
                }
            }
        }
    }

    private openChilds(item: any) {
        item.open = !item.open;
        if (item.kat) {
            if (!item.open) {
                for (const r of this.treeData.filter((x: any) => x.parent_kat == item.kat)) {
                    r.open = item.open;
                    r.visible = item.open;
                }
            } else {
                for (const r of this.treeData.filter((x: any) => x.cls && x.parent_kat == item.kat)) {
                    r.visible = item.open;
                }
            }
        }
        if (item.cls) {
            if (!item.open) {
                for (const r of this.treeData.filter((x: any) => (x.parent_kat == item.parent_kat) && (x.parent_cls == item.cls))) {
                    r.open = item.open;
                    r.visible = item.open;
                }
            } else {
                for (const r of this.treeData.filter((x: any) => x.pcl && (x.parent_cls == item.cls) && (item.parent_kat == x.parent_kat))) {
                    r.visible = item.open;
                }
            }
        }
        if (item.pcl) {
            for (const r of this.treeData.filter((x: any) => x.spf && (x.parent_pcl == item.pcl) && (x.parent_cls == item.parent_cls) && (x.parent_kat == item.parent_kat))) {
                r.visible = item.open;
            }
        }
        if (item.children) {
            for (const r of this.treeData.filter((x: any) => !x.children && x.region_code.slice(0, 4) === item.region_code.slice(0, 4))) {
                r.visible = item.open;
            }
        }
    }

    private valueRound(value: any) {
        if (this.settings.roundUp) {
            const roundUpFactor = Math.pow(10, Number(this.settings.roundUpTo));
            if (Number(this.settings.roundUpTo) > 0) {
                return formatDigitsGrouping(Math.round(value * roundUpFactor) / roundUpFactor);
            }
            return formatDigitsGrouping(Math.round(value));
        }
        return formatDigitsGrouping(value)
    }
}
