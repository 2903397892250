import { render, staticRenderFns } from "./report-1-27.vue?vue&type=template&id=36c4de0e&scoped=true&"
import script from "./report-1-27.vue?vue&type=script&lang=ts&"
export * from "./report-1-27.vue?vue&type=script&lang=ts&"
import style0 from "./report-1-27.vue?vue&type=style&index=0&id=36c4de0e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c4de0e",
  null
  
)

export default component.exports