import { cloneDeep } from "lodash";
import { FilterOptions } from "../../../common/types/roots/FilterOptions";
import { Facade, TableDataAndJournalParams } from "../../../common/Facade";
import { FilterSettings } from "../../../common/types/roots/FilterSettings";
import { FilterSettingsForm } from "../../../common/types/roots/FilterSettingsForm";
import { Report127ActFilterSettingsForm } from "../settings-form/Report127ActFilterSettingsForm";
import { Report127ActFilterSettings } from "../settings/Report127ActFilterSettings";
import { Report127ActTableDataRequest } from "../data-request/Report127ActTableDataRequest";
import { Report127ActJournalRequest } from "../journal/Report127ActJournalRequest";
import { report127ActFilterOptions } from "../report-127-act";
import i18nService from "@/services/i18n";
import { Report127ActRow } from "../table-data/table-data-types";

export class Report127ActFacade implements Facade {
    getFilterOptions(): FilterOptions {
        return cloneDeep(report127ActFilterOptions)
    }

    settingsToForm(settings: FilterSettings, options: FilterOptions): FilterSettingsForm {
        // 1. Валидаций
        if (settings.reportName !== '1-27-ACT') {
            throw new Error('не соответсвующий подтип FilterSettings')
        }
        if (options.key !== '1-27-ACT') {
            throw new Error('не соответсвующий подтип FilterOptions')
        }

        let reportName = options.reportNames.find(it => it.value === settings.reportName)
        if (!reportName) {
            throw new Error('Не найден reportName')
        }
        reportName = { ...reportName }

        let reportType = options.reportTypes.find(it => it.value === settings.reportType)!
        if (!reportType) {
            throw new Error('Не найден reportType')
        }
        reportType = { ...reportType }

        let classificationType = options.classificationTypes.find(it => it.value === settings.classificationType)
        if (!classificationType) {
            throw new Error('Не найден classificationType')
        }
        classificationType = { ...classificationType }

        let dataSource = options.dataSources.find(it => it.value === settings.dataSource)
        if (!dataSource) {
            throw new Error('Не найден dataSource')
        }
        dataSource = { ...dataSource }

        let periodicity = options.periodicities.find(it => it.value === settings.periodicity)
        if (!periodicity) {
            throw new Error('Не найден periodicity')
        }
        periodicity = { ...periodicity }

        let regionOption = options.regions.find(it => it.value === settings.region)
        if (!regionOption) {
            throw new Error('Не найден regionOption')
        }
        regionOption = { ...regionOption }

        const budgetTypes = cloneDeep(
            options.budgetTypes.filter(it => settings.budgetTypes.includes(it.value))
        )

        const statuses = cloneDeep(
            options.statuses.filter(it => settings.statuses.includes(it.value))
        )

        let measureUnit = options.measureUnits.find(it => it.value === settings.measureUnit)
        if (!measureUnit) {
            throw new Error('Не найден measureUnit')
        }
        measureUnit = { ...measureUnit }

        let month = options.months.find(it => it.value == settings.month) ?? null
        if (month) {
            month = { ...month }
        }

        let isRoundUp: boolean = false
        if (settings.roundUpTo != null) {
            isRoundUp = true
        }

        // 2. Формирование
        const form: Report127ActFilterSettingsForm = {
            key: '1-27-ACT',

            reportName: reportName,
            reportType: reportType,
            classificationType: classificationType,
            dataSource: dataSource,
            periodicity: periodicity,

            region: regionOption,
            budgetTypes: budgetTypes,
            statuses: statuses,
            measureUnit: measureUnit,
            year: settings.year,
            month: month,
            roundUp: isRoundUp,
            roundUpTo: settings.roundUpTo ?? null
        }

        return form
    }

    formToSettings(form: FilterSettingsForm): FilterSettings {
        if (form.key !== '1-27-ACT') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }
        if (form.year == null) {
            throw new Error('Необходимо ввести "Год"')
        }
        if (form.region == null) {
            throw new Error('Необходимо ввести "Регион"')
        }
        if (form.month == null) {
            throw new Error('Необходимо ввести "Месяц"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        const settings: Report127ActFilterSettings = {
            reportName: '1-27-ACT',

            reportType: form.reportType.value,
            classificationType: form.classificationType.value,
            dataSource: form.dataSource.value,
            periodicity: form.periodicity.value,

            region: form.region.value,
            budgetTypes: form.budgetTypes.map(it => it.value),
            statuses: form.statuses.map(it => it.value),
            measureUnit: form.measureUnit.value,
            year: form.year,
            month: form.month.value,
            roundUpTo: roundUpTo
        }

        return settings
    }

    getEmptyForm(): FilterSettingsForm {
        const options = cloneDeep(report127ActFilterOptions)

        const emptyForm: Report127ActFilterSettingsForm = {
            key: '1-27-ACT',

            reportName: options.reportNames.find(it => it.value === '1-27-ACT')!,
            reportType: options.reportTypes.find(it => it.value === 'REGULATED')!,
            classificationType: options.classificationTypes.find(it => it.value === 'MIXED')!,
            dataSource: options.dataSources.find(it => it.value === 'LOADER')!,
            periodicity: options.periodicities.find(it => it.value === 'MONTH')!,

            region: null,
            budgetTypes: [],
            statuses: options.statuses.filter(it => it.value === '39'),
            measureUnit: options.measureUnits.find(it => it.value === 'THOUSAND')!,
            year: (new Date().getFullYear()),
            month: null,
            roundUp: false,
            roundUpTo: null
        }

        return emptyForm
    }

    async constructReport(form: FilterSettingsForm, templateName: string, userId: string): Promise<TableDataAndJournalParams> {
        // 1. Валидаций
        if (form.key !== '1-27-ACT') {
            throw new Error('не соответсвующий подтип FilterSettingsForm')
        }

        if (!form.year || !form.month) {
            throw new Error('Необходимо ввести "Год" и "Месяц"')
        }

        if (!form.region) {
            throw new Error('Необходимо ввести "Регион"')
        }

        if (form.measureUnit == null) {
            throw new Error('Необходимо ввести "Единица измерения"')
        }

        let roundUpTo: number | null = null
        if (form.roundUp) {
            roundUpTo = form.roundUpTo ?? null
        }

        // 2. Формирование данных для таблицы
        const params: Report127ActTableDataRequest = {
            year: form.year,
            month: form.month.value,
            status_list: form.statuses.map(it => Number(it.value)),
            region: form.region.value,
            budget_types: form.budgetTypes.map(it => it.value),
            measure_unit: form.measureUnit.value,
            round_up_to: roundUpTo
        }
        const response = await fetch('/api-py/monitoring/reports-constructor/act-1-27',
            {
                method: 'POST',
                body: JSON.stringify(params)
            }
        )
        if (!response.ok) {
            throw new Error('Не удалось получить данные для отчета')
        }
        const responseTableData = await response.json()

        const tableData: Report127ActRow[] = transformIntoRows(responseTableData)

        // 3. Формирование параметров для выгрузки журнал
        const settings: FilterSettings = this.formToSettings(cloneDeep(form))
        const journalParams: Report127ActJournalRequest = {
            reportName: "1-27-ACT",
            // journal data
            name: templateName,
            filter_settings: settings,
            user_id: userId,
            // for excel
            params: params,
            locale: i18nService.locale
        }

        const result: TableDataAndJournalParams = {
            tableData: tableData,
            journalParams: journalParams
        }

        return result
    }
}

const transformIntoRows = (data: any): Report127ActRow[] => {
    const rows: Report127ActRow[] = []

    const sectionKeys = [
        { name: 'general_income_total', departmentKey: 'act_general_income_total', localKey: 'general_income_total' },
        { name: 'general_expense_total', departmentKey: 'act_general_expense_total', localKey: 'general_expense_total' },
        { name: 'budget_loan_expense_total', departmentKey: 'act_budget_loan_expense_total', localKey: 'budget_loan_expense_total' },
        { name: 'budget_loan_income_total', departmentKey: 'act_budget_loan_income_total', localKey: 'budget_loan_income_total' },
        { name: 'financial_actives_expense_total', departmentKey: 'act_financial_actives_expense_total', localKey: 'financial_actives_expense_total' },
        { name: 'financial_actives_income_total', departmentKey: 'act_financial_actives_income_total', localKey: 'financial_actives_income_total' },
        { name: 'finance_deficit_budget_income_7_total', departmentKey: 'act_finance_deficit_budget_income_7_total', localKey: 'finance_deficit_budget_income_7_total' },
        { name: 'finance_deficit_budget_expense_total', departmentKey: 'act_finance_deficit_budget_expense_total', localKey: 'finance_deficit_budget_expense_total' },
    ]

    let outerIndex = 1
    data.forEach((sectionData: any) => {
        if (!['acc_sld_beg', 'acc_sld_end'].includes(sectionData.data_key)) {
            rows.push({
                number: String(outerIndex),
                name: sectionData.data_key,
                department: null,
                local: null,
                difference: null
            })
            sectionKeys
                .filter(it => {
                    if (sectionData.data_key === 'plgo') {
                        return [
                            'general_expense_total',
                            'budget_loan_expense_total',
                            'financial_actives_expense_total',
                            'finance_deficit_budget_expense_total'
                        ].includes(it.name)
                    } else {
                        return true
                    }
                }).forEach((keys, index) => {
                    rows.push({
                        number: `${outerIndex}.${index + 1}`,
                        name: keys.name,
                        department: sectionData[keys.departmentKey],
                        local: sectionData[keys.localKey],
                        difference: sectionData[keys.departmentKey] - sectionData[keys.localKey]
                    })
                })
        } else {
            rows.push({
                number: String(outerIndex),
                name: sectionData.data_key,
                department: sectionData.act_value,
                local: sectionData.value,
                difference: sectionData.act_value - sectionData.value
            })
        }

        outerIndex++
    })

    rows.push({
        number: String(outerIndex),
        name: 'free_budget',
        department: null,
        local: null,
        difference: null
    })

    return rows
}
