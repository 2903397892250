



















































































import VueElementLoading from "vue-element-loading"
import VueDraggable from 'vuedraggable'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Classificator } from "../../../common/types/Classificator"
import { ReportDkiPTableData } from "../table-data/ReportDkiPTableData"
import { formatDigitsGrouping } from "../../../common/utils/formattingUtils"

@Component({
    components: {
        'draggable': VueDraggable,
        'loading': VueElementLoading
    }
})
export default class ReportDkiR extends Vue {

    @Prop({
        type: Object,
        required: true,
    })
    public tableData!: ReportDkiPTableData

    @Prop({
        type: Array,
        required: true
    })
    private readonly incomes!: Classificator[]


    @Prop({
        type: Boolean,
        required: true
    })
    private readonly incomeTotal!: boolean[]



    public columnsNameMap: Record<string, string> = {
        'kat': 'Категория',
        'cls': 'Класс',
        'pcl': 'Подкласс',
        'spf': 'Специфика',
        'name_of_income': 'Наименование доходов',
        'cash_execution_total': 'Кассовое исполнение, всего',
        'total': 'Итого:',
        'including_by_month': 'в том числе по месяцам',
        'month1': 'январь',
        'month2': 'февраль',
        'month3': 'март',
        'month4': 'апрель',
        'month5': 'май',
        'month6': 'июнь',
        'month7': 'июль',
        'month8': 'август',
        'month9': 'сентябрь',
        'month10': 'октябрь',
        'month11': 'ноябрь',
        'month12': 'декабрь',
    }
    
    public formatDigitsGrouping = formatDigitsGrouping
}
