<template>
    <div>
        <div class="table-container">
            <div class="filter-breadcrumb" ref="filterBreadcrumb">
                <span class="item-breadcrumb" v-if="dateTo !== null">{{ 'Дата' }} - {{ dateTo }}</span>
                <span class="item-breadcrumb" v-if="reg !== null && reg_name !== null">{{ 'Регион' }} - {{ reg }}</span>
                <span class="item-breadcrumb" v-if="reg_switch === true">{{ 'Разрезность' }} - {{ cutness.text }}</span>
                <span class="item-breadcrumb" v-if="reg_switch === false && codeCount.abp === 1">{{ 'АБП' }} - {{ countNumber('abp')}}</span>
                <span class="item-breadcrumb" v-if="reg_switch === false && codeCount.prg === 1">{{ 'Программа' }} - {{ countNumber('prg')}}</span>
                <span class="item-breadcrumb" v-if="reg_switch === false && codeCount.ppr === 1">{{ 'Подпрограмма' }} - {{ countNumber('ppr')}}</span>
                <span class="item-breadcrumb" v-if="reg_switch === false && codeCount.spf === 1">{{ 'Специфика' }} - {{ countNumber('spf')}}</span>
                <span class="item-breadcrumb" v-if="vzk !== null"> {{ vzk }}</span>
                <span class="item-breadcrumb" v-if="develop !== null">{{ 'Вид программы' }} - {{ develop }}</span>
            </div>
        </div>
        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="spfForm"
                responsive="true"
                class="text-center"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="1"></b-th>
                        <b-th v-if="reg_switch === true" colspan="1" class="text-center">{{ 'Код бюджетной классификации' }}</b-th>
                        <b-th v-if="reg_switch === false && count_cut === 1" colspan="1" class="text-center">{{ 'Код бюджетной классификации' }}</b-th>
                        <b-th v-if="reg_switch === false && count_cut === 2" colspan="2" class="text-center">{{ 'Код бюджетной классификации' }}</b-th>
                        <b-th v-if="reg_switch === false && count_cut === 3" colspan="3" class="text-center">{{ 'Код бюджетной классификации' }}</b-th>
                        <b-th v-if="reg_switch === false && count_cut === 4" colspan="4" class="text-center">{{ 'Код бюджетной классификации' }}</b-th>
                        <b-th colspan="1"></b-th>
                        <b-th colspan="3" class="text-center">{{ 'Скорректированный план' }}</b-th>
                        <b-th colspan="3" class="text-center">{{ 'Кассовое исполнение' }}</b-th>
                        <b-th colspan="2" class="text-center">{{ 'Остаток' }}</b-th>
                        <b-th colspan="2" class="text-center">{{ '% исполнения' }}</b-th>
                    </b-tr>
                </template>
                <template #top-row="data" v-if="totalsSpf !== null">
                    <td v-if="reg_switch === true" class="text-right" colspan="2"></td>
                    <td v-if="reg_switch === false && count_cut === 1" class="text-right" colspan="2"></td>
                    <td v-if="reg_switch === false && count_cut === 2" class="text-right" colspan="3"></td>
                    <td v-if="reg_switch === false && count_cut === 3" class="text-right" colspan="4"></td>
                    <td v-if="reg_switch === false && count_cut === 4" class="text-right" colspan="5"></td>
                    <td class="text-left" colspan="1">{{ totalsSpf.name_ru }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row3)) }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row4))}}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row5)) }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row6)) }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row7)) }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row8)) }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row3 - totalsSpf.row6)) }}</td>
                    <td class="text-right">{{ $n(toNum(totalsSpf.row4 - totalsSpf.row6)) }}</td>
                    <td class="text-right">{{ totalsSpf.row3 > 0 ? $n(toNum((totalsSpf.row6/ totalsSpf.row3) * 100)) : 0 }}</td>
                    <td class="text-right">{{ totalsSpf.row4 > 0 ? $n(toNum((totalsSpf.row6 / totalsSpf.row4) * 100)) : 0 }}</td>

                </template>
                <template #cell(action)="data">
                    <b-button v-if="data.item.type === buttonOpenChildDisplay('action')" @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(abp)="data">
                    <div v-if="data.item.type === 3" class="text-center">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === buttonOpenChildDisplay('abp'))"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(prg)="data">
                    <div v-if="data.item.type === 4" class="text-center">{{ data.value }}</div>
                    <b-button v-if="(data.item.type === buttonOpenChildDisplay('prg'))"
                              @click="openChilds(data.item)">
                        <i class="icon icon-chevron-circle icon-rotate-180" v-if="data.item.open"></i>
                        <i class="icon icon-chevron-circle" v-if="!data.item.open"></i>
                    </b-button>
                </template>
                <template #cell(ppr)="data">
                    <div v-if="data.item.type === 5" class="text-center">{{ data.value }}</div>
                </template>
                <template #cell(spf)="data">
                    <div v-if="data.item.type === 6" class="text-center">{{ data.value }}</div>
                </template>
                <template #cell(region)="data">
                    <div v-if="data && data.value" class="text-center">{{ $n(data.value) }}</div>
                </template>
                <template #cell()="data">
                    <div class="text-right">{{ $n(toNum(data.value)) }}</div>
                </template>
                <template #cell(name_ru)="data">
                    <div v-if="data.item.type !== 'RB' && data.item.type !== 'MB'" class="text-left">
                        {{ data.item.name_ru }}
                    </div>
                    <div v-if="data.item.type === 'RB' || data.item.type === 'MB'" class="text-left">
                        {{ data.item.name_ru }}
                    </div>
                </template>
            </b-table>
        </div>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                <span class="left">
                    <i class="icon icon-download"></i> Скачать
                </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item v-if="reg_switch === true" @click="downloadRep_region">Отчет 5-42 в разрезе регионов
                </b-dropdown-item>
                <b-dropdown-item v-if="reg_switch === false" @click="downloadRep_spf">{{ ReportName}}
                </b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>
<script>
import {Ax} from '@/utils';
import moment from 'moment';
import VueElementLoading from "vue-element-loading";

function calculateRow(arr) {
    arr.forEach(item => {
        item.row11 = item.row3 === 0 ? 0 : (item.row6 / item.row3 * 100).toFixed(1);
        item.row12 = item.row4 === 0 ? 0 : (item.row6 / item.row4 * 100).toFixed(1);
        if (item.child && item.child.length > 0) {
            calculateRow(item.child);
        }
    });
}

export default {
    name: "report-form-542",
    components: {
        'loading': VueElementLoading
    },
    data() {
        return {
            obl: null,
            listReg: [],
            curParams: {},
            reg: null,
            reg_name: null,
            vzk: null,
            develop: null,
            dateFrom: null,
            modalForm: false,
            reasonForm: false,
            currYear: new Date().getFullYear(),
            curDate: null,
            dateTo: null,
            isLoad: false,
            isSave: false,
            isOpen: false,
            isNew: false,
            repCode: 'R542',
            repName: 'Отчет о кассовом исполнении по форме 5-42',
            listReports: [],
            arr: [],
            codeCount: {abp: 0, prg: 0, ppr: 0, spf: 0},
            reg_switch: true,
            data_round: false,
            count_cut: 4,
            totalsSpf: null,
            changeRow: false,
            cutness: {text: 'Регион', code: 0},
            abpListCount: 0,
            prgListCount: 0,
            pprListCount: 0,
            spfListCount: 0,
            curReport: [],
            curRegion: 0,
            newReport: {id: 0, name_ru: '', name_kk: '', name_en: ''},
            loading: false,
            regName: 'Все',
            arrForm: [],
            curRow: [],
            regionForm: null,
            spfForm: null,
            originalTableFields: [
                { key: 'action', label: '', class: 'toggle-show' },
                { key: 'region', label: 'Регион' },
                { key: 'abp', label: 'АБП' },
                { key: 'prg', label: 'БП' },
                { key: 'ppr', label: 'БПП' },
                { key: 'spf', label: 'СП' },
                { key: 'name_ru', label: 'Наименование' },
                { key: 'row3', label: 'на год' },
                { key: 'row4', label: 'на отчетный период' },
                { key: 'row5', label: 'за отчетный месяц' },
                { key: 'row6', label: 'на отчетный период' },
                { key: 'row7', label: 'за отчетный месяц' },
                { key: 'row8', label: 'за день' },
                { key: 'row9', label: 'на год' },
                { key: 'row10', label: 'на текущий период' },
                { key: 'row11', label: 'на год' },
                { key: 'row12', label: 'на текущий период' },
            ],
            tableFields: [
                {
                    key: 'action',
                    label: '',
                    class: 'toggle-show'
                },
                {
                    key: 'abp',
                    label: 'АБП'
                },
                {
                    key: 'prg',
                    label: 'БП'
                },
                {
                    key: 'ppr',
                    label: 'БПП'
                },
                {
                    key: 'spf',
                    label: 'СП'
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'row3',
                    label: 'на год'
                },
                {
                    key: 'row4',
                    label: 'на отчетный период'
                },
                {
                    key: 'row5',
                    label: 'за отчетный месяц'
                },
                {
                    key: 'row6',
                    label: 'на отчетный период'
                },
                {
                    key: 'row7',
                    label: 'за отчетный месяц'
                },
                {
                    key: 'row8',
                    label: 'за день'
                },
                {
                    key: 'row9',
                    label: 'на год'
                },
                {
                    key: 'row10',
                    label: 'на текущий период'
                },
                {
                    key: 'row11',
                    label: 'на год'
                },
                {
                    key: 'row12',
                    label: 'на текущий период'
                },
            ],

        };
    },
    beforeUpdate() {
        if (this.spfForm && Array.isArray(this.spfForm)) {
            for (const row of this.spfForm) {
                if ([3].includes(row.type)) {
                    row._rowVariant = 'primary';
                }
                if ([4, 5].includes(row.type)) {
                    row._rowVariant = 'info';
                }
            }
        }
    },

    async mounted() {
    },

    created() {
        this.$on('loadReportFormData', (period, date_to, month, year, region, reg_name, cutness, vzk,
                                        develop, obl, uuid, reg_switch, data_round, abpL, prgL, pprL, spfL, fillCbc) => {
            this.loadData(period, date_to, month, year, region, reg_name, cutness.code, vzk.code, develop.code,
                obl, uuid, reg_switch, data_round, abpL, prgL, pprL, spfL, fillCbc);
            this.abpListCount = abpL;
            this.prgListCount = prgL;
            this.pprListCount = pprL;
            this.spfListCount = spfL;
            this.cutness = cutness;
            this.reg = region;
            this.reg_name = reg_name;
            this.vzk = vzk.text;
            this.develop = develop.text;
            this.reg_switch = reg_switch;
            this.data_round = data_round;
            if (reg_switch === false){
                let localCount = 0;
                if (abpL !== '') {
                    localCount += 1;
                }
                if (prgL !== '') {
                    localCount += 1;
                }
                if (pprL !== '') {
                    localCount += 1;
                }
                if (spfL !== '') {
                    localCount += 1;
                }
                this.count_cut = localCount;
            }

        });
    },
    computed: {
        labelText() {
            return this.repName + '_' + this.reg.name_ru + '/' + this.cutness.text + '/' + this.selecteds.text;
        },
        ReportName(){
            const components = { abp: 'АБП', prg: 'Программа', ppr: 'Подпрограмма', spf: 'Специфика' };
            const includedComponents = [];

            if (this.codeCount.abp) includedComponents.push(components.abp);
            if (this.codeCount.prg) includedComponents.push(components.prg);
            if (this.codeCount.ppr) includedComponents.push(components.ppr);
            if (this.codeCount.spf) includedComponents.push(components.spf);

            return 'Отчет 5-42 (' + includedComponents.join('+') + ')';
        },
    },

    methods: {
        countNumber(code) {
            if (code === 'abp'){
                if (this.abpListCount === '000'){
                    return 'Все';
                }
                else {
                    return `Выбрано ${this.abpListCount.split(',').length}`;
                }
            }
            if (code === 'prg'){
                if (this.prgListCount === '000'){
                    return 'Все';
                }
                else {
                    return `Выбрано ${this.prgListCount.split(',').length}`;
                }
            }
            if (code === 'ppr'){
                if (this.pprListCount === '000'){
                    return 'Все';
                }
                else {
                    return `Выбрано ${this.pprListCount.split(',').length}`;
                }
            }
            if (code === 'spf'){
                if (this.spfListCount === '000'){
                    return 'Все';
                }
                else {
                    return `Выбрано ${this.spfListCount.split(',').length}`;
                }
            }
        },
        buttonOpenChildDisplay(code) {
            const levels = Object.keys(this.codeCount).filter(field => this.codeCount[field] === 1); // find active
            const typeToField = {3: 'abp', 4: 'prg', 5: 'ppr', 6: 'spf'};
            const levelsTypes = Object.entries(typeToField)
                .filter(([type, field]) => levels.includes(field))
                .map(([type]) => parseInt(type, 10));

            if (code === 'action' && levelsTypes.length !== 1) {
                if (this.reg_switch === false) {
                    return levelsTypes[0];
                }
            } else if (code === 'abp') {
                const index = levelsTypes.indexOf(3);
                if (index !== -1 && index + 1 < levelsTypes.length - 1) {
                    return levelsTypes[index + 1];
                }
            } else if (code === 'prg') {
                const index = levelsTypes.indexOf(4);
                if (index !== -1 && index + 1 < levelsTypes.length - 1) {
                    return levelsTypes[index + 1];
                }
            }
            return null;
        },
        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                txt = obj[codeName] + ' - ' + txt;
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },
        async loadData(period, date_to, month, year, region, reg_name, cutness, vzk, develop, obl, uuid, reg_switch, data_round, abpL, prgL, pprL, spfL, fillCbc) {
            try {
                this.firstDateTo = new Date(date_to);
                this.curParams = {
                    period: period,
                    date_to: moment(this.firstDateTo).format('YYYY-MM-DD'),
                    month: month,
                    year: year,
                    region: region,
                    reg_name: reg_name,
                    cutness: cutness,
                    vzk: vzk,
                    develop_type: develop,
                    obl: obl,
                    user_id: uuid,
                    abpL: abpL,
                    prgL: prgL,
                    pprL: pprL,
                    spfL: spfL,
                    data_round: data_round,
                    fillCbc: fillCbc,
                    reg_switch: reg_switch
                };
                this.tableFields = [...this.originalTableFields];
                this.loading = true;
                if (reg_switch === true) {
                    this.tableFields = this.tableFields.filter(field => field.key !== 'abp' && field.key !== 'prg' &&
                        field.key !== 'ppr' && field.key !== 'spf');
                    await this.reportOpen(this.curParams);
                    this.isLoading = true;
                }
                else if (reg_switch === false) {
                    this.codeCount.abp = abpL !== '' ? 1 : 0;
                    this.codeCount.prg = prgL !== '' ? 1 : 0;
                    this.codeCount.ppr = pprL !== '' ? 1 : 0;
                    this.codeCount.spf = spfL !== '' ? 1 : 0;
                    this.tableFields = this.tableFields.filter(field => field.key !== 'region');
                    if (this.codeCount.abp === 0){
                        this.tableFields = this.tableFields.filter(field => field.key !== 'abp');
                    }
                    if (this.codeCount.prg === 0){
                        this.tableFields = this.tableFields.filter(field => field.key !== 'prg');
                    }
                    if (this.codeCount.ppr === 0){
                        this.tableFields = this.tableFields.filter(field => field.key !== 'ppr');
                    }
                    if (this.codeCount.spf === 0){
                        this.tableFields = this.tableFields.filter(field => field.key !== 'spf');
                    }
                    await this.reportOpenSpf(this.curParams);
                    this.isLoading = true;
                }
            } finally {
                this.loading = false;
            }
        },
        async reportOpen(params) {
            this.loading = true;
            const response = await fetch(`/api-py/get-fulldata-region-542/` + JSON.stringify(params));
            const data = await response.json();
            this.spfForm = data.arr;
            this.totalsSpf = data.totals;
            this.dateTo  = data.period;
            calculateRow(this.spfForm);
            this.toOpen();
            this.loading = false;
            if (this.spfForm.length === 0){
                this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
            }
        },
        async reportOpenSpf(params) {
            this.loading = true;
            const response = await fetch(`/api-py/get-fulldata-spf-542/` + JSON.stringify(params));
            const data = await response.json();
            this.spfForm = data.arr;
            this.totalsSpf = data.totals;
            this.dateTo  = data.period;
            calculateRow(this.spfForm);
            this.toOpen();
            this.loading = false;
            if (this.spfForm.length === 0){
                this.makeToast('info', 'Внимание', 'Данных по таким параметрам нет.');
            }
        },
        toOpen() {
            this.isOpen = !this.isOpen;
            this.$emit('repOpen', this.isOpen);
        },
        onReasonChange(item) {
            this.$set(item, 'onSave', true);
            this.isSave = true;
        },
         downloadRep_region() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.loading = true;
                Ax(
                    {
                        url: '/api-py/report542_region/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        this.loading = false;
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                        this.loading = false;
                    }
                )
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                this.loading = false;
            } finally {
            }
        },
        downloadRep_spf() {
            try {
                this.makeToast('info', 'Внимание!', 'Скоро начнется скачивание. Пожалуйста ожидайте.');
                this.loading = true;
                Ax(
                    {
                        url: '/api-py/report542_spf/' + JSON.stringify(this.curParams),
                        method: 'POST',
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.repName + '.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        this.loading = false;
                    },
                    (error) => {
                        this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                        this.loading = false;
                    }
                );
            } catch (error) {
                console.log(error)
                this.makeToast('danger', 'Предупреждение', 'Необходимо заполнить все обязательные фильтры!');
                this.loading = false;
            } finally {
            }
        },
        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);
            if (parent.child) {
                let dat = parent.child;
                dat = dat.sort(this.compare_spf);
                dat = dat.sort(this.compare_ppr);
                dat = dat.sort(this.compare_prg);
                dat = dat.sort(this.compare_abp);
                dat = dat.sort(this.compare_null);
                for (const row of dat) {
                    if ((parent.id === row.parent_id)) {
                        // this.changeDevelop(row);
                        const ind = this.spfForm.indexOf(parent);
                        if (this.spfForm.indexOf(row) < 0) {
                            this.spfForm.splice(ind + 1, 0, row);
                        }
                        if ([3, 4, 5, 6].includes(row.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        // if ([6].includes(row.type)) {
                        //     row.visible = false;
                        // }
                        if ([5].includes(row.type)) {
                            row.visible = parent.open;
                            row.open = false;
                            this.openChilds(row, row.open);
                        }
                        if ([5].includes(parent.type)) {
                            this.openChilds(row, parent.open);
                            row.visible = parent.open;
                            row.open = parent.open;
                        }
                        if ((this.spfForm.indexOf(row) > 0) && (!row.visible)) {
                            this.spfForm.splice(this.spfForm.indexOf(row), 1);
                        }
                        this.deleteNulls();
                    }
                }
            }
        },
        async deleteNulls() {
            for (const row of this.spfForm) {
                if (parseFloat(row.row3)
                    + parseFloat(row.row4)
                    + parseFloat(row.row5)
                    + parseFloat(row.row6)
                    + parseFloat(row.row7)
                    + parseFloat(row.row8)
                    + parseFloat(row.row9)
                    + parseFloat(row.row10)
                    + parseFloat(row.row11) === 0) {
                    row.visible = false;
                }
            }
        },
        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну
        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab
        createTable(elem, parentId) { // создание таблицы на основе дерева
            const that = this;

            const item = Object.assign({}, elem);

            that.$set(item, 'parent_id', parentId);
            that.$set(item, 'visible', true);

            const par = that.arrDataMap.get(item.parent_id);
            if (par) {
                if (par.hasOwnProperty('newchild')) {
                    that.$set(par.newchild, par.newchild.length, item);
                } else {
                    that.$set(par, 'newchild', []);
                    that.$set(par.newchild, par.newchild.length, item);
                }
            }
            Object.defineProperty(item, 'parent', {
                get: function () {
                    const par = that.arrDataMap.get(item.parent_id);
                    if (par) {
                        return par;
                    }
                    return null;
                }
            });

            that.$set(item, 'index', that.arrFormTemp.length);
            that.$set(that.arrFormTemp, that.arrFormTemp.length, item);
            that.arrDataMap.set(item.id, item);
            if (item.hasOwnProperty('child') && (item.child.length > 0)) {
                that.$set(item, 'open', true);
                that.$set(item, 'hasChild', true);
            }

            if (item.hasChild) {
                for (const ch of item.child) {
                    that.createTable(ch, item.id);
                }
                delete item.child;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)
        async prepareForm() {
            this.arrForm = [];
            this.arrFormTemp = [];
            this.arrDataMap = new Map();
            for (const item of this.spfForm) {
                await this.createTable(item, 0);
            }
            for (const item of this.arrFormTemp) {
                if (item.type === 2) {
                    item.open = false;
                    this.arrForm.push(item)
                }
            }
        }, // подготовка отображения данных
        compare_spf(a, b) {
            if (a.spf < b.spf) {
                return -1;
            }
            if (a.spf > b.spf) {
                return 1;
            }
            return 0;
        },
        compare_ppr(a, b) {
            if (a.ppr < b.ppr) {
                return -1;
            }
            if (a.ppr > b.ppr) {
                return 1;
            }
            return 0;
        },
        compare_prg(a, b) {
            if (a.prg < b.prg) {
                return -1;
            }
            if (a.prg > b.prg) {
                return 1;
            }
            if (a.ppr === undefined) {
                return -1;
            }
            if (b.ppr === undefined) {
                return 1;
            }
            return 0;
        },
        compare_abp(a, b) {
            if (a.abp < b.abp) {
                return -1;
            }
            if (a.abp > b.abp) {
                return 1;
            }
            if (a.prg === undefined) {
                return -1;
            }
            if (b.prg === undefined) {
                return 1;
            }
            return 0;
        },
        compare_null(a, b) {
            if ((a.abp === undefined) || (b.abp !== undefined)) {
                return -1;
            }
            return 1;
        },
        inputFixed(item, field, value, digit) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        }, // форматирует введенное значение до digit цифр после запятой

        onChangeRecord(value) {
            this.$nextTick(() => {
                this.$set(this, 'isSave', value.length > 0);
            });
        },

        toNum(value, fix = 1) {
            if (this.data_round === true){
                fix = 5
            }
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined') {
                return 0;
            }
            if (isNaN(value)) {
                return 0;
            }
            if (isFinite(value)) {
                return 0;
            }
            if (value === null) {
                return 0;
            }
        },

    }
}
</script>

