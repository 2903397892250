import { cloneDeep } from "lodash";
import { allBudgetTypesOptions, allClassificationTypesOptions, allDataSourcesOptions, allIncomeClassificators, allMeasureUnitsOptions, allPeriodicitiesOptions, allReportNamesOptions, allReportTypesOptions } from "../../common/baseOptions";
import { Classificator } from "../../common/types/Classificator";
import { Template } from "../../common/types/Template";
import { ReportEibFilterSettings } from "./settings/ReportEibFilterSettings";
import { ReportEibFilterOptions } from "./options/ReportEibFilterOptions";

export const reportEibExpensesClassificators: Classificator[] = [
    { key: 'gr',  active: false, selected: [] },
    { key: 'pgr', active: false, selected: [] },
    { key: 'abp', active: false, selected: [] },
    { key: 'prg', active: false, selected: [] },
    { key: 'ppr', active: false, selected: [] },
    { key: 'spf', active: false, selected: [] },
]

// Нужна как временная затычка (При созданий нового берутся пустые настройки)
const tempSettings: ReportEibFilterSettings = {
    // Base:
    reportName: 'EIB',
    reportType: 'REGULATED',
    classificationType: 'MIXED',
    dataSource: 'LOADER',
    periodicity: 'DAY',
    // Custom:
    date: '',
    budgetTypes: [],
    region: '',
    measureUnit: 'THOUSAND',
    roundUpTo: null,
    incomes: [],
    expenses: []
}

export const reportEibBaseTemplate: Template = {
    id: null,
    name: 'Ежедневное исполнение бюджета (по всем уровням бюджета)',
    description: null,
    category: '50',
    regions: [],
    userId: '',
    filterSettings: tempSettings
}

export const reportEibFilterOptions: ReportEibFilterOptions = {
    // Base:
    key: 'EIB',
    reportNames: cloneDeep(allReportNamesOptions),
    reportTypes: cloneDeep(allReportTypesOptions),
    classificationTypes: cloneDeep(allClassificationTypesOptions),
    dataSources: cloneDeep(allDataSourcesOptions),
    periodicities: cloneDeep(allPeriodicitiesOptions),
    // Custom:
    measureUnits: cloneDeep(allMeasureUnitsOptions),
    budgetTypes: cloneDeep(allBudgetTypesOptions),
    regions: [], // Загружается с бэка
    incomes: cloneDeep(allIncomeClassificators),
    expenses: cloneDeep(reportEibExpensesClassificators)
}
